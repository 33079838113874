import { useEffect, useState } from "react";

export const TablaSimple = (props) => {  
    const [DatosVista, setDatosVista] = useState([]);
    useEffect(() => {
      setDatosVista( props.datos );
    }, [props.datos])
    

    const cambiarValorInput = (event, tabla, fila, celda, vista) => {
        let nuevos_datos = DatosVista;

        const valor_input = validarNumero( event.target.value );

        if( valor_input === '' ) {
            nuevos_datos[tabla].filas[fila][celda].clases_error = 'input-cosecha-error';
            nuevos_datos[tabla].filas[fila][celda].valor = valor_input;
            nuevos_datos[tabla].filas[fila][celda].disabled = false;
            props.setDatos( { ...nuevos_datos } );
        } else {
            nuevos_datos[tabla].filas[fila][celda].clases_error = '';
            if( nuevos_datos[tabla].filas[fila][celda].valor === nuevos_datos[tabla].filas[fila][celda].original ) {
                props.setCambios( props.cambios + 1 );
                nuevos_datos[tabla].filas[fila][celda].valor = valor_input;
                nuevos_datos[tabla].filas[fila][celda].disabled = false;
                props.setDatos( { ...nuevos_datos } );
            } else {
                nuevos_datos[tabla].filas[fila][celda].valor = valor_input;
                nuevos_datos[tabla].filas[fila][celda].disabled = false;
                props.setDatos( { ...nuevos_datos } );
            }
        }
    }

    const reestablecerValor = (event, tabla, fila, celda, vista) => {
        let nuevos_datos = DatosVista;
        nuevos_datos[tabla].filas[fila][celda].clases_error = '';
        nuevos_datos[tabla].filas[fila][celda].valor = nuevos_datos[tabla].filas[fila][celda].original;
        nuevos_datos[tabla].filas[fila][celda].disabled = true;
        props.setDatos( { ...nuevos_datos } );
        props.setCambios( props.cambios - 1 );
    }

    const validarNumero = ( valor ) => {
        let nuevo = "";
        let decimal = false;

        for(let i = 0; i < valor.length; i++) {
            switch(true) {
                case valor[i] == "." || valor[i] == ",":
                    if(decimal == false) {
                        decimal = true;
                        nuevo += "."
                    }
                    break;
                
                case valor != "-" && !isNaN(valor[i]) && valor[i] != '.' :
                    nuevo += valor[i];
                    break;
            }
        }

        return nuevo;

    }

    return (
        <table className={ props.claseTabla } id={ props.id_tabla }>
            <thead>
                {
                    props.cabecera.map((fila, index) => (
                        <tr key={ index }>
                            {
                                fila.map((celda, subindex) => (
                                    celda.cols === 0
                                    ? <th key={ subindex } className='celda-vacia'></th>
                                    : <th key={ subindex } colSpan={ celda.cols } className={ fila.length === 1 ? 'un-th' : '' }>{ celda.valor }</th>
                                ))
                            }
                        </tr>
                    ))
                }
            </thead>

            <tbody>
                {
                    props.filas.map((fila, index) => (
                        <tr key={ index }>
                            {
                                fila.map((celda, subindex) => (
                                    celda.input === false 
                                    ?
                                        celda.valor === 'no valido'
                                        ? <td rowSpan={ celda.rows }  key={ subindex } className='celda-no-valida'></td>
                                        : 
                                            celda.clases
                                            ?
                                                <td 
                                                    rowSpan={ celda.rows } key={ subindex }  
                                                    className={ celda.clases  }
                                                >{ celda.valor != '-' && celda.valor != 'undefined' && celda.valor != '' && celda.valor != null ? (celda.valor).toLocaleString("es-CL") : celda.valor }</td>
                                            :
                                                <td 
                                                    rowSpan={ celda.rows } key={ subindex }  
                                                    className={ subindex === 1 ? 'turno-a' : subindex !== 0 ? 'turno-b' : ''  }
                                                >{ celda.valor != '-' && celda.valor != 'undefined' && celda.valor != '' && celda.valor != null ? (celda.valor).toLocaleString("es-CL") : celda.valor }</td>
                                    :
                                    
                                    <td key={ subindex }  className={ celda.clases ? celda.clases : ( subindex === 1 ? 'turno-a' : subindex !== 0 ? 'turno-b' : '' ) }> 
                                        <div className="cont-input">
                                            <input value={ celda.valor } className={ celda.clases_error } onChange={ (e) => { cambiarValorInput(e, celda.tabla, index, subindex, props.vista) } }/>
                                            {/* <button className='btn blue-grey waves-effect waves-light' disabled={ celda.disabled }>
                                                <i className="material-icons left">save</i>
                                            </button> */}
                                            <button 
                                                onClick={ (e) => { reestablecerValor(e, celda.tabla, index, subindex, props.vista) } } 
                                                id={ `btn-reestablecer-${ index }${subindex}` } className='btn blue-grey waves-effect waves-light' 
                                                disabled={ celda.disabled }
                                            >
                                                <i className="material-icons left">replay</i>
                                                
                                            </button>
                                        </div>
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
