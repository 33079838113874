// Componentes
import { useEffect, useState } from "react";
import { FilaSuperior } from "./components/FilaSuperior";
import { Tabla } from "./components/Tabla";
import { Loader } from "../../components/Loader";
import { MsjError } from "../../components/MsjError";
import { useAnalisisQuimico } from "../../hooks/useAnalisisQuimico";

import M from "materialize-css";

// Estilos
import './styles/AnalisisQuimico.scss'
import './styles/AnalisisQuimico_responsive.scss'

export const AnalisisQuimicoPage = () => {
    const  { obtenerAnalisisQuimicoAPI  } = useAnalisisQuimico();

    const [DatosTabla, setDatosTabla] = useState([]);
    const [Interfaz, setInterfaz] = useState(false);

    useEffect(() => {
      obtenerDatos();
    }, [])
    
    const obtenerDatos = async() => {
        const datos =  await obtenerAnalisisQuimicoAPI();
        const modal = document.getElementById('modal-carga');
        const ins = M.Modal.init(modal);

        if( datos == false ) {
            setInterfaz( null );
        } else {
            setDatosTabla(datos);
            setInterfaz(true);
        }
    }

    return (
        <div className="contenido">
            {
                {
                    false:
                        <Loader />,
 
                    null: <MsjError />,

                    true:
                        <>
                            <div className="contenedor">
                                <FilaSuperior datos={ DatosTabla } setDatos={ setDatosTabla } />
                            </div>

                            <div className="contenedor">
                                <div className="cont-tabla-aq scroll">
                                    <Tabla datos={ DatosTabla } Interfaz={ Interfaz }/>
                                </div>
                            </div>
                        </>

                }[ Interfaz ]
            }
        </div>
    )
}
