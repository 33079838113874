// API
import digitalizacionAPI from "../../API/digitalizacionAPI";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setRazonLixiviacion, limpiarRazonLixiviacion } from "../../store/digitalizacion/RazonLixiviacionSlice";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";

export const useRazonLixiviacion = () => {
    const dispatch = useDispatch();
    const XLSX = require("xlsx-js-style");

    // Token
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};

    // Planta es un ID (1: met, 2: oxe)
    const obtenerRlixAPI = async(planta, inicio) => {
        let datos_rlix = [];

        try {
            await digitalizacionAPI.get(`comun/rlix/${planta}`, conf_api).then(({ data:datos }) => {
                if(planta == 1) {
                    const datos_ordenados = datos.slice().sort(ordenarArray('Modulo'));
                    const ciclos = agruparCiclos(datos_ordenados);
                    datos_rlix = ciclos;
                    if(inicio != true) {
                        dispatch( setRazonLixiviacion({ciclos}) )
                    }
                } else {
                    datos_rlix = agruparOXE(datos);
                    // console.log(datos_rlix);
                    if(inicio != true) {
                        dispatch( setRazonLixiviacion({ datos: datos_rlix }) )
                    }
                }
            });
    
            
        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }

        return datos_rlix;
    }

    const obtenerTodoRlixAPI = async(planta) => {
        try {
            let datos_rlix = [];
    
            await digitalizacionAPI.get(`comun/rlix/${planta}`, conf_api).then(({ data:datos }) => {
                if(planta == 2) {
                    // console.log(datos);
                    const { datos:data } = agruparOXE(datos);
                    datos_rlix = data;
                } else {
                    datos_rlix = datos;
                }
            })
    
            return datos_rlix;
        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }
    }

    const agruparOXE = (data) => {
        const { Ciclos } = data;

        let datos = [];
        Ciclos.forEach(({ registros }) => {
            registros.forEach(registro => {
                if(registro.Modulo != '00') {
                    datos = [...datos, registro];
                }
            });
        });

        datos = datos.sort(ordenarArray('Nomenclatura'));;
        return {
            ...data,
            datos
        }
    }

    function ordenarArray(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const agruparCiclos = (datos) => {
        /**
         * DATOS 
         *      Caudal_Promedio: int
         *      Ciclo: string
         *      DiasDisponibles_RiegoEfectivo: float
         *      EstadiCiclo: string
         *      Fecha_Inicio_Ciclo: date
         *      Fecha_Termino_Riego: date
         *      Modulo: string
         *      Nomenclatura: string
         *      ProcentajeCumplomiento_RiegoEfectivo: float
         *      RazonRiego_Efectiva: float
         *      RazonRiego_Segun_DiasDisponibles
         *      TMS: float
         *      Total_Horas: float
         *      Volumen_Total: float
         *      id_modulo: int
         */

        let datos_agrupados = [];
        let grupo = [];
        let ciclo_actual = datos[0].Ciclo;
        let ciclos = [ciclo_actual];
        const fecha_actual = obtenerFechaActual();
        let lista_modulos = [];
        let lista_datos = [];

        datos.forEach(modulo => {
            // console.log(modulo.Nomenclatura);
            if(modulo.Nomenclatura != null && modulo.Modulo != "00" && !lista_modulos.includes(modulo.Modulo) ) {
                lista_modulos.push(modulo.Modulo);

                const { Ciclo, Total_Horas, Caudal_Promedio } = modulo;
                const m3_promedio = Total_Horas * Caudal_Promedio;
                
                if(ciclo_actual != Ciclo) {
                    datos_agrupados = [...datos_agrupados, {
                        ciclo: ciclo_actual,
                        registros: grupo
                    }]
                    
                    ciclos = [...ciclos, Ciclo];
                    ciclo_actual = Ciclo;
                    grupo = [];
                } else {
                    grupo = [...grupo, {
                        ...modulo,
                        m3_promedio,
                        fecha_actual
                    }]
                }

                lista_datos = [...lista_datos, modulo ];
            }
        });

        datos_agrupados = [...datos_agrupados, {
            ciclo: ciclo_actual,
            registros: grupo
        }];

        // console.log(datos);
        return {
            ciclos,
            datos_agrupados,
            datos: lista_datos
        };
    }

    const obtenerFechaActual = () => {
        const date = new Date();
        const dia = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const mes = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
        const anio = date.getFullYear();

        return `${anio}-${mes}-${dia}`;
    }

    const descargarRlix = (planta, ciclo) => {
        const tabla = document.getElementById("tabla-rlix");
        const thead = tabla.tHead.rows[0].children;
        const tbody = tabla.tBodies[0].rows;


        let opts = [];
        let cabecera = [];

        for(let i = 0; i < thead.length; i++) {
            const valor = thead[i].innerHTML;
            opts = [...opts, { wch: 20 }]

            cabecera = [...cabecera, {
                v: valor,
                t: 's',
                s: {
                    font: {
                        sz: 12,
                        bold: true,
                        color: {
                            rgb: "ffffff"
                        }
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '3677b0'
                        }
                    },
                    border: {
                        bottom: {
                            style: "thin",
                            color: {
                                rgb: "ffffff"
                            }
                        },
                        top: {
                            style: "thin",
                            color: {
                                rgb: "ffffff"
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                rgb: "ffffff"
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                rgb: "ffffff"
                            }
                        }
                    }
                }
            }];
        }

        let hoja =  XLSX.utils.aoa_to_sheet([cabecera]);

        hoja['!cols'] = opts;
        let alto_filas = [{ 'hpt': 50 }];

        for(let i = 0; i < tbody.length; i++) {
            alto_filas = [...alto_filas, { hpt: 23 }];

            const fila =  tbody[i].children;
            
            let cuerpo = [];
            for(let h = 0; h < fila.length; h++) {
                const celda = h == fila.length-1 ? fila[h].children[0].innerHTML : fila[h].innerHTML;
                
                if(h == 0) {
                    cuerpo = [...cuerpo, {
                        v: celda,
                        t: 's',
                        s: {
                            font: {
                                bold: true,
                                sz: 11,
                                color: {
                                    rgb: '1e1e1e' 
                                }
                            },
                            alignment: {
                                vertical: "center",
                                horizontal: "center"
                            },
                            fill: {
                                fgColor: {
                                    rgb: 'b1c7e0'
                                }
                            },
                            border: {
                                bottom: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                },
                                top: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                },
                                left: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                },
                                right: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                }
                            }
                        }
                    }]
                } else {
                    cuerpo = [...cuerpo, {
                        v: celda,
                        t: 's',
                        s: {
                            font: {
                                bold: false,
                                sz: 11,
                                color: {
                                    rgb: '1e1e1e' 
                                }
                            },
                            alignment: {
                                vertical: "center",
                                horizontal: "center"
                            },
                            fill: {
                                fgColor: {
                                    rgb: i % 2 == 0 ? 'ffffff' : 'f2f2f2'
                                }
                            },
                            border: {
                                bottom: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                },
                                top: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                },
                                left: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                },
                                right: {
                                    style: "thin",
                                    color: {
                                        rgb: "ffffff"
                                    }
                                }
                            }
                        }
                    }]
                }
                
            }
            
            XLSX.utils.sheet_add_aoa(hoja, [cuerpo], { origin: i+1 });
        }

        hoja['!rows'] = alto_filas;
        
        const archivo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(archivo, hoja, `Razón lixiviación ${planta}`);
        XLSX.writeFile(archivo, `Razón_de_lixiviación_${planta.toUpperCase()}.xlsx`, {  editable: true });
    }

    const cambiarTabRlix = (tab_nueva, btn_nuevo, tab_origen, btn_origen) => {
        document.getElementById(tab_nueva).classList.add("activo");
        document.getElementById(btn_nuevo).classList.add("activo");

        document.getElementById(tab_origen).classList.remove("activo");
        document.getElementById(btn_origen).classList.remove("activo");
    }

    return {
        obtenerRlixAPI,
        descargarRlix,
        obtenerTodoRlixAPI,
        cambiarTabRlix
    }
}