import { createSlice } from '@reduxjs/toolkit';


const initialState = { resumen: [] }

const resumenModulosOXESlice = createSlice({
    name: 'ResumenModulosOXE',
    initialState,
    reducers: {
        setResumenModulosOXE: (state, { payload }) => {
            state.resumen = payload
        },

        limpiarResumenModulosOXE: (state) => {
            state.resumen = [];
        }
    }
});

export const { setResumenModulosOXE, limpiarResumenModulosOXE } = resumenModulosOXESlice.actions;
export default resumenModulosOXESlice.reducer;