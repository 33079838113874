import { createSlice } from '@reduxjs/toolkit';

const initialState = { rampa: {} };

const RampaHumectacionSlice = createSlice({
    name: 'RampaHumectacionMET',
    initialState,
    reducers: {
        setRampaHumectacion: (state, { payload }) => {
            state.rampa = payload.rampa
        },

        limpiarRampaHumectacion: (state) => {
            state.rampa = { modulos: [''], registros: [] }
        }
    }
});

export const { setRampaHumectacion, limpiarRampaHumectacion } = RampaHumectacionSlice.actions;
export default RampaHumectacionSlice.reducer;

