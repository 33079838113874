import { createSlice } from '@reduxjs/toolkit';


const initialState = { datos: {} }


const ReportePreliminarSlice = createSlice({
    name: 'ReportePreliminar',
    initialState,
    reducers: {
        setReportePreliminar: (state, { payload }) => {
            state.datos = payload
        },

        limpiarReportePreliminar: (state) => {
            state.datos = {};
        }
    }
});

export const { setReportePreliminar, limpiarReportePreliminar } = ReportePreliminarSlice.actions;
export default ReportePreliminarSlice.reducer;