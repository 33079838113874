import React, { useEffect, useState } from 'react'
import M from 'materialize-css';
import { ControlRiego } from './ControlRiego';
import { Rlix } from './Rlix';
import { ResumenModulos } from './ResumenModulos';

export const Tarjeta = (props) => {
    const [Ejecutado, setEjecutado] = useState(false)

    useEffect(() => {
        if(!Ejecutado) {
            setEjecutado(true);
            const el = document.querySelectorAll(".tabs");
            var instance = M.Tabs.init(el, { swipeable : false });
            var elems = document.querySelectorAll('.tooltipped');
            var instances = M.Tooltip.init(elems);
        }
    }, [])

    return (
        <div className={ props.clases }>
            {
                props.nombre !== 'relleno' 
                ?
                    <>
                        <div className="nombre-tarjeta">
                            { 
                                props.nombre === 'CONTROL DE RIEGO' 
                                ? <>{ props.nombre } <i class="material-icons tooltipped" data-position="right" data-tooltip="Los registros con valores 0 no mostrarán una barra">info</i> </>
                                : props.nombre 
                            }
                        </div>

                        <div className="contenido-tarjeta">
                            {
                                {
                                    'CONTROL DE RIEGO': <ControlRiego datos={ props.datos_riego }/>,
                                    'RAZÓN DE LIXIVIACIÓN': <Rlix datos_rlix={ props.datos_rlix } />,
                                    'RESUMEN MÓDULOS': <ResumenModulos datos={ props.datos_estados }/>
                                }[props.nombre]
                            }
                            
                        </div>
                    </>
                : <></>
            }
            
        </div>
    )
}
