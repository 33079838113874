import { useEffect, useState } from 'react'
import M from 'materialize-css';

export const TablaResumenMET = (props) => {
    const [Pila, setPila] = useState([]);
    const [Ejecutado, setEjecutado] = useState(false)

    useEffect(() => {
        if(props.datos.estado == true && Ejecutado == false) {
            setEjecutado(true)
            let datos_pila =  props.datos[`pila_${props.pila}`];
            if( props.pila == 'oeste') {
                setPila( [...datos_pila].reverse() )
            } else {
                setPila( datos_pila );
            }

            var elems = document.querySelectorAll('.modal');
            var instances = M.Modal.init(elems);
        }
    }, [props.datos.estado])
    
    const historialModulo = (modulo, nombre, pila) => {
        var modal = document.getElementById("modal-historial-met");
        var instance = M.Modal.init(modal);
        
        props.setModuloModal({modulo, nombre, pila});

        instance.open();
    }
    
    return (
        <table className="tabla-resumen" id={ `tabla-resumen-met-${props.pila}` }>
            <thead>
                <tr>
                    <th colSpan={ 2 }>PILA { (props.pila).toUpperCase() }</th>
                </tr>
                
                <tr>
                    <th>MÓDULO</th>
                    <th>ESTADO</th>
                </tr>
            </thead>

            <tbody>
                {
                    Pila.map((modulo, index) => (
                        <tr key={ index }>
                            <td>
                                <button 
                                    className='btn light-blue darken-4 waves-effect waves-light'
                                    onClick={ () => { historialModulo( modulo.id_modulo, modulo.nombre_modulo, modulo.nombre_pila ) } }
                                >{ modulo.nombre_modulo }</button>
                            </td>
                            <td>
                                <button 
                                    className={ 'btn waves-effect waves-light estado ' + (modulo.estado).replace(/\s/g, '_') } 
                                    onClick={ () => { historialModulo( modulo.id_modulo, modulo.nombre_modulo, modulo.nombre_pila ) } }
                                >{ modulo.estado }</button>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
