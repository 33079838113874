import { useResumenROM } from "../../../hooks/useResumenROM"
import M from "materialize-css";

export const FilaSuperior = ( props ) => {
  const { descargarROM, guardarCambiosAPI } = useResumenROM();

  const validarEntradas = () => {
    // Validación de vacios
    const elementos = document.getElementsByClassName("input-cosecha-error");
    if( elementos.length > 0 ) {
      M.toast({html: '<i class="material-icons">error</i> &nbsp; No pueden existir campos vacíos', classes: 'rounded red'});
    } else {
      var elems = document.querySelectorAll('.modal');
      var instances = M.Modal.init(elems);
      guardarCambiosAPI( props.rom )
    }
  }

  return (
    <div className='fila-superior'>
        <h3 className='titulo-pagina'>
            RESUMEN PRODUCCIÓN {props.planta.toUpperCase()}
        </h3>

        <div className='cont-btns'>
            <button onClick={ validarEntradas } disabled={ props.cambio > 0 ? false : true } id="fila-sup-cosecha" data-target="modal-carga" className="modal-trigger btn waves-effect waves-light light-blue darken-4 btn-adj-var margen" >
              <i className="material-icons left">save</i>
                GUARDAR CAMBIOS
            </button>

            {/* exportar datos de tablas */}
            <button 
              className='btn waves-effect waves-light deep-orange lighten-1 btn-exportar' 
              onClick={ () => { descargarROM( props.rom ) } }
            >
                <i className='material-icons left'>download</i>
                DESCARGAR
            </button>
        </div>
    </div>
  )
}
