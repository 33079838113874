import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Loader } from '../../../components/Loader';

export const GraficoLinea = (props) => {
  const [Carga, setCarga] = useState(false)
  const [Series, setSeries] = useState([]);
  const [Opciones, setOpciones] = useState({})
  useEffect(() => {

    if( props.interfaz === true ) {

      switch(props.datos.nombre) {
        case 'CONTROL DE RIEGO':
          const { datos, filtro } = props;
          let { modulos, valores:grupos } = datos[filtro];

          if( filtro == 'MET') {
            const lista = agruparDatosMETCR(modulos, grupos);

            let lista_modulos = [];
            let lista_horas = [];
            let lista_presiones  = [];
            let lista_flujos = [];

            lista.forEach( ({ modulo, horas, presion, flujo}) => {
              lista_modulos = [...lista_modulos, modulo ];
              lista_horas = [...lista_horas, horas];
              lista_presiones = [...lista_presiones, presion];
              lista_flujos = [...lista_flujos, flujo];
            })

            let cont = document.querySelector('.icr-MET');
            if (lista.length <= 100 ){
              cont.style.width = '100%';
            }else{
              cont.style.width = '320%';
            }
            setSeries([
              { name: 'Horas', data: lista_horas, type: 'column' },
              { name: 'Presión', data: lista_presiones, type: 'column' },
              { name: 'Flujo', data: lista_flujos, type: 'column' }
            ]);
  
            setOpciones({
              chart: {
                height: 350,
                type: 'bar',
              },
              bar: {
                columnWidth: '70%',
              },
              title: {
                text: props.nombre
              },
              stroke: {
                show: true,
                width: 0.5,
                colors: ['transparent']
              },
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              plotOptions: { 
                bar: { 
                  columnWidth: '90%' 
                }
              },
              grid: {
                row: {
                  colors: ['#f3f3f3', '#ffffff'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              colors: ['#16549e'],
              labels: lista_modulos
            });
          } else {
            const lista = agruparDatosOXECR(modulos, grupos);

            let lista_modulos = [];
            let lista_horas = [];
            let lista_flujos = [];

            lista.forEach( ({ modulo, horas, presion, flujo}) => {
              lista_modulos = [...lista_modulos, modulo ];
              lista_horas = [...lista_horas, horas];
              lista_flujos = [...lista_flujos, flujo];
            })
            let cont = document.querySelector('.icr-OXE');
            cont.style.width = '100%';

            setSeries([
              { name: 'Horas', data: lista_horas, type: 'column' },
              { name: 'Flujo', data: lista_flujos, type: 'column' }
            ]);
  
            setOpciones({
              chart: {
                height: 350,
                type: 'bar',
              },
              bar: {
                columnWidth: '70%',
              },
              title: {
                text: props.nombre
              },
              stroke: {
                show: true,
                width: 0.5,
                colors: ['transparent']
              },
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              plotOptions: { 
                bar: { 
                  columnWidth: '90%' 
                }
              },
              grid: {
                row: {
                  colors: ['#f3f3f3', '#ffffff'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              colors: ['#16549e'],
              labels: lista_modulos
            });
          }
          break;

        case 'RAZÓN DE LIXIVIACIÓN':
          let { data, modulos:modulos_rlix } = props.filtro == 'MET' ? props.datos.MET : props.datos.OXE;
          // let { data, modulos:modulos_rlix } = props.datos.MET;

          let contmet = document.querySelector('.irl-rlix-met');
          let contoxe = document.querySelector('.irl-rlix-oxe');

          contmet.style.width = '100%';
          contoxe.style.width = '100%';
          
          setSeries([
            { 
              name: 'Rlix real',
              type: 'bar',
              data: data
            }
          ]);

          setOpciones({ 
              chart: {
                height: 350,
                type: 'bar'
              },
              title: {
                text: props.nombre
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                }
              },
              grid: {
                row: {
                  colors: ['#f3f3f3', '#ffffff'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              colors: ['#16549e'],
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              legend: {
                show: false,
                showForSingleSeries: true,
                customLegendItems: ['Rlix real', 'Rlix esperado',  'Rlix objetivo'],
                markers: {
                  fillColors: ['#16549e', '#ffc33c', '#a3d39c']
                }
              }
            }
          )
          break;

      }

      setCarga(true);
      
    }
  }, [props.datos])

  const agruparDatosMETCR = (modulos, grupos) => {
    // Obtener último ciclo
    let ciclo = 0;
    let lista = [];
    let lista_modulos = [];
    modulos.forEach(modulo => {
      const num = parseInt(modulo[0] + modulo[1]);

      ciclo = num > ciclo ? num : ciclo;
    });

    modulos.forEach(modulo => {
      const ciclo_modulo = parseInt(modulo[0] + modulo[1]);
      let suma_hrs = 0;
      let suma_presion = 0;
      let suma_flujos = 0;
      let div_presion = 0;
      let div_flujos = 0;

      if( ciclo_modulo == ciclo ) {
        lista_modulos = [...lista_modulos, modulo];
        
        grupos.forEach(({ filas_general } )=> {
          
          for( let i = 0; i < filas_general.length; i++ ) {
            if( i != filas_general.length - 1 ) {
              const fila = filas_general[i]; 
              let pos = 1;
              fila.forEach( ({ modulo:nomenclatura, valor }) => {
                if( nomenclatura == modulo ) {
                  switch( pos ) {
                    case 1:
                      suma_hrs += parseFloat(valor);
                      pos += 1;
                      break;

                    case 2:
                      suma_presion = (valor > 0 && valor != 'NaN') ? suma_presion + parseFloat(valor) : suma_presion;
                      div_presion = (valor > 0 && valor != 'NaN') ? div_presion + 1 : div_presion;
                      pos += 1;
                      break;

                    case 3:
                      suma_flujos = (parseFloat(valor) > 0 && valor != 'NaN') ? suma_flujos + parseFloat(valor) : suma_flujos;
                      div_flujos = (parseFloat(valor) > 0 && valor != 'NaN') ? div_flujos + 1 : div_flujos;
                      break;
                  }
                }
              });
            }
          }
        });

        div_presion = div_presion == 0 ? 1 : div_presion;
        div_flujos = div_flujos == 0 ? 1 : div_flujos;
        lista = [...lista, {
          modulo,
          horas: (suma_hrs).toFixed(2),
          presion: (suma_presion / div_presion).toFixed(2),
          flujo: (suma_flujos / div_flujos).toFixed(2)
        }];
      }
    });

    return lista;
  }

  const agruparDatosOXECR = (modulos, grupos) => {
    // Obtener último ciclo
    let ciclo = 0;
    let lista = [];
    let lista_modulos = [];
    modulos.forEach(modulo => {
      const num = parseInt(modulo[0] + modulo[1]);
      ciclo = num > ciclo ? num : ciclo;
    });

    modulos.forEach(modulo => {
      const ciclo_modulo = parseInt(modulo[0] + modulo[1]);
      let suma_hrs = 0;
      let suma_flujos = 0;
      let div_flujos = 0;

      if( ciclo_modulo == ciclo ) {
        lista_modulos = [...lista_modulos, modulo];
        
        grupos.forEach(({ filas_general } )=> {
          
          for( let i = 0; i < filas_general.length; i++ ) {
            if( i != filas_general.length - 1 ) {
              const fila = filas_general[i]; 
              let pos = 1;
              fila.forEach( ({ modulo:nomenclatura, valor }) => {
                if( nomenclatura == modulo ) {
                  switch( pos ) {
                    case 1:
                      suma_hrs += parseFloat(valor);
                      pos += 1;
                      break;

                    case 2:
                      suma_flujos = (parseFloat(valor) > 0 && valor != 'NaN') ? suma_flujos + parseFloat(valor) : suma_flujos;
                      div_flujos = (parseFloat(valor) > 0 && valor != 'NaN') ? div_flujos + 1 : div_flujos;
                      break;
                  }
                }
              });
            }
          }
        });

        div_flujos = div_flujos == 0 ? 1 : div_flujos;
        lista = [...lista, {
          modulo,
          horas: (suma_hrs).toFixed(2),
          flujo: (suma_flujos / div_flujos).toFixed(2)
        }];
      }
    });

    return lista;
  }
  
  return (
    Carga === false 
    ? <></>
    : 
      props.datos.nombre === 'CONTROL DE RIEGO' 
      ? props.filtro == 'MET'
        ?
          <>
            <div id="graf-horas-met" className='graf-cr activo'>
              <ReactApexChart height={ 227 } className='graf-reportabilidad' series={ [Series[0]] } options={ Opciones } />
            </div>

            <div id="graf-presion-met" className='graf-cr'>
              <ReactApexChart  height={ 227 } className='graf-reportabilidad' series={ [Series[1]] } options={ Opciones } />
            </div>

            <div id="graf-flujo-met" className='graf-cr'>
              <ReactApexChart  height={ 227 } className='graf-reportabilidad' series={ [Series[2]] } options={ Opciones } />
            </div>
          </>
        :
          <>

            <div id="graf-horas-oxe" className='graf-cr activo'>
              <ReactApexChart height={ 227 } className='graf-reportabilidad' series={ [Series[0]] } options={ Opciones } />
            </div>

            <div id="graf-flujo-oxe"  className='graf-cr'>
              <ReactApexChart height={ 227 } className='graf-reportabilidad' series={ [Series[1]] } options={ Opciones } />
            </div>
          </>
      : <ReactApexChart type="bar" height={ 250 } className='graf-reportabilidad' series={ Series } options={ Opciones } />
  )
}
