import M from "materialize-css";
import { ModalVariables } from "./ModalVariables";
import { useCosecha } from '../../../hooks/useCosecha';
import { descargaCosecha } from "../../../hooks/descargaCosecha";
import { ModalDescarga } from "./ModalDescarga";

export const FilaSuperior = ( props ) => {
  const { guardarCambios } = useCosecha();
  const { descargarPDF, descargarXLSX, descargarImagen } = descargaCosecha();

  const validarEntradas = () => {
    // Validación de vacios
    const elementos = document.getElementsByClassName("input-cosecha-error");
    if( elementos.length > 0 ) {
      M.toast({html: '<i class="material-icons">error</i> No pueden existir campos vacíos', classes: 'rounded red'});
    } else {
      const modal = document.getElementById("modal-carga");
      const instance = M.Modal.getInstance(modal);
      instance.open();
      guardarCambios(props.produccion, props.sx, props.ewsx, props.lixiviacion);
    }
  }
  
  return (
    <div className="fila-superior cosecha">
        <h3 className="titulo-pagina">
          INFORME COSECHA
        </h3>

        <div className="cont-btns cosecha">
            <button onClick={ validarEntradas } disabled={ props.cambios > 0 ? false : true } id="fila-sup-cosecha" className="btn waves-effect waves-light light-blue darken-4 btn-adj-var margen" >
              <i className="material-icons left">save</i>
                GUARDAR CAMBIOS
            </button>

            <ModalVariables />
            <button  id="fila-sup-cosecha" className="btn waves-effect waves-light cyan darken-3 btn-adj-var modal-trigger margen modal-trigger" data-target="modal-variables-cosecha" >
              <i className="material-icons left">settings</i>
                AJUSTAR VARIABLES
            </button>

            

            {/* Exportar datos de tablas */}
            <button className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar" onClick={ () => { descargarXLSX( props.produccion, props.sx, props.ewsx, props.lixiviacion ) } }>
              <i className="material-icons left">download</i>
                XLSX
            </button>

            <button className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar"  onClick={ () => { descargarPDF( props.produccion, props.sx, props.ewsx, props.lixiviacion ) } }>
              <i className="material-icons left">download</i>
                PDF
            </button>

            <ModalDescarga produccion={ props.produccion } sx={ props.sx } ewsx={ props.ewsx } lixiviacion={ props.lixiviacion } interfaz={ props.interfaz }/>
            <button className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar"  onClick={ () => { descargarImagen( props.produccion, props.sx, props.ewsx, props.lixiviacion ) } }>
              <i className="material-icons left">download</i>
              IMG
            </button>
        </div> 
    </div>
  )
}