import React from 'react';
import ReactDOM from 'react-dom/client';


import { DigitalizacionApp } from './DigitalizacionApp';

import { BrowserRouter } from 'react-router-dom';

import { store } from './store/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
        <DigitalizacionApp />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);