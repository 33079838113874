import { useState, useEffect } from 'react'


export const Nomina = ( props ) => {
  const [Ejecutado, setEjecutado] = useState(false)
  const [DatosNomina, setDatosNomina] = useState({
    filas: [],
    fila_total: []
  });

  useEffect(() => {
    if( !Ejecutado  && props.interfaz ) {
      setEjecutado( true );
      const datos = props.datos;

      setDatosNomina({
        filas: datos.filas,
        fila_total: datos.fila_total
      });

    }
  }, [props.interfaz]);

  return (
    Ejecutado == true
    ?
      props.id_tab == "tab-informe-nomina-mensual"
        ?
          <>
            <div className="cont-tabla-nomina scroll">
              <table id="tb-nomina-mensual">
                <thead>
                  <tr>
                    <th width={ 200 }>DÍA</th>
                    <th width={ 200 }>COSECHA TURNO A</th>
                    <th width={ 200 }>COSECHA TURNO B</th>
                    <th width={ 200 }>COSECHA DÍA</th>
                    <th width={ 200 }>ACUM. COSECHA REAL</th>
                    <th width={ 200 }>ACUM. PRESUPUESTO</th>
                    <th width={ 200 }>VARIACIÓN PRESUPUESTO</th>
                    <th width={ 200 }>ACUM. PLAN MAESTRO</th>
                    <th width={ 200 }>VARIACIÓN PLAN MAESTRO</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    DatosNomina.filas.map( (fila, index) => (
                      <tr key={ index } className={ index % 2 != 0 ? 'colorear' : '' }>
                        <td>{ fila.fecha }</td>
                        <td>{ fila.cosecha_a.toLocaleString("es-CL") }</td>
                        <td>{ fila.cosecha_b.toLocaleString("es-CL") }</td>
                        <td>{ fila.cosecha_dia.toLocaleString("es-CL") }</td>
                        <td>{ fila.cosecha_real.toLocaleString("es-CL") }</td>
                        <td>{ fila.presupuesto.toLocaleString("es-CL") }</td>
                        <td>{ fila.var_presupuesto.toLocaleString("es-CL") }</td>
                        <td>{ fila.plan_maestro.toLocaleString("es-CL") }</td>
                        <td>{ fila.var_plan_maestro.toLocaleString("es-CL") }</td>
                      </tr>
                    ))
                  }

                  <tr>
                    <td width={ 200 }>{ DatosNomina.fila_total.texto }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_cosecha_a.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_cosecha_b.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_cosecha_dia.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_cosecha_real.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_presupuesto.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_var_presupuesto.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_plan_maestro.toLocaleString("es-CL") }</td>
                    <td width={ 200 }>{ DatosNomina.fila_total.total_var_plan_maestro.toLocaleString("es-CL") }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>

        :
          <>
            <div className="cont-tabla-nomina scroll">
              <table id="tb-nomina-anual">
                <thead>
                  <tr>
                    <th width={ 100 }>MES</th>
                    <th width={ 100 }>ACUM. COSECHA REAL</th>
                    <th width={ 100 }>ACUM. PRESUPUESTO</th>
                    <th width={ 100 }>VARIACIÓN PRESUPUESTO</th>
                    <th width={ 100 }>ACUM. PLAN MAESTRO</th>
                    <th width={ 100 }>VARIACIÓN PLAN MAESTRO</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    DatosNomina.filas.map( (fila, index) => (
                      <tr key={ index } className={ index % 2 != 0 ? 'colorear' : '' }>
                        <td>{ fila.mes }</td>
                        <td>{ fila.cosecha_real.toLocaleString("es-CL") }</td>
                        <td>{ fila.presupuesto.toLocaleString("es-CL") }</td>
                        <td>{ fila.var_presupuesto.toLocaleString("es-CL") }</td>
                        <td>{ fila.plan_maestro.toLocaleString("es-CL") }</td>
                        <td>{ fila.var_plan_maestro.toLocaleString("es-CL") }</td>
                      </tr>
                    ))
                  }

                    <tr>
                      <td width={ 200 }>{ DatosNomina.fila_total.texto }</td>
                      <td width={ 200 }>{ DatosNomina.fila_total.total_cosecha_real.toLocaleString("es-CL") }</td>
                      <td width={ 200 }>{ DatosNomina.fila_total.total_presupuesto.toLocaleString("es-CL") }</td>
                      <td width={ 200 }>{ DatosNomina.fila_total.total_var_presupuesto.toLocaleString("es-CL") }</td>
                      <td width={ 200 }>{ DatosNomina.fila_total.total_plan_maestro.toLocaleString("es-CL") }</td>
                      <td width={ 200 }>{ DatosNomina.fila_total.total_var_plan_maestro.toLocaleString("es-CL") }</td>
                    </tr>
                </tbody>
              </table>
            </div>
            
          </>
    : null
  )
}
