import React, { useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import M from 'materialize-css';
import { useResumenModulos } from '../../../hooks/useResumenModulos';

import '../styles/registro_estados.scss';
import { MsjError } from '../../../components/MsjError';

export const ModalHistorialEstados = (props) => {
  const { descargarHistorial } = useResumenModulos();
  const moment = require('moment');
  const [Ejecutado, setEjecutado] = useState(false);
  const [Fecha, setFecha] = useState('');
  const [FechaMaxima, setFechaMaxima] = useState('')
  const [FechaMinima, setFechaMinima] = useState('');

  const [DatosDia, setDatosDia] = useState([]);
  const [DatosA, setDatosA] = useState([]);
  const [DatosB, setDatosB] = useState([]);

  useEffect(() => {

    if(!Ejecutado && props.datos.error == false ) {
      const hoy = (moment().locale('es').format('YYYY-MM-DD HH:mm')).split(" ")[0];
      setFecha(hoy);
      setFechaMaxima(hoy);

      let fecha = '';
      let lista_hoy = [];
      let lista_A = [];
      let lista_B = [];
      props.datos.datos.forEach(registro => {
        // Obtener fecha mínima
        const fecha_cambio = moment(registro.datetime_actualizado).locale('es').format('YYYY-MM-DD HH:mm');
        if(fecha == '') {
          fecha = moment(registro.datetime_actualizado).locale('es').format('YYYY-MM-DD HH:mm');
        } else {
          if (fecha > fecha_cambio) fecha = moment(registro.datetime_actualizado).locale('es').format('YYYY-MM-DD HH:mm');
        }

        // Obtener datos de hoy
        if(fecha_cambio.split(" ")[0] == hoy) {
          lista_hoy = [...lista_hoy, registro];
          if(registro.turno == 'turno_A') {
            lista_A = [...lista_A, registro];
          } else {
            lista_B = [...lista_B, registro];
          }
        }
      });

      setFechaMinima(fecha.split(" ")[0]);
      setDatosDia(lista_hoy);
      setDatosA(lista_A);
      setDatosB(lista_B);
      setEjecutado(true);
    }

    const el = document.querySelectorAll(".tabs");
    var instance = M.Tabs.init(el, { swipeable : true });
  }, [props.datos]);

  useEffect(() => {
    if( Ejecutado ) {
      let lista_hoy = [];
      let lista_A = [];
      let lista_B = [];
      props.datos.datos.forEach(registro => {
        // Obtener fecha mínima
        const fecha_cambio = moment(registro.datetime_actualizado).locale('es').format('YYYY-MM-DD HH:mm');
        // Obtener datos de hoy
        if(fecha_cambio.split(" ")[0] == Fecha) {
          lista_hoy = [...lista_hoy, registro];
          if(registro.turno == 'turno_A') {
            lista_A = [...lista_A, registro];
          } else {
            lista_B = [...lista_B, registro];
          }
        }
      });

      setDatosDia(lista_hoy);
      setDatosA(lista_A);
      setDatosB(lista_B);
    }
  }, [Fecha])
  
  
  const cambiarFechaEstados = (input) => {
    const valor = document.getElementById('input-dia-estados').value;
    if( Fecha != valor ) setFecha(valor);
    
  }

  const descargarHistorialEstados = () => {
    // Obtener planta
    const planta = props.planta;
    descargarHistorial(planta, DatosDia, DatosA, DatosB, Fecha);
  }
  
  return (
      <div id={ `modal-historial-estados-${props.planta}` } className="modal estados">
        {
          {
            null: <Loader />,
            true: <MsjError />,
            false:
              <div className="modal-content">
                <h5>HISTORIAL ESTADOS PLANTA { (props.planta).toUpperCase() }</h5>

                <div className="cont-fecha-descarga">
                  <input 
                    type="date" 
                    id="input-dia-estados" 
                    max={ FechaMaxima }  
                    value={ Fecha } 
                    min={ FechaMinima } 
                    onChange={ cambiarFechaEstados }
                  />

                <button 
                  className="btn waves-effect waves-light light-blue darken-4 btn-descargar-historial" 
                  onClick={ descargarHistorialEstados }
                >
                  <i className="material-icons left">download</i>
                  DESCARGAR
                </button>


                </div>

                <ul id="tabs-estados" className="clase tabs">
                  <li className="tab col s3"><a className="active" href="#tab-estados-dia">RESUMEN DIARIO</a></li>
                  <li className="tab col s3"><a href="#tab-estados-A">TURNO A</a></li>
                  <li className="tab col s3"><a href="#tab-estados-B">TURNO B</a></li>
                </ul>

                <div id="tab-estados-dia" className="col s12 cont-tab estados">
                  <div className="cont-historial-estados scroll">
                    {
                      props.datos.error === null
                      ? <center><Loader /></center>
                      : props.datos.error === true
                        ? <div>ERROR</div>
                        : 
                          DatosDia.length > 0
                          ?
                          <table id="tabla-resumen-diario-estados" className='tabla-historial-estados'>
                            <thead>
                              <tr>
                                <th>FECHA</th>
                                <th>CICLO</th>
                                <th>MÓDULO</th>
                                <th>ESTADO</th>
                                <th>USUARIO</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                DatosDia.map(( fila, index ) => (
                                  <tr key={ index }>
                                    <td>{ (fila.datetime_actualizado) }</td>
                                    <td>{ fila.ciclo }</td>
                                    <td>{ fila.modulo }</td>
                                    <td>{ (fila.estado).replace(/_/g, ' ') }</td>
                                    <td>{ (fila.usuario).replace(/_/g, " ") }</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                          : <div className='div-no-estados'><p>No existen registros</p></div>
                    }
                  </div>
                </div>

                <div id="tab-estados-A" className="col s12 cont-tab estados">
                  <div className="cont-historial-estados scroll">
                    {
                      props.datos.error === null
                      ? <center><Loader /></center>
                      : props.datos.error === true
                        ? <div>ERROR</div>
                        : 
                        DatosA.length > 0
                        ?
                          <table id="tabla-resumen-A-estados" className='tabla-historial-estados'>
                            <thead>
                              <tr>
                                <th>FECHA</th>
                                <th>CICLO</th>
                                <th>MÓDULO</th>
                                <th>ESTADO</th>
                                <th>USUARIO</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                DatosA.map(( fila, index ) => (
                                  <tr key={ index }>
                                    <td>{ (fila.datetime_actualizado).split(" ")[0] }</td>
                                    <td>{ fila.ciclo }</td>
                                    <td>{ fila.modulo }</td>
                                    <td>{ (fila.estado).replace(/_/g, ' ') }</td>
                                    <td>{ (fila.usuario).replace(/_/g, " ") }</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        : <div className='div-no-estados'><p>No existen registros</p></div>
                    }
                  </div>
                </div>

                <div id="tab-estados-B" className="col s12 cont-tab estados">
                  <div className="cont-historial-estados scroll">
                    {
                      props.datos.error === null
                      ? <center><Loader /></center>
                      : props.datos.error === true
                        ? <div>ERROR</div>
                        : 
                          DatosB.length > 0
                          ?
                          <table id="tabla-resumen-B-estados" className='tabla-historial-estados'>
                            <thead>
                              <tr>
                                <th>FECHA</th>
                                <th>CICLO</th>
                                <th>MÓDULO</th>
                                <th>ESTADO</th>
                                <th>USUARIO</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                
                                DatosB.map(( fila, index ) => (
                                  <tr key={ index }>
                                    <td>{ (fila.datetime_actualizado).split(" ")[0] }</td>
                                    <td>{ fila.ciclo }</td>
                                    <td>{ fila.modulo }</td>
                                    <td>{ (fila.estado).replace(/_/g, ' ') }</td>
                                    <td>{ (fila.usuario).replace(/_/g, " ") }</td>
                                  </tr>
                                ))
                                
                              }
                            </tbody>
                          </table>
                          : <div className='div-no-estados'><p>No existen registros</p></div>
                    }
                  </div>
                </div>
              </div>
          }[ props.datos.error ]
        }
      </div>
  ) 
}
