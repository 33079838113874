// React
import { useEffect, useState } from 'react';

// Componentes 
import { Tarjeta } from './components/Tarjeta';
import { Loader } from '../../components/Loader';

// Hooks
import { useSesion } from '../../hooks/useSesion';
import { useRazonLixiviacion } from '../../hooks/useRazonLixiviacion';
import useControlRiego from '../../hooks/useControlRiego';
import { useResumenModulos } from '../../hooks/useResumenModulos';
import { MsjError } from '../../components/MsjError';

// Estilos
import './styles/inicio.scss';
import './styles/inicio_responsivo.scss';


export const InicioPage = () => {
    const { obtenerTodoRlixAPI } = useRazonLixiviacion();
    const { obtenerControlRiegoAPI } = useControlRiego();
    const { obtenerResumenAPI } = useResumenModulos();

    const [Limpieza, setLimpieza] = useState(false);
    const [DatosRlix, setDatosRlix] = useState({ datos: false });
    const [DatosRiego, setDatosRiego] = useState({ datos: false });
    const [DatosEstados, setDatosEstados] = useState({ datos: false })

    const { limpiarStore } =  useSesion();

    useEffect(() => {
      if(Limpieza === false) {
        setLimpieza(true);
        limpiarStore();
        obtenerRlix();
        obtenerRiego();
        obtenerEstados();
      }
    }, []);

    
    const obtenerRlix = async() => {
        // Obtener datos
        const met = await obtenerTodoRlixAPI(1, true);
        const oxe = await obtenerTodoRlixAPI(2, true);

        if( met == false || oxe  == false ) {
            setDatosRlix({
                datos: null,
            });
        } else {
            setDatosRlix({
                datos: true,
                met,
                oxe
            });
        }
    }

    const obtenerRiego = async() => {
        // Obtener datos
        const datos_met = await obtenerControlRiegoAPI('met', true);
        const datos_oxe = await obtenerControlRiegoAPI('oxe', true);

        if( datos_met == false || datos_oxe == false ) {
            setDatosRiego({
                datos: null,
            });
        } else {
            // Obtener suma y promedio MET del último ciclo
            const { modulos:modulos_met, grupos:grupos_met } = datos_met;
                    
            // Obtener suma y promedio OXE del último ciclo
            const { modulos:modulos_oxe, grupos:grupos_oxe } = datos_oxe;
            
            setDatosRiego({
                datos: true,
                nombre: 'CONTROL DE RIEGO',
                MET: {
                    modulos: modulos_met,
                    valores: grupos_met
                },
                OXE: {
                    modulos: modulos_oxe,
                    valores: grupos_oxe
                }
            });
        }
    }

    const obtenerEstados = async() => {
        const met = await obtenerResumenAPI('met', true);
        const oxe = await obtenerResumenAPI('oxe', true);

        if( met == false || oxe  == false ) {
            setDatosEstados({
                datos: null,
            });
        } else {
            setDatosEstados({
                datos: true,
                met,
                oxe
            });
        }

        
    }

    return (
        DatosRlix.datos === false || DatosRiego.datos === false || DatosEstados.datos === false
        ? <Loader mensaje="" />
        : 
            DatosRlix.datos === null || DatosRiego.datos === null || DatosEstados.datos === null
            ? <MsjError />
            :
                <div className="contenido">
                    <div className="cont-reportabilidad scroll">
                        <div className="contenedor">
                            <Tarjeta 
                                clases="tarjeta-reporte graf-resumen-modulos" 
                                nombre="RESUMEN MÓDULOS"
                                pestanias={[]}
                                datos_estados={ DatosEstados }
                            />
                        </div>

                        <div>
                            <div className="contenedor">
                                <Tarjeta 
                                    clases="tarjeta-reporte graf-rlix"
                                    nombre="RAZÓN DE LIXIVIACIÓN"
                                    datos_rlix={ DatosRlix }
                                />
                            </div>

                            <div className="contenedor">
                                <Tarjeta 
                                    clases="tarjeta-reporte graf-control-riego"
                                    nombre="CONTROL DE RIEGO"
                                    datos_riego={ DatosRiego }
                                />
                            </div>
                        </div>
                    </div>
                </div>
    )
}
