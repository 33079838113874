import { Tabla } from './Tabla'

export const Pestania = (props) => {
  return (
    <div id={ props.id_tab } className={ props.clases }>
      <div className='cont-tabla scroll'>
        <Tabla
          tipo={ props.tipo }
          cambio={ props.cambio } 
          setCambio={ props.setCambio } 
          rom={ props.rom } 
          setROM={ props.setROM } 
          tabla={ props.tabla } 
          interfaz={ props.interfaz } 
          filas={ props.datos } 
          clases={ `tabla-rom tabla-${ props.turno }` } 
        />
      </div>
    </div>
  
  )
}
