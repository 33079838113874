import { useEffect, useState } from "react";
import { Loader } from "../../../components/Loader";


export const ResumenModulos = ( props ) => {
    const [Ejecutado, setEjecutado] = useState(false);
    const [EstadosMET, setEstadosMET] = useState([]);
    const [EstadosOXE, setEstadosOXE] = useState([]);
   

    useEffect(() => {
        if(!Ejecutado && props.datos.datos === true ) {
            const { met, oxe } = props.datos;
            contarEstadosMET(met);
            contarEstadosOXE(oxe);
        }
    }, [props.datos]);


    const contarEstadosMET = (datos) => {
        let cont_estados = {
            Vacio: 0, Inicio_Apilamiento: 0, Fin_Apilamiento: 0,Armado_Parrilla: 0, Humectacion: 0, Riego: 0,
            Detenido_por_Fuerza_Mayor: 0, Drenaje: 0, Desarme_de_Parrilla: 0, Inicio_Derripado: 0, Fin_Derripado: 0
        }

        // MET
        const { pila_este, pila_oeste } = datos;

        pila_este.forEach(({ estado }) => {
            const estado_modulo = estado.replace(/ /g, '_');
            cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
        })

        pila_oeste.forEach(({ estado }) => {
            const estado_modulo = estado.replace(/ /g, '_');
            cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
        })

        setEstadosMET([
            [ 'Vacío(s)', cont_estados.Vacio ],
            [ 'Inicio apilamiento', cont_estados.Inicio_Apilamiento ],
            [ 'Fin apilamiento', cont_estados.Fin_Apilamiento ],
            [ 'Armado parrilla', cont_estados.Armado_Parrilla ],
            [ 'En humectación', cont_estados.Humectacion ],
            [ 'En riego', cont_estados.Riego ],
            [ 'Detenido por fuerza mayor', cont_estados.Detenido_por_Fuerza_Mayor ],
            [ 'Drenaje', cont_estados.Drenaje ],
            [ 'Desarme de parrilla', cont_estados.Desarme_de_Parrilla ],
            [ 'Inicio derripado', cont_estados.Inicio_Derripado ],
            [ 'Fin derripado', cont_estados.Fin_Derripado ],
            [ 'Total módulos', pila_este.length + pila_oeste.length ]
        ]);
    }

    const contarEstadosOXE = (datos) => {
        // console.log(datos);
        let cont_estados = {
            Vacio: 0, Inicio_Apilamiento: 0, Fin_Apilamiento: 0, Ruteo: 0, Armado_Parrilla: 0, Humectacion: 0, Riego: 0,
            Detenido_por_Fuerza_Mayor: 0, Drenaje: 0, Desarme_de_Parrilla: 0, Inicio_Derripado: 0, Fin_Derripado: 0
        }
        
        let totalModulos = 0;
        datos.forEach(({ modulos }) => {
            modulos.forEach(modulo => {
                totalModulos += 1;
                const estado_modulo = modulo.estado;
                cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
            })
        })

        setEstadosOXE([
            [ 'Vacío(s)', cont_estados.Vacio ],
            [ 'Inicio apilamiento', cont_estados.Inicio_Apilamiento ],
            [ 'Fin apilamiento', cont_estados.Fin_Apilamiento ],
            [ 'Ruteado(s)', cont_estados.Ruteo ],
            [ 'Armado parrilla', cont_estados.Armado_Parrilla ],
            [ 'En humectación', cont_estados.Humectacion ],
            [ 'En riego', cont_estados.Riego ],
            [ 'Detenido por fuerza mayor', cont_estados.Detenido_por_Fuerza_Mayor ],
            [ 'Drenaje', cont_estados.Drenaje ],
            [ 'Desarme de parrilla', cont_estados.Desarme_de_Parrilla ],
            [ 'Inicio derripado', cont_estados.Inicio_Derripado ],
            [ 'Fin derripado', cont_estados.Fin_Derripado ],
            [ 'Total módulos', totalModulos ]
        ])
    }

    return (
        <div className="cont-grafs-resumen">
            {/* PLANTA MET */}
            <div>
                <h6>Planta MET</h6>
                {
                    EstadosMET.length === 0 
                    ? <center><Loader /></center>
                    :
                    <table className="tabla-estados-inicio" id="tabla-estados">
                        <tbody>
                            {
                            
                                EstadosMET.map((fila, index)=> (
                                    fila[1] == 0
                                    ? null
                                    :
                                    <tr key={index}>
                                    {
                                        fila.map((celda,subindex)=>(
                                            <td className={ subindex % 2 == 0 ? 'nombre-estado' : ''} width={100} key={subindex}>{celda}</td>
                                        ))
                                    }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }
            </div>

            <br />

            {/* PLANTE OXE */}
            <div>
                <h6>Planta OXE</h6>
                {
                    EstadosOXE.length === 0 
                    ? <center><Loader /></center>
                    :
                    <table className="tabla-estados-inicio" id="tabla-estados">
                        <tbody>
                            {
                                EstadosOXE.map((fila, index)=> (
                                    fila[1] == 0
                                    ? null
                                    :
                                    <tr key={index}>
                                    {
                                        fila.map((celda,subindex)=>(
                                            <td className={ subindex % 2 == 0 ? 'nombre-estado' : ''} width={100} key={subindex}>{celda}</td>
                                        ))
                                    }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}
