import localforage from 'localforage';
import storage from 'redux-persist/lib/storage'; 
import { persistReducer } from 'redux-persist';

// Slice
import autentificacionSlice from './autentificacion/autentificacionSlice';
import ControlRiegoSlice from './digitalizacion/ControlRiegoSlice';
import RampaHumectacionSlice from './digitalizacion/RampaHumectacionSlice';
import RazonLixiviacionSlice from './digitalizacion/RazonLixiviacionSlice';
import ResumenModulosOXESlice from './digitalizacion/ResumenModulosOXESlice';
import ResumenModulosMETSlice from './digitalizacion/ResumenModulosMETSlice';
import SimSXSlice from './digitalizacion/SimSXSlice';
import AnalisisQuimicoSlice from './digitalizacion/AnalisisQuimicoSlice';
import ReportePreliminarSlice from './digitalizacion/ReportePreliminarSlice';


const persisAutentificacion = {
    key: 'sesion',
    version: 1,
    storage: storage
}

const persistControlRiego = {
    key: 'control_riego',
    version: 1,
    storage: localforage
};

const persistRampa = {
    key: 'rampa_humectacion',
    version: 1,
    storage: localforage
};

const persistRlix = {
    key: 'rlix',
    version: 1,
    storage: localforage
};

const persistResumenMET = {
    key: 'resumen_modulos_met',
    version: 1,
    storage: localforage
}

const persistResumenOXE = {
    key: 'resumen_modulos_OXE',
    version: 1,
    storage: localforage
}

const persistSimSX = {
    key: 'sim_sx',
    version: 1,
    storage: localforage
}

const persistAnalisisQuimico = {
    key: 'analisis_quimico',
    version: 1,
    storage: localforage
}

const persistReportePreliminar = {
    key: 'reporte_preliminar',
    version: 1,
    storage: localforage
}

export const autentificacion = persistReducer(persisAutentificacion, autentificacionSlice);
export const ControlRiego = persistReducer(persistControlRiego, ControlRiegoSlice);
export const Rlix = persistReducer(persistRlix, RazonLixiviacionSlice);
export const RampaHumectacion = persistReducer(persistRampa, RampaHumectacionSlice);
export const SimSX = persistReducer(persistSimSX, SimSXSlice);
export const AnalisisQuimico = persistReducer(persistAnalisisQuimico, AnalisisQuimicoSlice);
export const ReportePreliminar = persistReducer(persistReportePreliminar, ReportePreliminarSlice);



export const ResumenModulosOXE = persistReducer(persistResumenOXE, ResumenModulosOXESlice)
export const ResumenModulosMET = persistReducer(persistResumenMET, ResumenModulosMETSlice)

