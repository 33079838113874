import digitalizacionAPI from "../../API/digitalizacionAPI";
// Store
import { iniciarSesion } from "../../store/autentificacion/autentificacionSlice";
import { useDispatch } from "react-redux";
import { limpiarAnalisisQuimico } from "../../store/digitalizacion/AnalisisQuimicoSlice";
import { limpiarControlRiego } from "../../store/digitalizacion/ControlRiegoSlice";
import { limpiarRampaHumectacion } from "../../store/digitalizacion/RampaHumectacionSlice";
import { limpiarRazonLixiviacion } from "../../store/digitalizacion/RazonLixiviacionSlice";
import { limpiarReportePreliminar } from "../../store/digitalizacion/ReportePreliminarSlice";
import { limpiarResumenModulosMET } from "../../store/digitalizacion/ResumenModulosMETSlice";
import { limpiarResumenModulosOXE } from "../../store/digitalizacion/ResumenModulosOXESlice";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";
import { useNavigate } from "react-router-dom";
import { limpiarSimSX } from "../../store/digitalizacion/SimSXSlice";

export const useSesion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validarToken = (token) => {
        dispatch( cerrarSesion() );
        digitalizacionAPI.get('/auth/renovar', { headers: { 'x-token': token } }).then(res => {
            const { correo, nombre, rol, token, uid } = res.data;
            dispatch( iniciarSesion({ correo, nombre, rol, token, uid }) );
            navigate('/');

        }).catch(err => {
            console.log(err);
        });
    }

    const limpiarStore = () => {
        dispatch( limpiarControlRiego() );
        dispatch( limpiarRazonLixiviacion() );
        dispatch( limpiarResumenModulosMET() );
        dispatch( limpiarResumenModulosOXE() );
        dispatch( limpiarSimSX() );
    }

    return {
        validarToken,
        limpiarStore
    }
}