import React from 'react'

export const Tabla = (props) => {
  return (
    <table className={ props.clases + 'tabla-control-riego'}>
        <thead>
            <tr>
                <th colSpan={ 1 }>
                </th>
            
                {
                    props.modulos.map((modulo, index) => (
                        props.planta === 'met'
                        ? <th className={ index % 2 == 0 ? 'encabezado modulo doble thcolor' : 'encabezado modulo doble' }  key={index} colSpan={ 3 }>{ modulo }</th>
                        : <th className={ index % 2 == 0 ? 'encabezado modulo doble thcolor' : 'encabezado modulo doble' }  key={index} colSpan={ 2 }>{ modulo }</th>
                    ))
                }
            </tr>
            
            <tr>
                <th>FECHA</th>
                {
                    props.modulos.map((modulo, index) => (
                        props.planta === 'met'
                        ?
                            <React.Fragment key={ index }>
                                <th className={ `encabezado sub ${ index % 2 == 0 ? 'color-sub' : null }` }>Horas</th>
                                <th className={ `encabezado sub ${ index % 2 == 0 ? 'color-sub' : null }` }>Presión <small>(kPa)</small></th>
                                <th className={ `encabezado sub ${ index % 2 == 0 ? 'color-sub' : null }` }>Flujo <small>[m3/hr]</small></th>
                            </React.Fragment>
                        :
                            <React.Fragment key={ index }>
                                <th className={ `encabezado sub ${ index % 2 == 0 ? 'color-sub' : null }` }>Horas</th>
                                <th className={ `encabezado sub ${ index % 2 == 0 ? 'color-sub' : null }` }>Flujo <small>[m3/hr]</small></th>
                            </React.Fragment>
                    ))
                }
            </tr>
        </thead>


        <tbody>
            {
                props.filas.map((fila, index) => (
                    <tr key={ index }>
                        {
                            fila.map((celda, subindex) => (
                                <td key={ subindex } className={ celda.clases }>{ celda.valor == 'NaN' ? '0.00' : celda.valor }</td>
                            ))
                        }
                    </tr>
                ))
            }
        </tbody>
    </table>
  )
}
