import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';

import { useCosecha } from '../../../hooks/useCosecha';

export const Grafico = ( props ) => {
    const { generarSeriesAnio, generarSeriesMes } = useCosecha();
    
    const [Series, setSeries] = useState([]);
    const [Opciones, setOpciones] = useState([]);
    const [Ejecutado, setEjecutado] = useState(false);

    useEffect(() => {
      if( !Ejecutado && props.interfaz == true  ) {
        setEjecutado( true );

        if( props.id_tab == 'tab-informe-grafico-mensual' ) {
            // Generar datos gráfico
            const { dias, serie_real, serie_presupuesto, serie_plan_maestro } = props.datos;
            setSeries([
                {
                    name: 'Real',
                    data: serie_real
                },
                {
                    name: 'Presupuesto',
                    data: serie_presupuesto
                },
                {
                    name: 'Plan maestro',
                    data: serie_plan_maestro
                }
            ]);

            setOpciones({
                chart: {
                    height: 300,
                    type: 'bar',
                },
                colors: ['#e07b40', '#0097a7', '#01579b'],

                dataLabels: {
                    enabled: false,
                },

                stroke: {
                    width: 2,
                    curve: 'smooth'
                },

                grid: {
                    borderColor: '#f2f2f2',
                    row: {
                        colors: ['#f2f2f2', 'transparent'],
                        opacity: 0.5
                    },
                },

                markers: {
                    size: 1
                },

                xaxis: {
                    categories: dias,
                    title: {
                        text: 'Día'
                    }
                },
                yaxis: {
                    title: {
                        text: 'TON'
                    },
                    tickAmount: 5,
                }
            });
        } else {
            const { meses, serie_real, serie_presupuesto, serie_plan_maestro } = props.datos;
            setSeries([
                {
                    name: 'Real',
                    data: serie_real
                },
                {
                    name: 'Presupuesto',
                    data: serie_presupuesto
                },
                {
                    name: 'Plan maestro',
                    data: serie_plan_maestro
                }
            ]);

            setOpciones({
                chart: {
                    height: 300,
                    type: 'bar',
                },
                colors: ['#e07b40', '#0097a7', '#01579b'],

                dataLabels: {
                    enabled: false,
                },

                stroke: {
                    width: 2,
                    curve: 'smooth'
                },

                grid: {
                    borderColor: '#f2f2f2',
                    row: {
                        colors: ['#f2f2f2', 'transparent'],
                        opacity: 0.5
                    },
                },

                markers: {
                    size: 1
                },

                xaxis: {
                    categories: meses,
                    title: {
                        text: 'Mes'
                    }
                },
                yaxis: {
                    title: {
                        text: 'TON'
                    },
                    tickAmount: 5,
                }
            });
        }       
      }
    }, [props.interfaz]);

    // useEffect(() => {
    //     console.log(props.aa);
    //     console.log(props.datos);
    //     console.log(Series);
    // }, [Series])
    

    
    return (
        <div className="tab-informe activo" id={ props.id_tab }>
            {
                Series.length === 0
                ? <div>Cargando...</div>
                :
                    <div>
                        {/* Gráfico */}
                        <ReactApexChart 
                            options={ Opciones } 
                            series={ Series } 
                            type="bar" 
                            height={ 300 } 
                            id={ props.id_grafico }
                        />
                    
                        {/* Valores tablas */}
                        <table className="tday-tabla" id="tabla-moth-to-day">
                            <thead>
                                {
                                    props.id_tab == 'tab-informe-grafico-mensual'
                                    ?
                                        <tr>
                                            <th>MONTH TO DAY REAL</th>
                                            <th>MONTH TO DAY PRESUPUESTO</th>
                                            <th>MONTH TO DAY PLAN MAESTRO</th>
                                        </tr>
                                    :
                                        <tr>
                                            <th>YEAR TO DAY REAL</th>
                                            <th>YEAR TO DAY PRESUPUESTO</th>
                                            <th>YEAR TO DAY PLAN MAESTRO</th>
                                        </tr>
                                }
                                
                            </thead>

                            <tbody>
                                <tr>
                                    <td>{ (props.datos.total_cosecha_real).toLocaleString("es-CL") }</td>
                                    <td>{ (props.datos.total_presupuesto).toLocaleString("es-CL") }</td>
                                    <td>{ (props.datos.total_plan_maestro).toLocaleString("es-CL") }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            }
        </div>   
    )
}
