import { useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import M from 'materialize-css'


export const TablaResumenOXE = (props) => {
    const historialModulo = (modulo, nombre, id_franja) =>{
        var elems = document.getElementById('modal-historial-oxe');
        var instance = M.Modal.init(elems);

        props.setModuloModal({modulo, nombre, id_franja})

        instance.open();
    }
    
    // VARIABLES DE ENTORNO
    const [Filas, setFilas] = useState([]);
    const [Franjas, setFranjas] = useState([]);
    const [Ejecutado, setEjecutado] = useState(false)

    useEffect(()=>{
        if(props.datos.estado === true && Ejecutado === false) {
            setEjecutado(true);

            // Generar filas de tabla
            let lista_franjas = [];

            // Extraer datos
            const franjas =  props.datos.franjas;

            // Obtener todos los módulos
            let modulos_franjas = [];
            for(let i = 0; i < franjas.length; i++) {
                lista_franjas = [...lista_franjas, { nombre: franjas[i].franja, id: franjas[i].id_franja }]
                modulos_franjas = [...modulos_franjas, ...franjas[i].modulos]
            }
            
            // FILAS PILA B
            let id = 4;
            let filas_modulos = [];
            for(let i = 4; i > 0; i--) {
                let fila_modulo = [];
                modulos_franjas.forEach(modulo => {
                    const { id_franja, nombre_pila:franja, nombre_modulo:nombre, id_modulo, estado } = modulo;

                    if(nombre == `B${i}`) {
                        fila_modulo = [...fila_modulo, {
                            id_franja,
                            modulo: id_modulo,
                            nombre: nombre,
                            estado: estado,
                            franja: franja
                        }]
                    }
                });

                filas_modulos = [...filas_modulos, fila_modulo];
            }

            // FILA A
            for(let i = 4; i > 0; i--) {;
                let fila_modulo = [];
                modulos_franjas.forEach(modulo => {
                    const { id_franja, nombre_pila:franja, nombre_modulo:nombre, id_modulo, estado } = modulo;

                    if(nombre == `A${i}`) {
                        fila_modulo = [...fila_modulo, {
                            id_franja,
                            modulo: id_modulo,
                            nombre: nombre,
                            estado: estado,
                            franja: franja
                        }]
                    }
                });

                filas_modulos = [...filas_modulos, fila_modulo];
            }

            setFranjas(lista_franjas);
            setFilas(filas_modulos);
        }
    },[props.datos.estado])
    
  return (
    <table className="tabla-resumen" id="tabla-resumen-oxe">
        <thead>
            <tr>   
                <th width={ 50 }></th> 
                <th width={ 50 }></th> 
                {/* Listar franjas  */}
                {
                    Franjas.map((franja, index)=> (
                        <th width={ 250 } key={ index }>{ franja.nombre }</th>
                    ))
                }
            </tr>
        </thead>

        <tbody>
            {/* 
                Listar filas y celdas. Se valida si el index es 0 o 4 ya que corresponden al inicio del bloque A
                y B respectivamente. Además, se hace uso de un switch ya que se requieren listar los números de 
                módulo.

                Al funcionar como matriz, los botones se encuentran en la celda de estado.
            
            */}
            {
                Filas.map((fila, index) => (
                    
                    index === 0
                    ? 
                        <tr key={ index }>
                            <td className='bloque' rowSpan={ 4 }>B</td>
                            <td className='modulo'>4</td>
                            {
                                fila.map((celda, subindex) => (
                                    <td key={ subindex } >
                                        <button id={ celda.franja } className={"btn waves-effect waves-light btn-modulo "+(celda.estado).replace(/\s/g, '_')} 
                                        onClick={ () => { historialModulo( celda.modulo, celda.nombre, Franjas[subindex].id ) }}
                                        >
                                            { celda.estado }
                                        </button>
                                    </td>
                                ))
                            }
                        </tr>
                    :   
                        index === 4
                        ? 
                            <tr key={ index }>
                                <td className='bloque' rowSpan={ 4 }>A</td>
                                <td className='modulo'>4</td>
                                {
                                    fila.map((celda, subindex) => (
                                        <td key={ subindex } >
                                            <button id={ celda.franja } className={"btn waves-effect waves-light btn-modulo "+(celda.estado).replace(/\s/g, '_')}
                                            onClick={ () => { historialModulo( celda.modulo, celda.nombre, Franjas[subindex].id  ) } }
                                            >
                                                { celda.estado }
                                            </button>
                                        </td>
                                    ))
                                }
                            </tr>
                        : 
                            <tr key={ index }>

                                {
                                    fila.map((celda, subindex) => (
                                        subindex === 0
                                        ?   
                                            <React.Fragment key={ subindex }>
                                                {{
                                                    1: <td className="modulo">3</td>,
                                                    2: <td className="modulo">2</td>,
                                                    3: <td className="modulo">1</td>,
                                                    5: <td className="modulo">3</td>,
                                                    6: <td className="modulo">2</td>,
                                                    7: <td className="modulo">1</td>
                                                }[index]}

                                                <td>
                                                    <button id={ celda.franja } className={"btn waves-effect waves-light btn-modulo "+(celda.estado).replace(/\s/g, '_')}
                                                    onClick={() => { historialModulo( celda.modulo, celda.nombre, Franjas[subindex].id  ) }}
                                                    >
                                                        { celda.estado }
                                                    </button>
                                                </td>
                                            </React.Fragment>
                                        : 
                                        <td key={ subindex } >
                                            <button id={ celda.franja } className={"btn waves-effect waves-light btn-modulo "+(celda.estado).replace(/\s/g, '_')}
                                            onClick={() => { historialModulo( celda.modulo, celda.nombre, Franjas[subindex].id  ) }}
                                            >
                                                { celda.estado }
                                            </button>
                                        </td>
                                    ))
                                }
                            </tr>
                ))
            } 
        </tbody>
    </table>

  )
}
