import { createSlice } from "@reduxjs/toolkit";

const initialState = { contenido: {} };

const AnalisisQuimicoSlice = createSlice({
    name: 'AnalisisQuimicoSlice',
    initialState,
    reducers: {
        setAnalisisQuimico: (state, { payload }) => {
            state.contenido = payload;
        },

        limpiarAnalisisQuimico: (state) => {
            state.contenido = {};
        }
    }
});

export const { setAnalisisQuimico, limpiarAnalisisQuimico } = AnalisisQuimicoSlice.actions;
export default AnalisisQuimicoSlice.reducer;