import { useEffect, useState } from "react";

export const TablaSimple = (props) => {  
    return (
        <table className={ props.claseTabla } id={ props.id_tabla }>
            <thead>
                {
                    props.cabecera.map((fila, index) => (
                        <tr key={ index }>
                            {
                                fila.map((celda, subindex) => (
                                    celda.cols === 0
                                    ? <th key={ subindex } className='celda-vacia'></th>
                                    : <th key={ subindex } colSpan={ celda.cols } className={ fila.length === 1 ? 'un-th' : '' }>{ celda.valor }</th>
                                ))
                            }
                        </tr>
                    ))
                }
            </thead>

            <tbody>
                {
                    props.filas.map((fila, index) => (
                        <tr key={ index }>
                            {
                                fila.map((celda, subindex) => (
                                    celda.valor === 'no valido'
                                    ? <td rowSpan={ celda.rows }  key={ subindex } className='celda-no-valida'></td>
                                    : 
                                        celda.clases
                                        ?
                                            <td 
                                                rowSpan={ celda.rows } key={ subindex }  
                                                className={ celda.clases  }
                                            >{ celda.valor != '-' && celda.valor != 'undefined' && celda.valor != '' && celda.valor != null ? (celda.valor).toLocaleString("es-CL") : celda.valor }</td>
                                        :
                                            <td 
                                                rowSpan={ celda.rows } key={ subindex }  
                                                className={ subindex === 1 ? 'turno-a' : subindex !== 0 ? 'turno-b' : ''  }
                                            >{ celda.valor != '-' && celda.valor != 'undefined' && celda.valor != '' && celda.valor != null ? (celda.valor).toLocaleString("es-CL") : celda.valor }</td>
                                ))
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
