import React, { useState } from 'react'
import { useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useParams } from "react-router-dom";
import { Loader } from '../../../components/Loader';
import { useResumenModulos } from '../../../hooks/useResumenModulos';


import '../styles/historial.scss'

export const ModalHistorial = (props) => {
    const { historialModuloAPI } = useResumenModulos();

    // const [Modulo, setModulo] = useState('')
    const [Cargando, setCargando] = useState(true)
    const [Historial, setHistorial] = useState([{ 
      estado: '',
      usuario: '',
      timestamp: ''
    }]);

    // Obtener planta desde URL
    const { planta } = useParams();

    useEffect(() => {
      setCargando(true);
      obtenerHistorial(props.modulo.modulo)
    }, [props.modulo]);

    const obtenerHistorial = async(id_modulo) => {
      const historial = await historialModuloAPI(planta, id_modulo);
      historial.reverse();
      setCargando(false);
      setHistorial(historial);
    }

    return (
        <div id={ `modal-historial-${planta}` } className="modal modal-historial-modulo">
            <div className="modal-content">
                <h5>LÍNEA DE TIEMPO MÓDULO { planta == 'met' ? `${props.modulo.nombre} ${ props.modulo.pila }` : `${ props.modulo.nombre } FRANJA ${ props.modulo.id_franja }` }</h5>

                
                  {
                    Cargando == true
                    ? <div className="cont-loader lmodal">
                        <Loader mensaje="" />
                      </div>
                    :
                      <div className="tmln tmln--box tmln--hr">
                        <ul className="tmln__list">
                          {
                            Historial.map(( registro, index ) => (
                              <li key={ index } className={ "tmln__item "+ (registro.estado).replace(/ /g, "_") }>
                                <span className={"estado-lntiempo "+ (registro.estado).replace(/ /g, "_") }>{ registro.estado }</span>
                                <div className="datos-transicion">
                                  <p className="usuario">
                                      <i className="material-icons left ">person</i>
                                      { registro.usuario.replace(/_/g, " ") }
                                  </p>

                                  <p className="fecha">
                                      <i className="material-icons left ">calendar_month</i>
                                      { (registro.timestamp).split(" ")[0] }
                                  </p>
                                </div>
                              </li>
                            ))
                          }
                          
                        </ul>
                      </div>
                      
                  }
            </div>
        </div>
    ) 
}


{/* <VerticalTimeline>
    {
    Historial.map((registro, index) => (
        <VerticalTimelineElement key={ index } 
        className={ "vertical-timeline-element--work "+ (registro.estado).replace(/\s/g, '_') }
        >
          <p className='nombre-estado'>{ registro.estado }</p>

          <p className="usuario">
              <i className="material-icons left ">person</i>
              { registro.usuario.replace(/_/g, " ") }
          </p>

          <p className="fecha">
              <i className="material-icons left ">calendar_month</i>
              { (registro.timestamp).split(" ")[0] }
          </p>
        </VerticalTimelineElement>
    
    ))
    }
</VerticalTimeline> */}