import React, { useEffect } from 'react'

import { TablaSimple } from './TablaSimple'

export const Lixiviacion = ( props ) => {
  return (
    props.Interfaz === false
    ? null
    :
    <div id="tab-lixiviacion-cosecha" className="tab-cosecha">
      <div className="contenido-cosecha scroll">
        <div className="cont-dos-tablas linea-cont-tabla">
          {/* Datos lixiviación */}
          <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } datos={ props.datos } setDatos={ props.setDatos } cabecera={ props.datos.tabla_lix.cabeceras } filas={ props.datos.tabla_lix.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_lix'/>

          <div>
            {/* Niveles ácido */}
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } datos={ props.datos } setDatos={ props.setDatos } cabecera={ props.datos.tabla_acido.cabeceras } filas={ props.datos.tabla_acido.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_acido'/>

            {/* Niveles agua */}
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } datos={ props.datos } setDatos={ props.setDatos } cabecera={ props.datos.tabla_lvl_agua.cabeceras } filas={ props.datos.tabla_lvl_agua.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_lvl_agua'/>
            
            {/* Nivel agua fresca */}
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } datos={ props.datos } setDatos={ props.setDatos } cabecera={ props.datos.tabla_agua_fresca.cabeceras } filas={ props.datos.tabla_agua_fresca.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_agua_fresca'/>
          </div>
        </div>

        <br />

        <div className='linea-cont-tabla'>
          {/* Flujo */}
          <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } datos={ props.datos } setDatos={ props.setDatos } cabecera={ props.datos.tabla_flujos.cabeceras } filas={ props.datos.tabla_flujos.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_flujos'/>
        </div>

        <br />
      </div>
    </div>
  )
}
