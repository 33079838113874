import React from 'react'

export const MsjError = () => {
  return (
    <div className="contenido">
        <div className="error-obt-datos">
            <i className="material-icons">error</i>
            <p>
                Ocurrió un error al cargar los datos, por favor contacte
                al equipo de soporte a través del formulario en la web
            </p>
        </div>
    </div>
  )
}
