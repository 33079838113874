import '../styles/modal_var.scss'
import { useCosecha } from '../../../hooks/useCosecha';
import { useEffect } from 'react';
import { useState } from 'react';

import { Loader } from '../../../components/Loader';


export const ModalVariables = () => {
    const { obtenerDatosGlobablesAPI, guardarDatosGlobales } = useCosecha();
    const [Interfaz, setInterfaz] = useState(false);
    const [Datos, setDatos] = useState([])

    useEffect(() => { 
        if( !Interfaz ) {
            obtenerVariables();
        }
    }, []);

    const obtenerVariables = async() => {
        const datos = await obtenerDatosGlobablesAPI();
        setDatos(datos);

        setInterfaz( true );
    }
    
    return (
        <div id="modal-variables-cosecha"  className="modal cosecha">
            <div className="modal-content">
                {
                    !Interfaz
                    ?
                        <div className="cont-loader lmodal"> 
                            <Loader />
                        </div>
                    :
                    <>
                        <h5>AJUSTAR VARIABLES COSECHA</h5>

                        <div className="cont-var-cosecha">
                            <label htmlFor="input-ef">EF</label>
                            <input type="text" id="input-ef" defaultValue={ Datos.ef }/>

                            <label htmlFor="input-eficiencia" >Eficiencia</label>
                            <input type="text" id="input-eficiencia" disabled={ true } defaultValue={ Datos.eficiencia }/>

                            <label htmlFor="input-n-celdas">N° celdas</label>
                            <input type="text" id="input-n-celdas" defaultValue={Datos.n_celdas}/>

                            <label htmlFor="input-faraday" >Const. faraday</label>
                            <input type="text" id="input-faraday" defaultValue={ Datos.const_faraday }/>

                            <label htmlFor="input-factor-calc">Factor cálculo</label>
                            <input type="text" id="input-factor-calc" disabled={ true }  defaultValue={ Datos.factor_calculo }/>
                        </div>
                        
                        <h6>Análisis ORG Cu++</h6>
                        <div className="cont-var-cosecha">
                            <label htmlFor="input-carg-a">Carg A</label>
                            <input type="text" id="input-carg-a" defaultValue={ Datos.carg_a }/>

                            <label htmlFor="input-carg-b">Carg B</label>
                            <input type="text" id="input-carg-b" defaultValue={ Datos.carg_b }/>

                            <label htmlFor="input-desc-a">Desc A</label>
                            <input type="text" id="input-desc-a" defaultValue={ Datos.desc_a }/>

                            <label htmlFor="input-desc-b">Desc B</label>
                            <input type="text" id="input-desc-b" defaultValue={ Datos.desc_b }/>
                        </div>
                        
                        <div className="cont-var-cosecha">
                            <button className="btn waves-effect waves-light  blue-grey modal-close">CANCELAR</button>
                            <button className="btn waves-effect waves-light light-blue darken-4 btn-aceptar" onClick={ guardarDatosGlobales }>GUARDAR</button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
