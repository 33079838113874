import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const TablaEstados = (props) => {
    // Obtener planta desde URL
    const { planta } = useParams();

    // Variables de estado
    const [Ejecutado, setEjecutado] = useState(false);
    const [Datos, setDatos] = useState([[]]);

    useEffect(() => {
        if(planta == 'met') {
            if( props.datos.estado == true && Ejecutado == false )  {
                setEjecutado(true);

                let cont_estados = {
                    Vacio: 0, Inicio_Apilamiento: 0, Fin_Apilamiento: 0,Armado_Parrilla: 0, Humectacion: 0, Riego: 0,
                    Detenido_por_Fuerza_Mayor: 0, Drenaje: 0, Desarme_de_Parrilla: 0, Inicio_Derripado: 0, Fin_Derripado: 0
                }
    
                // MET
                const { pila_este, pila_oeste } = props.datos;
    
                pila_este.forEach(({ estado }) => {
                    const estado_modulo = estado.replace(/ /g, '_');
                    cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
                    
                })
    
                pila_oeste.forEach(({ estado }) => {
                    const estado_modulo = estado.replace(/ /g, '_');
                    cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
                })
    
                setDatos([
                    [ 'Vacío(s)', cont_estados.Vacio,                                           'Inicio apilamiento', cont_estados.Inicio_Apilamiento ],
                    [ 'Fin apilamiento', cont_estados.Fin_Apilamiento,                          'Armado parrilla', cont_estados.Armado_Parrilla],
                    [ 'En humectación', cont_estados.Humectacion,                               'En riego', cont_estados.Riego ],
                    [ 'Detenido por fuerza mayor', cont_estados.Detenido_por_Fuerza_Mayor,      'Drenaje', cont_estados.Drenaje ],
                    [ 'Desarme de parrilla', cont_estados.Desarme_de_Parrilla,                  'Inicio derripado', cont_estados.Inicio_Derripado ],
                    [ 'Fin derripado', cont_estados.Fin_Derripado,                              'Total módulos', pila_este.length + pila_oeste.length]
                ]);
            }
        }
         else if(planta == 'oxe') {
            if( props.datos.estado == true && Ejecutado == false ) {
                setEjecutado(true);
                const data = props.datos.franjas;
                let cont_estados = {
                    Vacio: 0, Inicio_Apilamiento: 0, Fin_Apilamiento: 0, Ruteo: 0, Armado_Parrilla: 0, Humectacion: 0, Riego: 0,
                    Detenido_por_Fuerza_Mayor: 0, Drenaje: 0, Desarme_de_Parrilla: 0, Inicio_Derripado: 0, Fin_Derripado: 0
                }
                
                let totalModulos = 0;
                // console.log(data);
                data.forEach(({ modulos }) => {
                    modulos.forEach(modulo => {
                        totalModulos += 1;
                        const estado_modulo = (modulo.estado).replace(/ /g, "_");
                        cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
                    })
                })

                setDatos([
                    ['Vacío(s)', cont_estados.Vacio,                                        'Inicio apilamiento', cont_estados.Inicio_Apilamiento ],
                    ['Fin apilamiento', cont_estados.Fin_Apilamiento,                    'Ruteado(s)', cont_estados.Ruteo ],
                    ['Armado parrilla', cont_estados.Armado_Parrilla,                       'En humectación', cont_estados.Humectacion ],
                    ['En riego', cont_estados.Riego,                                        'Detenido por fuerza mayor', cont_estados.Detenido_por_Fuerza_Mayor ],
                    ['Drenaje', cont_estados.Drenaje,                                       'Desarme de parrilla', cont_estados.Desarme_de_Parrilla ],
                    ['Inicio derripado', cont_estados.Inicio_Derripado,                     'Fin derripado', cont_estados.Fin_Derripado ],
                    ['Total módulos', totalModulos, '', ''],
                ])
            }
        }
    }, [props.datos])
    
    return (
        <table className="tabla-estados" id="tabla-estados">
            <tbody>
                {
                    Datos.map((fila, index)=>(
                        <tr key={index} className={ index % 2 != 0 ? 'impar' : 'a' }>
                        {
                            fila.map((celda,subindex)=>(
                                <td className={ subindex % 2 == 0 ? 'nombre-estado' : ''} width={ subindex % 2 == 0 ? '150' : '70'} key={subindex}>{celda}</td>
                            ))
                        }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
