import { createSlice } from '@reduxjs/toolkit';


const initialState = { resumen: [] }

const resumenModulosMETSlice = createSlice({
    name: 'ResumenModulosMET',
    initialState,
    reducers: {
        setResumenModulosMET: (state, { payload }) => {
            state.resumen = payload
        },

        limpiarResumenModulosMET: (state) => {
            state.resumen = [];
        }
    }
});

export const { setResumenModulosMET, limpiarResumenModulosMET } = resumenModulosMETSlice.actions;
export default resumenModulosMETSlice.reducer;