import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import digitalizacionAPI from "../../API/digitalizacionAPI";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";

export const useAnalisisQuimico = () => {
    const XLSX = require("xlsx-js-style");
    const moment = require("moment");
    const dispatch = useDispatch();

    // Token
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};

    const obtenerAnalisisQuimicoAPI = async() => {
        let filas = [];
        try {
            await digitalizacionAPI.get('reportes/aq', conf_api).then( ({ data }) => {
                const { am_00, am_04, am_06, am_07, am_10, pm_12, pm_16, pm_18, pm_22 } = data.datos;
                filas = organizarTabla( am_10, pm_12, pm_16, pm_18, pm_22, am_00, am_04, am_06, am_07 );
            });
        
        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }

        return {
            cabecera: [
                [
                    { nombre: '', cols: 1 },
                    { nombre: '', cols: 1 },
                    { nombre: 'ANÁLISIS', cols: 7 }
                ],
                [
                    { nombre: 'HORARIO', cols: 1 },
                    { nombre: 'IDENTIFICACIÓN', cols: 1 },
                    { nombre: 'Cu++ g/L', cols: 1 },
                    { nombre: 'H2SO4 g/L', cols: 1 },
                    { nombre: 'pH', cols: 1 },
                    { nombre: 'FeT g/L', cols: 1 },
                    { nombre: 'Fe++ ppm', cols: 1 },
                    { nombre: 'Eh mV', cols: 1 },
                    { nombre: 'TSS ppm', cols: 1 }
                ]
            ],

            filas
        }
    }

    const obtenerAnalisisQuimicoFechaAPI = async( fecha ) => {
        let filas = [];
        try {
            const { data } = await digitalizacionAPI.get(`reportes/aq/${ fecha }`, conf_api );
            console.log( data );
            const { am_00, am_04, am_06, am_07, am_10, pm_12, pm_16, pm_18, pm_22 } = data.datos;
            filas = organizarTabla( am_10, pm_12, pm_16, pm_18, pm_22, am_00, am_04, am_06, am_07 );
        
        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }

        return {
            cabecera: [
                [
                    { nombre: '', cols: 1 },
                    { nombre: '', cols: 1 },
                    { nombre: 'ANÁLISIS', cols: 7 }
                ],
                [
                    { nombre: 'HORARIO', cols: 1 },
                    { nombre: 'IDENTIFICACIÓN', cols: 1 },
                    { nombre: 'Cu++ g/L', cols: 1 },
                    { nombre: 'H2SO4 g/L', cols: 1 },
                    { nombre: 'pH', cols: 1 },
                    { nombre: 'FeT g/L', cols: 1 },
                    { nombre: 'Fe++ ppm', cols: 1 },
                    { nombre: 'Eh mV', cols: 1 },
                    { nombre: 'TSS ppm', cols: 1 }
                ]
            ],

            filas
        }
    }

    const organizarTabla = ( datos_10, datos_12, datos_16, datos_18, datos_22, datos_00, datos_04, datos_06, datos_07 ) => {
        // Grupo 10:00
        let labels = [
            { nombre: 'Refino', llave: 'refino' }, 
            { nombre: 'PLS Pila este', llave: 'pls_pila_este'},
            { nombre: 'PLS Pila oeste', llave: 'pls_pila_oeste'},
            { nombre: 'PLS OXE', llave: 'pls_oxe' },
            { nombre: 'PLS Hidro', llave: 'pls_hidro' },
            { nombre: 'Perc Lix', llave: 'perc_lix' },
            { nombre: 'TK 101', llave: 'tk_101' },
            { nombre: 'PLS Ripio mirador', llave: 'pls_ripio_mirador' }    
        ];
        let filas = generarFilas( datos_10, labels, '10:00' );

        // Grupo 12:00
        labels = [
            { nombre: 'PLS OXE', llave: 'pls_oxe' },    
        ];
        filas = [...filas, ...generarFilas( datos_12, labels, '12:00' )];
        
        // Grupo 16:00
        labels = [
            { nombre: 'Refino', llave: 'refino' }, 
            { nombre: 'PLS Pila este', llave: 'pls_pila_este'},
            { nombre: 'PLS Pila oeste', llave: 'pls_pila_oeste'},
            { nombre: 'PLS OXE', llave: 'pls_oxe' },
            { nombre: 'PLS Hidro', llave: 'pls_hidro' },
            { nombre: 'Perc Lix', llave: 'perc_lix' },
            { nombre: 'Composito PLS OXE', llave: 'composito_pls_oxe' },
        ];
        filas = [...filas, ...generarFilas( datos_16, labels, '16:00' )];

        // Grupo 18:00
        labels = [
            { nombre: 'Alime. Pila ROM', llave: 'alime_pila_rom' }, 
            { nombre: 'Percolado', llave: 'percolado'},
            { nombre: 'ILS ROM', llave: 'ils_rom'},
            { nombre: 'ILS Dinámico', llave: 'ils_dinamico'},
            { nombre: 'TK 101', llave: 'tk_101'},
            { nombre: 'Comp. Esc N°1', llave: 'comp_esc_n1'},
            { nombre: 'Comp. Esc N°2', llave: 'comp_esc_n2'},
            { nombre: 'Comp. Esc N°3', llave: 'comp_esc_n3'},
            { nombre: 'Comp. Esc N°4', llave: 'comp_esc_n4'},
            { nombre: 'Comp. Esc N°5', llave: 'comp_esc_n5'},
            { nombre: 'Comp. Esc N°6', llave: 'comp_esc_n6'},
            { nombre: 'Comp. Esc N°7', llave: 'comp_esc_n7'},
            { nombre: 'Comp. Esc N°8', llave: 'comp_esc_n8'},
            { nombre: 'Comp. Esc N°9', llave: 'comp_esc_n9'},
            { nombre: 'Comp. Esc N°10', llave: 'comp_esc_n10'},
            { nombre: 'Comp. Esc N°11', llave: 'comp_esc_n11'},
            { nombre: 'Comp. Esc N°12', llave: 'comp_esc_n12'},
            { nombre: 'RAL a piscina ILS', llave: 'ral_piscina_ils'},
            { nombre: 'Refino rico', llave: 'refino_rico'},
        ];
        filas = [...filas, ...generarFilas( datos_18, labels, '18:00' )];
        
        // Grupo 22:00
        labels = [
            { nombre: 'Refino', llave: 'refino' }, 
            { nombre: 'PLS Pila este', llave: 'pls_pila_este'},
            { nombre: 'PLS Pila oeste', llave: 'pls_pila_oeste'},
            { nombre: 'PLS OXE', llave: 'pls_oxe' },
            { nombre: 'PLS Hidro', llave: 'pls_hidro' },
            { nombre: 'Perc Lix', llave: 'perc_lix' },
            { nombre: 'TK 101', llave: 'tk_101' },
            { nombre: 'PLS Ripio mirador', llave: 'pls_ripio_mirador' }    
        ];
        filas = [...filas, ...generarFilas( datos_22, labels, '22:00' )];

        // Grupo 00:00
        labels = [
            { nombre: 'PLS OXE', llave: 'pls_oxe' },    
        ];
        filas = [...filas, ...generarFilas( datos_00, labels, '00:00' )];

        // Grupo 04:00
        labels = [
            { nombre: 'Refino', llave: 'refino' }, 
            { nombre: 'PLS OXE', llave: 'pls_oxe' },
            { nombre: 'PLS Hidro', llave: 'pls_hidro' },
            { nombre: 'Perc Lix', llave: 'perc_lix' },
            { nombre: 'Composito PLS OXE', llave: 'composito_pls_oxe' },
        ];
        filas = [...filas, ...generarFilas( datos_04, labels, '04:00' )];

        // Grupo 06:00
        labels = [
            { nombre: 'Alime. Pila ROM', llave: 'alime_pila_rom' }, 
            { nombre: 'Percolado', llave: 'percolado'},
            { nombre: 'ILS ROM', llave: 'ils_rom'},
            { nombre: 'ILS Dinámico', llave: 'ils_dinamico'},
            { nombre: 'TK 101', llave: 'tk_101'},
            { nombre: 'RAL a piscina ILS', llave: 'ral_piscina_ils'},
            { nombre: 'Refino rico', llave: 'refino_rico'},
        ];
        filas = [...filas, ...generarFilas( datos_06, labels, '06:00' )];

        // Grupo 07:00
        labels = [
            { nombre: 'PLS Pila este', llave: 'pls_pila_este' }, 
            { nombre: 'PLS Pila oeste', llave: 'pls_pila_oeste' }
        ];
        filas = [...filas, ...generarFilas( datos_07, labels, '07:00' )];
        return filas;
    }

    const generarFilas = (datos, labels, hora) => {
        let cols = ['Cu', 'H2SO4', 'pH', 'FeT', 'Fe', 'Eh', 'TSS'];
        let filas = []; 

        for( let  i = 0; i < labels.length; i++ ) {
            let fila = [];

            if( i == 0 ) {
                fila = [
                    { clases: 'cel-hora', valor: hora, rows: labels.length },
                    { clases: 'cel-id', valor: labels[i].nombre, rows: 1 }
                ];

            } else {
                fila = [ { clases: 'cel-id', valor: labels[i].nombre, rows: 1 } ];
            }
            
            cols.forEach( col => {
                const valor = datos[ labels[i].llave ][ col ];
                

                fila = [...fila, {
                    clases: valor == '-' ? 'celda-valor oscurecer' : 'celda-valor',
                    valor: valor == '-' ? '' : valor,
                    rows: 1
                }];

            });

            filas = [...filas, fila];
        }

        return filas;
    }

    const descargarAnalisisQuimico = ( datos ) => {
        // console.log(datos);
        // Generar hojas
        const hoja = generarHoja( datos );
        const archivo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(archivo, hoja, `Análisis químico`);
        XLSX.writeFile(archivo, `Análisis_químico_${ moment().format('DD-MM-YYYY') }.xlsx`, {  editable: true });
    }

    const generarHoja = (datos) => {
        // Extraer filas
        const { filas:contenido } = datos;

        // Estilos
        const cabecera_gris = {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: '607d8b'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const cabecera_azul = {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: '01579b'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const hora_claro = {
            font: {
                sz: 11, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'adc9df'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        // Generar hoja, se agrega fila vacía
        let fila = [];
        let hoja =  XLSX.utils.aoa_to_sheet([fila]);
        let origen = 1;

        // CABECERA DE TABLA
        fila = [
            {  v: '', t: 's' }, // margen
            {  v: '', t: 's', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
            {  v: 'ANÁLISIS', t: '', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
            {  v: '', t: 's', s: cabecera_gris },
        ]

        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            {  v: '', t: '' }, // margen
            {  v: 'HORARIO', t: '', s: cabecera_azul },
            {  v: 'IDENTIFICACIÓN', t: 's', s: cabecera_azul },
            {  v: 'Cu++ g/L', t: 's', s: cabecera_azul },
            {  v: 'H2SO4 g/L', t: 's', s: cabecera_azul },
            {  v: 'pH', t: 's', s: cabecera_azul },
            {  v: 'FeT g/L', t: 's', s: cabecera_azul },
            {  v: 'Fe++ ppm', t: 's', s: cabecera_azul },
            {  v: 'Eh mV', t: 's', s: cabecera_azul },
            {  v: 'TSS ppm', t: 's', s: cabecera_azul },
        ]

        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // CUERPO DE TABLA
        let rows = [{ 'hpt': 18 }];
        for( let i = 0; i < contenido.length; i++ ) {
            rows = [...rows, { 'hpt': 18 }]
            const celdas = contenido[i];
            
            // Corresponde a una fila con hora
            if( celdas.length == 9 ) {
                fila = [{}];

                let cont = 0;
                celdas.forEach(({ valor }) => {
                    fila = [...fila, {
                        v: valor,
                        t: 's',
                        s: obtenerColor(cont, i, true)
                    }];

                    cont += 1;
                });
            } else {
                fila = [ {}, {  v: '', t: '', s: hora_claro } ];

                let cont = 0;
                celdas.forEach(({ valor }) => {
                    fila = [...fila, {
                        v: valor,
                        t: 's',
                        s: obtenerColor(cont, i)
                    }];

                    cont += 1;
                });
            }

            XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
            origen += 1;
        }

        let merges = [
            { s: { r: 1, c: 3 }, e: { r: 1, c: 9 } },
            { s: { r: 3, c: 1 }, e: { r: 10, c: 1 } },
            { s: { r: 12, c: 1 }, e: { r: 18, c: 1 } },
            { s: { r: 19, c: 1 }, e: { r: 37, c: 1 } },
            { s: { r: 38, c: 1 }, e: { r: 45, c: 1 } },
            { s: { r: 47, c: 1 }, e: { r: 51, c: 1 } },
            { s: { r: 52, c: 1 }, e: { r: 58, c: 1 } },
            { s: { r: 59, c: 1 }, e: { r: 60, c: 1 } }
        ];
        let cols = [{ wch: 3 }, { wch: 13 }, { wch: 25 }];
        hoja['!rows'] = rows;
        hoja['!cols'] = cols;
        hoja['!merges'] = merges;

        return hoja
    }

    const obtenerColor = ( pos_celda, pos_fila, nueva ) => {
        const hora_claro = {
            font: {
                sz: 11, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'adc9df'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const identificacion_claro = {
            font: {
                sz: 11, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'd2e2ed'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const hora_oscuro = {
            font: {
                sz: 11, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: '93b8d5'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const identificacion_oscuro = {
            font: {
                sz: 11, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'bed4e6'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const gris_claro = {
            font: {
                sz: 11, // tamaño
                bold: false, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'f2f2f2'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };
        const gris_oscuro = {
            font: {
                sz: 11, // tamaño
                bold: false, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'ececec'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        let oscuro = false;
        if ( pos_fila == 8 || pos_fila == 43 || ( pos_fila >= 16 && pos_fila <= 34 ) || ( pos_fila >= 49 && pos_fila <= 55 ) ) {
            oscuro = true;
        } 

        switch( true ) {
            case pos_celda == 0  && nueva == true:
                return oscuro ? hora_oscuro : hora_claro;

                
            case pos_celda == 0  && nueva != true:
                return oscuro ? identificacion_oscuro : identificacion_claro;

            case pos_celda == 1 && nueva == true:
                return oscuro ? identificacion_oscuro : identificacion_claro;

            default:
                return oscuro ? gris_oscuro : gris_claro;
        }
    }
    return {
        obtenerAnalisisQuimicoAPI,
        obtenerAnalisisQuimicoFechaAPI,
        descargarAnalisisQuimico
    }
}