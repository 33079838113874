import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import './styles/puente.scss'

import { useSesion } from '../../hooks/useSesion';

const digitalizacion = require('../../../assets/img/puente/01.png');
const smartpls = require('../../../assets/img/puente/02.png');

export const PuentePage = () => {
    const { token } = useParams();

    const { validarToken, limpiarStore } = useSesion();
    const [Validacion, setValidacion] = useState(false);

    useEffect(() => {
        if(Validacion === false) {  
            setValidacion(true);
            limpiarStore();
            validarToken(token);
        }
    }, [])
    

    return (
        <div className="contenedor-tarjeta">
            <div className="tarjeta">
                <div className="cargando">
                    <img src={ smartpls } alt="" />
                    <img src={ digitalizacion } alt="" />
                    <img src={ smartpls } alt="" />
                    <img src={ digitalizacion } alt="" />
                </div>

                <p id="texto-puente">Trasladando a SmartPLS Reportabilidad...</p>
            </div>
        </div>
    )
}
