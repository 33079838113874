import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import ReactApexChart from 'react-apexcharts';
import { useResumenModulos } from "../../../hooks/useResumenModulos";

export const Grafico = (props) => {
  // Variables de estado
  const { contarEstados } = useResumenModulos();
  const [Ejecutado, setEjecutado] = useState(false);
  const [Series, setSeries] = useState([{data: []}]);
  const [Opciones, setOpciones] = useState({
    options: {
      legend: {
        show: false
      },
      chart: {
        height: 250,
        type: 'treemap'
      }
    }
  })

  let { planta:param_planta } = useParams();

  // Obtener datos
  useEffect(() => {
    if(param_planta == 'met' || props.planta == 'met') {
      if((props.datos.estado == true && Ejecutado == false)) {
        setEjecutado(true);
        const { lista_contabilizada, lista_estados, colores } = contarEstados('met', props.datos);


        setSeries([{ data: lista_contabilizada }]);
        setOpciones({
          chart: {
              type: 'treemap',
              height: props.alto
          },
          dataLabels: {
              enabled: true,
              enabledOnSeries: [0],
              style: {
                colors: ["#1b1d1f"]
              }
          },
          plotOptions: {
                treemap: {
                  distributed: true,
                  enableShades: false
                }
              },
          labels: lista_estados,
          colors: colores
        });
      }
    } 
    else if(param_planta == 'oxe' || props.planta == 'oxe') {
      if((props.datos.estado == true && Ejecutado == false)) {
        setEjecutado(true);
        const { lista_contabilizada, lista_estados, colores } = contarEstados('oxe', props.datos.franjas);
        setSeries([{ data: lista_contabilizada }]);
        setOpciones({
          chart: {
              type: 'treemap',
              height: props.alto
          },
          dataLabels: {
              enabled: true,
              enabledOnSeries: [0],
              style: {
                colors: ["#020202"]
              }
          },
          plotOptions: {
                treemap: {
                  distributed: true,
                  enableShades: false
                }
              },
          labels: lista_estados,
          colors: colores
        })
      }
    }
  }, [props.datos]);  


  return (
    <div className={ `cont-grafico ${ param_planta }` }>
      <ReactApexChart 
          options={ Opciones }
          series={ Series }
          type='treemap'
          height={ props.alto }
        />
    </div>
  )
}
