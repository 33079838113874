import { Routes, Route, Navigate } from "react-router-dom"
import { AutentificarPage } from "../AutentificarPage"


// Rutas exclusivas login
export const AutentificacionRoutes = () => {
  return (
    <Routes>
        <Route path="/autentificar" element={ <AutentificarPage /> } />
        <Route path="/*" element={ <Navigate to="/autentificar" replace/> } />
    </Routes>
  )
}
