// Redux
import { configureStore } from '@reduxjs/toolkit';

// Redux persist
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import { autentificacion, ControlRiego, Rlix, RampaHumectacion,
ResumenModulosMET, ResumenModulosOXE, SimSX, AnalisisQuimico, ReportePreliminar } from './PersistReducers';

export const store = configureStore({
    reducer: {
        autentificacion,
        ControlRiego,
        RampaHumectacion,
        Rlix,
        AnalisisQuimico,
        SimSX,
        ReportePreliminar,

        

        ResumenModulosOXE,
        ResumenModulosMET,

        
        
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);
// export type RootState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch