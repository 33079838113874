import { Tabla } from "./Tabla"

export const Detalle = (props) => {
  return (
    <div id="tab-detalle-rlix" className="tab-rlix activo" style={{ 'overflowY': 'hidden' } }>
      <Tabla cabecera={ props.cabecera } modulos={ props.modulos } planta={ props.planta } />
      <br />
    </div>
  )
}
