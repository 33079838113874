

import ReactApexChart from 'react-apexcharts';

export const Graficos = ( props ) => {


  return (
    <div id="tab-graficos-rom" className="col s12 cont-tab scroll">
      <ReactApexChart 
        id="grafico-refino-rom"
        height={ 300 }
        type="area"
        options={{
          chart: {
            type: 'area',
            height: 300
          },
          colors: ['#5da95c'],
          dataLabels: {
              enabled: true,
              enabledOnSeries: [0]
          },
          labels: props.datos_ref.labels,
          title: {
            text: 'Refino a ROM'
          },
          stroke: {
            curve: 'smooth'
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
        }}

        series= {[{
          data: props.datos_ref.data,
          name: 'Refino'
        }]}
      />
      
      <br />

      <ReactApexChart 
        id="grafico-produccion-rom"
        height={ 300 }
        type="area"
        options={{
          chart: {
            type: 'area',
            height: 300
          },
          colors: ['#01579b'],
          dataLabels: {
              enabled: true,
              enabledOnSeries: [0]
          },
          labels: props.datos_prod.labels,
          title: {
            text: 'Producción ROM'
          },
          stroke: {
            curve: 'smooth'
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
        }}
        series= {
          [{
            data: props.datos_prod.data,
            name: 'Producción'
          }]
        }
      />
    </div>
  )
}
