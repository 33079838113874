import { useEffect, useState } from "react";
import { Tabla } from "./Tabla";

export const Pestania = (props) => {
  const [Filas, setFilas] = useState([]);

  useEffect(() => {
    setFilas(props.filas);
  }, [props.filas])

  return (
    <div id={ props.id_tab } className={ props.clases }>
      <div className="cont-tabla scroll">
        <Tabla 
          planta={ props.planta } 
          filas={ Filas } 
          modulos={ props.modulos } 
          clases={ `tabla-control-riego striped tabla-${props.turno} ` }
        />
      </div>
    </div>
  )
}
