// React
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

// Store
import { limpiarResumenModulosMET } from '../../../store/digitalizacion/ResumenModulosMETSlice';
import { limpiarResumenModulosOXE } from '../../../store/digitalizacion/ResumenModulosOXESlice';

// Hooks
import { useResumenModulos } from '../../hooks/useResumenModulos';

// Componentes
import { FilaSuperior } from './components/FilaSuperior';
import { ResumenMET } from './components/ResumenMET';
import { ResumenOXE } from './components/ResumenOXE';
import { Loader } from '../../components/Loader';

// Estilos
import './styles/resumen.scss';
import './styles/tablas.scss';
// import './styles/resumen_responsivo.scss';
// import './styles/ResumenModulos.scss';
// import './styles/ResumenModulosMET.scss';

export const ResumenModulosPage = () => {
  // Store
  const resumenMET = useSelector((state) => state.ResumenModulosMET.resumen);
  const resumenOXE = useSelector((state) => state.ResumenModulosOXE.resumen);
  const dispatch = useDispatch();

  // Parámetros
  const { planta } = useParams();
  const { obtenerResumenAPI } = useResumenModulos();

  // Variables de estado
  const [Interfaz, setInterfaz] = useState(false)
  const [DatosMET, setDatosMET] = useState({ estado: false });
  const [DatosOXE, setDatosOXE] = useState({ estado: false });

  useEffect(() => {
    if( planta === 'met' ) {
      if( Object.keys(resumenMET).length > 0) {
        dispatch( limpiarResumenModulosMET() );
        obtenerDatos();
  
      } else {
        obtenerDatos();
      }

    } else {
      if( Object.keys(resumenOXE).length > 0) {
        dispatch( limpiarResumenModulosOXE() );
        obtenerDatos();
      } else {
        obtenerDatos();
      }
    }
    
  }, [])

  const obtenerDatos = async() => {
    // Obtener datos desde API
    const datos_met = await obtenerResumenAPI('met');
    const datos_oxe = await obtenerResumenAPI('oxe');
    
    setDatosMET({
      estado: true,
      pila_este: datos_met.pila_este,
      pila_oeste: datos_met.pila_oeste
    });

    setDatosOXE({
      estado: true,
      franjas: datos_oxe
    });

    setInterfaz(true)
  }

  return (
    Interfaz === false 
    ? 
      <div className="contenido">
        <div className="cont-loader">
            <Loader mensaje="" />
        </div>
      </div>
    :
      <div className="contenido">
        <div className="fila-superior">
          <FilaSuperior planta={ planta } />
        </div>

        <br />
        
        <div className="contenedor-datos-resumen scroll">
          {
            planta == 'met'
            ? <ResumenMET datos={ DatosMET } />
            : <ResumenOXE datos={ DatosOXE }/>
          }
        </div>
      </div>
  )
}
