import { useEffect, useState } from "react";

export const Tabla = ( props ) => {
    const [FilasVista, setFilasVista] = useState([]);

    useEffect(() => {
        setFilasVista( props.filas );
      }, [props.filas])
      

    const cambiarValorInput = ( event, fila, celda, tabla ) => {
        let nuevos_datos = props.rom;
        const valor_input = validarNumero( event.target.value );

        if( valor_input === '' ) {
            nuevos_datos[tabla][fila][celda].error = true;
            nuevos_datos[tabla][fila][celda].valor = valor_input;
            nuevos_datos[tabla][fila][celda].disabled = false;
            props.setROM( { ...nuevos_datos } );
        } else {
            nuevos_datos[tabla][fila][celda].error = false;

            if( nuevos_datos[tabla][fila][celda].valor === nuevos_datos[tabla][fila][celda].original ) {
                props.setCambio( props.cambio + 1 );
                nuevos_datos[tabla][fila][celda].valor = valor_input;
                nuevos_datos[tabla][fila][celda].disabled = false;
                props.setROM( { ...nuevos_datos } );
            } else {
                nuevos_datos[tabla][fila][celda].valor = valor_input;
                nuevos_datos[tabla][fila][celda].disabled = false;
                props.setROM( { ...nuevos_datos } );
            }

        }
        
    }

    const reestablecerValor = ( event, fila, celda, tabla ) => {
        let nuevos_datos = props.rom;
        nuevos_datos[tabla][fila][celda].clases_error = '';
        nuevos_datos[tabla][fila][celda].valor = nuevos_datos[tabla][fila][celda].original;
        nuevos_datos[tabla][fila][celda].disabled = true;
        props.setROM( { ...nuevos_datos } );
        props.setCambio( props.cambio - 1 );
    }

    const validarNumero = ( valor ) => {
        let nuevo = "";
        let decimal = false;

        for(let i = 0; i < valor.length; i++) {
            switch(true) {
                case valor[i] == "." || valor[i] == ",":
                    if(decimal == false) {
                        decimal = true;
                        nuevo += "."
                    }
                    break;
                
                case valor != "-" && !isNaN(valor[i]) && valor[i] != '.' :
                    nuevo += valor[i];
                    break;
            }
        }

        return nuevo;

    }

    return (
        props.interfaz == true
        ?
            props.tipo == 'turno'
            ?
                // Turno
                <div id="tab-tabla-rom" className="col s12 cont-tab scroll">
                    <div className="cont-tabla-rom scroll">
                        <table className={ props.clases + ' tabla-rom' }>
                            <thead>
                                <tr>
                                    <th colSpan={ 1 } className="dif"></th>
                                    <th colSpan={ 3 } className="dif">Refino a ROM</th>
                                    <th colSpan={ 3 }>ILS pilas dinámicas</th>
                                    <th colSpan={ 4 } className="dif">ILS por parshall a canaletas</th>
                                    <th colSpan={ 4 }>ILS bypass a piscinas PLS</th>
                                    {/* <th colSpan={ 2 } className="dif">Producción ROM</th> */}
                                </tr>

                                <tr>
                                    <th width="100" className="th-fecha claro">FECHA</th>
                                    {/* refino a rom */}
                                    <th width="100" className="claro">Hrs operacion</th>
                                    <th width="70" className="claro">m3/turno</th>
                                    <th width="70" className="claro">[Cu] gpl</th>

                                    {/* ils pilas dinámicas */}
                                    <th width="100" >Hrs operacion</th>
                                    <th width="70" >m3/turno</th>
                                    <th width="70" >[Cu] gpl</th>

                                    {/* ils por parshal a canaletas */}
                                    <th width="100" className="claro">Hrs operacion</th>
                                    <th width="70" className="claro">m3/hora</th>
                                    <th width="70" className="claro">m3/turno</th>
                                    <th width="70" className="claro">[Cu] gpl</th>

                                    {/* ils bypass a piscinas pls */}
                                    <th width="100">Hrs operacion</th>
                                    <th width="70">m3/hora</th>
                                    <th width="70">m3/turno</th>
                                    <th width="70">[Cu] gpl</th>

                                    {/* produccion rom */}
                                    {/* <th className="claro">Ton Cu/día</th>
                                    <th className="claro">Ton Cu acumulado</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    FilasVista.map((celdas, index)=>(
                                        <tr key={ index }>
                                            {
                                                celdas.map( ( celda, subindex ) => (
                                                    // (subindex == 7 || subindex == 8 || subindex == 10 || subindex == 11) 
                                                    // && index == props.filas.length-1 && props.clases != "tabla-rom tabla-general"
                                                    celda.input == true  && index == props.filas.length-1 && props.clases != "tabla-rom tabla-general"
                                                    ?
                                                        <td key={ subindex } className={ celda.clases } >

                                                        <div className="cont-input">
                                                                <input value={ celda.valor } className={ celda.error ? 'input-cosecha-error' : null } onChange={ (e) => { cambiarValorInput( e, index, subindex, props.tabla ) } }/>

                                                                <button 
                                                                    onClick={ (e) => { reestablecerValor(e, index, subindex, props.tabla ) } } 
                                                                    id={ `btn-reestablecer-${ index }${subindex}` } className='btn blue-grey waves-effect waves-light' 
                                                                    disabled={ celda.disabled }
                                                                >
                                                                    <i className="material-icons left">replay</i>
                                                                    
                                                                </button>
                                                            </div>
                                                        </td>

                                                    :   <td key={ subindex } className={ celda.clases } >{ celda.valor }</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            : // General
                <div id="tab-tabla-rom" className="col s12 cont-tab scroll">
                    <div className="cont-tabla-rom scroll">
                        <table className={ props.clases + ' tabla-rom' }>
                            <thead>
                                <tr>
                                    <th colSpan={ 1 } className="dif"></th>
                                    <th colSpan={ 3 } className="dif">Refino a ROM</th>
                                    <th colSpan={ 3 }>ILS pilas dinámicas</th>
                                    <th colSpan={ 4 } className="dif">ILS por parshall a canaletas</th>
                                    <th colSpan={ 4 }>ILS bypass a piscinas PLS</th>
                                    <th colSpan={ 2 } className="dif">Producción ROM</th>
                                </tr>

                                <tr>
                                    <th width="50" className="th-fecha claro">FECHA</th>
                                    {/* refino a rom */}
                                    <th width="100" className="claro">Hrs operacion</th>
                                    <th width="70" className="claro">m3/día</th>
                                    <th width="70" className="claro">TON/DÍA</th>

                                    {/* ils pilas dinámicas */}
                                    <th width="100" >Hrs operacion</th>
                                    <th width="80" >m3/día</th>
                                    <th width="70" >TON/DÍA</th>

                                    {/* ils por parshal a canaletas */}
                                    <th width="100" className="claro">Hrs operacion</th>
                                    <th width="70" className="claro">m3/hora</th>
                                    <th width="80" className="claro">m3/día</th>
                                    <th width="70" className="claro">TON/DÍA</th>

                                    {/* ils bypass a piscinas pls */}
                                    <th width="100">Hrs operacion</th>
                                    <th width="70">m3/hora</th>
                                    <th width="80">m3/día</th>
                                    <th width="70">TON/DÍA</th>

                                    {/* produccion rom */}
                                    <th width="100" className="claro">Ton Cu/día</th>
                                    <th width="100" className="claro">Ton Cu acumulado</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    FilasVista.map((celdas, index)=>(
                                        <tr key={ index }>
                                            {
                                                celdas.map( ( celda, subindex ) => (
                                                    // (subindex == 7 || subindex == 8 || subindex == 10 || subindex == 11) 
                                                    // && index == props.filas.length-1 && props.clases != "tabla-rom tabla-general"
                                                    celda.input == true  && index == props.filas.length-1 && props.clases != "tabla-rom tabla-general"
                                                    ?
                                                        <td key={ subindex } className={ celda.clases } >

                                                        <div className="cont-input">
                                                                <input value={ celda.valor } className={ celda.error ? 'input-cosecha-error' : null } onChange={ (e) => { cambiarValorInput( e, index, subindex, props.tabla ) } }/>

                                                                <button 
                                                                    onClick={ (e) => { reestablecerValor(e, index, subindex, props.tabla ) } } 
                                                                    id={ `btn-reestablecer-${ index }${subindex}` } className='btn blue-grey waves-effect waves-light' 
                                                                    disabled={ celda.disabled }
                                                                >
                                                                    <i className="material-icons left">replay</i>
                                                                    
                                                                </button>
                                                            </div>
                                                        </td>

                                                    :   <td key={ subindex } className={ celda.clases } >{ celda.valor }</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
        : null
    )
}
