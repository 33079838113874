// React
import { useEffect, useState } from 'react';
import { useCosecha } from '../../hooks/useCosecha';

// Materialize
import { FilaSuperior } from './components/FilaSuperior';
import { Loader } from '../../components/Loader';
import { MsjError } from '../../components/MsjError';

import { Produccion } from './components/Produccion';
import { SX } from './components/SX';
import { EW_SX } from './components/EW_SX';
import { Lixiviacion } from './components/Lixiviacion';
import { ModalCarga } from '../../components/ModalCarga';

import { useAuthStore } from '../../../autentificacion/hooks/useAutentificacion';

import './styles/btns_pestanias.scss';
import './styles/tablas.scss';
import './styles/Cosecha_responsive.scss';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

export const CosechaPage = () => {
    const [CambiosInput, setCambiosInput] = useState(0);
    const { obtenerCosechaProduccionAPI, obtenerSXAPI, obtenerLixAPI, obtenerEWSXAPI } = useCosecha();
    const [Interfaz, setInterfaz] = useState(false)
    const [DatosProduccion, setDatosProduccion] = useState([]);
    const [DatosSX, setDatosSX] = useState();
    const [DatosLix, setDatosLix] = useState();
    const [DatosEWSX, setDatosEWSX] = useState();
    
    useEffect(() => {
        if( !Interfaz ) {
            obtenerDatos();
        }
    }, [])
    
    const obtenerDatos = async() => {
        try {
            const produccion = await obtenerCosechaProduccionAPI();
            setDatosProduccion(produccion);
    
            const sx = await obtenerSXAPI();
            setDatosSX( sx );
    
            const lix = await obtenerLixAPI();
            setDatosLix(lix);
            
            const ewsx = await obtenerEWSXAPI();
            setDatosEWSX(ewsx);
            setInterfaz( true );
            
            M.toast({html: 'Los datos de producción mensual tienen un día menos debido a una situación con las señales', displayLength: 15000, classes: 'error' })
        } catch( err ) {
            // console.log(err);
            setInterfaz( null );
        }
    }

    const cambiarVistaCosecha = (btn_nuevo, cont_nuevo) => {
        const btns = ['btn-cosecha-produccion', 'btn-cosecha-sx', 'btn-cosecha-ewsx', 'btn-cosecha-lixiviacion'];
        const contenedores = ['tab-produccion-cosecha', 'tab-sx-cosecha', 'tab-ewsx-cosecha', 'tab-lixiviacion-cosecha'];

        btns.forEach(btn => {
            if( btn != btn_nuevo ) {
                document.getElementById(btn).classList.remove("activo");
            }
        });

        contenedores.forEach(cont => {
            if( cont != cont_nuevo ) {
                document.getElementById(cont).classList.remove("activo");
            }
        });

        document.getElementById(btn_nuevo).classList.add("activo");
        document.getElementById(cont_nuevo).classList.add("activo");
    }

    useEffect(() => {
      if( Interfaz ) {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems);
        // var instances = M.Modal.init(elems, {
        //     dismissible: false,
        // });
      }
    }, [Interfaz])
    

    return (
        Interfaz == false
        ?    
            <div className="contenido">
                <div className="cont-loader">
                    <Loader mensaje=""/>
                </div>
            </div>
        :
            Interfaz == true
            ?
            <div className="contenido">
                <ModalCarga />
                <div className="contenedor">
                    <FilaSuperior 
                        cambios={ CambiosInput } 
                        produccion={ DatosProduccion } 
                        sx={ DatosSX } 
                        ewsx={ DatosEWSX } 
                        lixiviacion={ DatosLix } 
                        interfaz={ Interfaz }
                    />
                </div>

                <div className='contenedor'>
                    {/* titulo de página - fila superior */}
                    <div className="cont-btns-cosecha">
                        <button 
                            id="btn-cosecha-produccion"
                            className='btn waves-effect waves-light activo' 
                            onClick={ () => { cambiarVistaCosecha('btn-cosecha-produccion', 'tab-produccion-cosecha') }}
                        >
                            Producción
                        </button>

                        <button 
                            id="btn-cosecha-sx"
                            className='btn waves-effect waves-light' 
                            onClick={ () => { cambiarVistaCosecha('btn-cosecha-sx', 'tab-sx-cosecha') }}
                        >
                            SX
                        </button>

                        <button 
                            id="btn-cosecha-ewsx"
                            className='btn waves-effect waves-light' 
                            onClick={ () => { cambiarVistaCosecha('btn-cosecha-ewsx', 'tab-ewsx-cosecha') }}
                        >
                            EW SX
                        </button>

                        <button 
                            id="btn-cosecha-lixiviacion"
                            className='btn waves-effect waves-light' 
                            onClick={ () => { cambiarVistaCosecha('btn-cosecha-lixiviacion', 'tab-lixiviacion-cosecha') }}
                        >
                            Lixiviación
                        </button>

                    </div>

                    <Produccion cambios={ CambiosInput } setCambios={ setCambiosInput } datos={ DatosProduccion } interfaz={ Interfaz } />
                    <SX cambios={ CambiosInput } setCambios={ setCambiosInput } TablasSX={ DatosSX } interfaz={ Interfaz } setDatos={ setDatosSX }/>
                    <EW_SX cambios={ CambiosInput } setCambios={ setCambiosInput } datos={ DatosEWSX } interfaz= { Interfaz } setDatos={ setDatosEWSX }/>
                    <Lixiviacion cambios={ CambiosInput } setCambios={ setCambiosInput } datos={ DatosLix } interfaz={ Interfaz } setDatos={ setDatosLix }/>
                </div>
            </div>

            :
            <MsjError />
    )
}
