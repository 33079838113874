import { createSlice } from '@reduxjs/toolkit';

const initialState = { contenido: {} };


const ControlRiegoSlice = createSlice({
    name: 'ControlRiego',
    initialState,
    reducers: {
        setControlRiego: (state, { payload }) => {
            state.contenido = payload
        },

        limpiarControlRiego: (state) => {
            state.contenido = []
        }
    }
});

export const { setControlRiego, limpiarControlRiego } = ControlRiegoSlice.actions;
export default ControlRiegoSlice.reducer;