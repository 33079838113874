// React
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Materialize
import M from 'materialize-css';

// Componentes
import { FilaSuperior } from './components/FilaSuperior';
import { Pestania } from './components/Pestania';
import { Loader } from '../../components/Loader';
import { MsjError } from '../../components/MsjError';

// Hooks
import useControlRiego from '../../hooks/useControlRiego';
import { useSesion } from '../../hooks/useSesion';

// Estilos
import './styles/controlRiego.scss';
import './styles/controlRiego_responsivo.scss';



export const ControlRiegoPage = () => {
  const datos = useSelector((state) => state.ControlRiego.contenido);

  // Obtener método para obtención de datos
  const { obtenerControlRiegoAPI } = useControlRiego();
  const { limpiarStore } = useSesion();

  // Obtener planta desde párametro URL
  const { planta } = useParams();

  // Variables de estado
  const [Ejecutado, setEjecutado] = useState(false);
  const [DatosControlRiego, setDatosControlRiego] = useState({})
  const [Meses, setMeses] = useState([])
  const [FilasGeneral, setFilasGeneral] = useState([]);
  const [FilasA, setFilasA] = useState([]);
  const [FilasB, setFilasB] = useState([]);

  useEffect(() => {
    limpiarStore();
    if(!Ejecutado && Object.keys(datos).length > 0) {
      
    } else {
      cargarDataRiego();
    }
  }, []);

  const cargarDataRiego = async() => {
    const controlriego = await obtenerControlRiegoAPI( planta );

    if( controlriego == false ) {
      setEjecutado( null )
    } else {
      setDatosControlRiego(controlriego);
      let lista = [];
      for(let i = 0; i < controlriego.meses.length; i++) {
        lista = [...lista, {
          valor: i,
          label: controlriego.meses[i]
        }]
      }

      setMeses(lista);  
      setFilasGeneral( controlriego.grupos[controlriego.grupos.length-1].filas_general );
      setFilasA( controlriego.grupos[controlriego.grupos.length-1].filas_turno_a );
      setFilasB( controlriego.grupos[controlriego.grupos.length-1].filas_turno_b );
      setEjecutado(true);
    }
  }

  const cambiarTablaControlRiego = (planta_vista, contenedor, btn) => {
    const contenedores = planta_vista  == 'met'
      ? ['tab-general-met', 'tab-A-met', 'tab-B-met']
      : ['tab-general-oxe', 'tab-A-oxe', 'tab-B-oxe'];

    const btns = ['btn-cr-resumen', 'btn-cr-a', 'btn-cr-b'];

    contenedores.forEach(id => {
      if( id == contenedor) {
        document.getElementById(id).classList.add('activo');
      } else {
        document.getElementById(id).classList.remove('activo');
      }
    });

    btns.forEach(id => {
      if( id == btn) {
        document.getElementById(id).classList.add('activo');
      } else {
        document.getElementById(id).classList.remove('activo');
      }
    });
  }

  return (
    <div className="contenido">
      {
          {
              false: <Loader />,

              null: <MsjError />,

              true:
                <>
                  {/* titulo de página - fila superior */}
                    <FilaSuperior 
                      planta={ planta } 
                      meses={ Meses } 
                      datos={ DatosControlRiego } 
                      setFilasGeneral={ setFilasGeneral }
                      setFilasA={ setFilasA } 
                      setFilasB={ setFilasB } 
                    />
                    <br />

          
                  <div className='contenedor cr'>
                    {/* Tabs */}
                    <div className="cont-btns-tabs-riego">
                      {
                        planta === 'met'
                        ?
                          <>
                            <button id="btn-cr-resumen" className="btn waves-effect waves-light activo" onClick={ () => { cambiarTablaControlRiego(planta, 'tab-general-met', 'btn-cr-resumen') } }>Resumen</button>
                            <button id="btn-cr-a" className="btn waves-effect waves-light " onClick={ () => { cambiarTablaControlRiego(planta, 'tab-A-met', 'btn-cr-a') } }>Turno A</button>
                            <button id="btn-cr-b" className="btn waves-effect waves-light " onClick={ () => { cambiarTablaControlRiego(planta, 'tab-B-met', 'btn-cr-b') } }>Turno B</button>
                          </>
                        :
                          <>
                            <button id="btn-cr-resumen" className="btn waves-effect waves-light activo" onClick={ () => { cambiarTablaControlRiego(planta, 'tab-general-oxe', 'btn-cr-resumen') } }>Resumen</button>
                            <button id="btn-cr-a" className="btn waves-effect waves-light" onClick={ () => { cambiarTablaControlRiego(planta, 'tab-A-oxe', 'btn-cr-a') } }>Turno A</button>
                            <button id="btn-cr-b" className="btn waves-effect waves-light" onClick={ () => { cambiarTablaControlRiego(planta, 'tab-B-oxe', 'btn-cr-b') } }>Turno B</button>
                          </>
                      }
                    </div>
            
                    {/* Contenido tabs */}
                    {
                      planta === 'met'
                      ?
                        <>
                          <Pestania 
                            planta={ planta } 
                            id_tab="tab-general-met" 
                            turno="general"
                            datos={ DatosControlRiego }
                            filas={ FilasGeneral }
                            meses={ Meses }
                            modulos={ DatosControlRiego.modulos }
                            clases="tab-control-riego activo"
                          />
      
                          <Pestania 
                            planta={ planta } 
                            id_tab="tab-A-met" 
                            turno="A"
                            datos={ DatosControlRiego }
                            filas={ FilasA }
                            meses={ Meses }
                            modulos={ DatosControlRiego.modulos }
                            clases="tab-control-riego"
                          />
      
                          <Pestania 
                            planta={ planta } 
                            id_tab="tab-B-met" 
                            turno="B"
                            datos={ DatosControlRiego }
                            filas={ FilasB }
                            meses={ Meses }
                            modulos={ DatosControlRiego.modulos }
                            clases="tab-control-riego"
                          />
      
                        </>
                      :
                        <>
                          <Pestania 
                            planta={ planta } 
                            id_tab="tab-general-oxe" 
                            turno="general"
                            datos={ DatosControlRiego }
                            filas={ FilasGeneral }
                            meses={ Meses }
                            modulos={ DatosControlRiego.modulos }
                            clases="tab-control-riego activo"
                          />
      
                          <Pestania 
                            planta={ planta } 
                            id_tab="tab-A-oxe" 
                            turno="A"
                            datos={ DatosControlRiego }
                            filas={ FilasA }
                            meses={ Meses }
                            modulos={ DatosControlRiego.modulos }
                            clases="tab-control-riego"
                          />
      
                          <Pestania 
                            planta={ planta } 
                            id_tab="tab-B-oxe" 
                            turno="B"
                            datos={ DatosControlRiego }
                            filas={ FilasB }
                            meses={ Meses }
                            modulos={ DatosControlRiego.modulos }
                            clases="tab-control-riego"
                          />
      
                        </>
                    }
                  </div>
                </>

          }[ Ejecutado ]
      }
      

    </div>
  )
}


