import React, { useState } from 'react'

// Componentes
import { Grafico } from './Grafico';
import { ModalHistorial } from './ModalHistorial';
import { TablaEstados } from './TablaEstados';
import { TablaResumenOXE } from './TablaResumenOXE';

export const ResumenOXE = (props) => {
  const [ModuloModal, setModuloModal] = useState({});

  return (
    <>
      <div className='contenedor cont-estadistica oxe'>
        <ModalHistorial modulo={ ModuloModal }/>
        <Grafico datos={ props.datos } alto={ 250 } />
        <TablaEstados datos={ props.datos } /> 
      </div>

      <div className="contenedor">
        <div className='cont-tabla-resumen oxe scroll'>
          <TablaResumenOXE setModuloModal={ setModuloModal } datos={ props.datos } />
        </div>
      </div>

      <br />
    </>
  )
}
