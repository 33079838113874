import { useEffect, useState } from "react";
import { GraficoLinea } from "./GraficoLinea";

export const Rlix = ( props ) => {
    const [Datos, setDatos] = useState({
        'MET': {},
        'OXE': {}
    });

    useEffect(() => {
        if ( props.datos_rlix.datos === true ) {
            obtenerDatos();
        }
    }, [props.datos_rlix]);

    const obtenerDatos = () => {
        const { met, oxe } = props.datos_rlix;

        const data_met = DatosRlixGrafico(met, 'met');
        const data_oxe = DatosRlixGrafico(oxe, 'oxe');

        setDatos({
            nombre: 'RAZÓN DE LIXIVIACIÓN',
            MET: {
                ...data_met
            },
            OXE: {
                ...data_oxe
            }
        });
    }

    const DatosRlixGrafico = (modulos, planta) => {
        setDatos({
            nombre: 'RAZÓN DE LIXIVIACIÓN',
        });

        let lista_modulos = [];
        let data_grafico = [];

        modulos.forEach(modulo => {
            if(modulo.Nomenclatura != null) {
                // Guardar arrays
                lista_modulos = [...lista_modulos, modulo.Nomenclatura];
                data_grafico = [...data_grafico, {
                    x: modulo.Nomenclatura,
                    y: planta == 'met' ? modulo.RazonRiego_Efectiva : (modulo.Volumen_Total / modulo.TMS).toFixed(2),
                    goals: [{
                        name: 'Rlix esperado',
                        value: modulo.Rlix_Estimado,
                        // strokeWidth: 10,
                        strokeHeight: 4,
                        strokeDashArray: 30,
                        strokeColor: '#ffc33c'
                      },
                      {
                        name: 'Rlix objetivo',
                        value: planta == 'met' ? 1.7 : 1.3,
                        strokeWidth: 1000,
                        strokeHeight: 4,
                        strokeDashArray: 30,
                        strokeColor: '#a3d39c'
                    }]
                }];
            }
        });
        
        return {
            modulos: lista_modulos,
            data: data_grafico
        };
    }

    const cambiarGraficoRlix = (btn, id_btn, id_ocultar, id_mostrar) => {
        btn.classList.add('activo');
        document.getElementById(id_btn).classList.remove('activo');

        document.getElementById(id_ocultar).classList.remove('activo');
        document.getElementById(id_mostrar).classList.add('activo');
    }

    return (
        <>
            <div className="btns-resumen-reportes">
                <button 
                    className='waves-effect waves-light btn activo'
                    id="btn-rlix-MET"
                    onClick={e => { cambiarGraficoRlix(e.target, 'btn-rlix-OXE', 'cont-rlix-oxe', 'cont-rlix-met') }}
                >Planta MET</button>

                <button 
                    className='waves-effect waves-light btn'
                    id="btn-rlix-OXE"
                    onClick={e => { cambiarGraficoRlix(e.target, 'btn-rlix-MET', 'cont-rlix-met', 'cont-rlix-oxe') }}
                >Planta OXE</button>
            </div>

            <div className="cont-leyendas-graf">
                <div>
                    <div className="cubo real"></div>Rlix Real
                </div>
                <div>
                    <div className="cubo esperado"></div> Rlix esperado
                </div>
                <div>
                <div className="cubo obj"></div> Rlix objetivo
                </div>
            </div>

            <div className="cont-rlix activo chartWrapper scroll" id='cont-rlix-met'>
                <div className="chartAreaWrapper irl-rlix-met">
                    <GraficoLinea datos={ Datos } interfaz={ props.datos_rlix.datos } filtro='MET' />
                </div>
            </div>

            <div className="cont-rlix chartWrapper scroll" id='cont-rlix-oxe'>
                <div className="chartAreaWrapper irl-rlix-oxe">
                    <GraficoLinea datos={ Datos } interfaz={ props.datos_rlix.datos } filtro='OXE' />
                </div>
            </div>
        </>
    )
}
