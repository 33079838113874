import React, { useEffect, useState } from 'react'

import { TablaSimple } from './TablaSimple'
import { useCosecha } from '../../../hooks/useCosecha'

export const EW_SX = ( props ) => {

  // useEffect(() => {
  //   console.log(props);
  // }, [props])
  
  
  return (
    props.Interfaz === false
    ? null
    :
    <div id="tab-ewsx-cosecha" className="tab-cosecha">
      <div className="contenido-cosecha scroll">
        <div className="linea-cont-tabla">
        {/* Tabla turnos */}
          <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera={ props.datos.tabla_turnos.cabeceras } filas={ props.datos.tabla_turnos.filas } claseTabla='tabla-ewsx simple colores-turnos turnos-ewsx' id_tabla='tabla-ewsx-turnos'/>
        </div>


        <br />

        {/* Nivel TKS y Prod. planta  */}
        <div className="cont-dos-tablas filas linea-cont-tabla">
        <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera = {props.datos.tabla_lvl_tks.cabeceras} filas = {props.datos.tabla_lvl_tks.filas} claseTabla='tabla-fila' id_tabla='tabla-ewsx-tks'/>

        <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera = {props.datos.tabla_prod_planta.cabeceras} filas = {props.datos.tabla_prod_planta.filas} claseTabla='tabla-fila' id_tabla='tabla-ewsx-prod-planta'/>
        </div>

        <br />

        <div className="linea-cont-tabla">
        {/* Tabla eficiencia de extraccion */}
          <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera={ props.datos.tabla_eficiencia_extraccion.cabeceras } filas={ props.datos.tabla_eficiencia_extraccion.filas } claseTabla='tabla-ewsx eficiencia simple tabla-ewsx-ef-ext' id_tabla='tabla-ewsx-ef-ext' />

          <div className="cont-dos-tablas">
          {/* tablas de extractores */}
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera={ props.datos.tabla_extractores.cabeceras } filas={ props.datos.tabla_extractores.filas } claseTabla='tabla-ewsx simple extractores ' id_tabla='tabla-ewsx-ext' />
            {/* tablas de cte tehorico */}
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera={ props.datos.tabla_cte_teorica.cabeceras } filas={ props.datos.tabla_cte_teorica.filas } claseTabla='tabla-ewsx simple teorica ' id_tabla='tabla-ewsx-teorica' />
          </div>
        </div>
        
        <br />
        {/* <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera={ EWSX.tabla_eficiencia_extraccion.cabeceras } filas={ EWSX.tabla_eficiencia_extraccion.filas } claseTabla='tabla-ewsx simple colores-turnos eficiencia-extraccion' /> */}
        {/* Esta se descartó <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } setDatos={ props.setDatos } datos={ props.datos } cabecera={ EWSX.tabla_consumo.cabeceras } filas={ EWSX.tabla_consumo.filas } claseTabla='tabla-ewsx consumo doble' /> */}
      </div>
    </div>
  )
}


