import digitalizacionAPI from "../../API/digitalizacionAPI";
import smartplsAPI from "../../API/smartplsAPI";
// import smartplsAPIDev from "../../API/smartplsAPIDev";
// import smartplsAPIQA from "../../API/smartplsAPIQA";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setResumenModulosMET } from "../../store/digitalizacion/ResumenModulosMETSlice";
import { setResumenModulosOXE } from "../../store/digitalizacion/ResumenModulosOXESlice";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";

export const useResumenModulos = () => {
    const dispatch = useDispatch();
    const XLSX = require("xlsx-js-style");

    // Token
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};

    const obtenerResumenAPI = async(planta, inicio) => {
        let datos = [];
        if(planta === 'met') {
            await smartplsAPI.get(`/entradasManuales/EntradasManuales_Lectura.php?Consulta=obtener_estadoActualModulosSinCiclo&id_planta=1`).then(res => {
                datos = agruparResumenMET(res.data);
                if(inicio !== true) {
                    dispatch( setResumenModulosMET({ resumen: datos }) );
                }
            });
        } else {
            await smartplsAPI.get(`/entradasManuales/EntradasManuales_Lectura.php?Consulta=obtener_estadoActualModulosSinCiclo&id_planta=2`).then(res => {;
                const lista = (res.data).sort(ordenarArray('nombre_pila'));
                datos = agruparResumenOXE(lista);
                if(inicio !== true) {
                    dispatch( setResumenModulosOXE({ resumen: datos }) );
                }
            });
        }

        return datos;
    }

    function ordenarArray(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const historialModuloAPI = async(planta, id) => {
        try {
            // Obtener historial
            let historial = [];
            await smartplsAPI.get(`/entradasManuales/EntradasManuales_Lectura.php?Consulta=obtener_detalleRegistroEstadoModuloSinCiclo&id_modulo=${id}`).then(res => {
                historial =  res.data;
            });
            
            return historial;
        } catch( err ) {
            console.log(err);
            if( err.response.data === 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
            }
        }
    }

    const historialEstadosPlantaAPI = async(planta) => {
        let datos = [];
        try {
            await digitalizacionAPI.get(`/${planta}/act_estados/`, conf_api).then(({ data }) => {
                datos = data;
            });

        } catch( err ) {
            console.log(err);
            if( err.response.data === 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }

        return datos
    }

    const agruparResumenMET = (datos) => {
        // datos.unshift(datos.pop())
        let lista_este = [];
        let lista_oeste = [];
        // let nombre = '';
        let ids = [];

        datos.forEach( modulo => {
            const { nombre_pila:pila, nombre_modulo:nombre } = modulo;

            if( !ids.includes(nombre)) {
                ids.push(nombre);
                if( pila === 'PILA ESTE') {
                    lista_este.push({
                        id_modulo: parseInt(modulo.id_modulo),
                        nombre_modulo: modulo.nombre_modulo,
                        nombre_pila: modulo.nombre_pila,
                        estado: modulo.estado
                    });
                } else {
                    lista_oeste.push({
                        id_modulo: parseInt(modulo.id_modulo),
                        nombre_modulo: modulo.nombre_modulo,
                        nombre_pila: modulo.nombre_pila,
                        estado: modulo.estado
                    });
                }
            }

        }); 

        return {
            pila_este: lista_este.sort(ordenarArray('id_modulo')),
            pila_oeste: (lista_oeste.sort(ordenarArray('id_modulo'))).reverse()
        };
    }

    const agruparResumenOXE = (datos) => {
        let datos_agrupados = [];

        // Agrupar por franja
        let franja_actual = datos[0].nombre_pila;
        let modulos = [];

        datos.forEach(modulo => {
            if(franja_actual !== modulo.nombre_pila) {
                datos_agrupados = [...datos_agrupados, {
                    id_franja: parseInt(franja_actual.replace("FRANJA ", "")),
                    franja: franja_actual,
                    modulos
                }];

                franja_actual = modulo.nombre_pila;
                modulos = [modulo];

            } else {
                modulos = [...modulos, modulo];
            }
        });

        datos_agrupados = [...datos_agrupados, {
            id_franja: parseInt(franja_actual.replace("FRANJA ", "")),
            franja: franja_actual,
            modulos
        }];

        // console.log(modulos);
        return datos_agrupados.sort(ordenarArray('id_franja'));
    }

    const descargarResumenMET = () => {
        // Generar archivo excel
        const archivo = XLSX.utils.book_new();
    
        const hoja_este = generarHojaMET('este');
    
        // Agregas hojas a archivo
        XLSX.utils.book_append_sheet(archivo, hoja_este, 'Pila Este');
    
        // Generar descarga
        const date = new Date();
        const fecha_actual = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        XLSX.writeFile(archivo, `Resumen_modulos_MET_${fecha_actual}.xlsx`,  { editable: true });
    
    }
    
    const generarHojaMET = () => {
    // Obtener tablas
    const tabla_este = document.getElementById(`tabla-resumen-met-este`);
    const tabla_oeste = document.getElementById(`tabla-resumen-met-oeste`);
    const tabla_estados = document.getElementById("tabla-estados").tBodies[0].rows;

    let cabecera = [
        {
            v: 'PILA ESTE',
            t: 's',
            s: {
                font: {
                sz: 14, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
                },
                alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
                },
                fill: {
                fgColor: {
                    rgb: '37474f'
                }
                },
                border: {
                top: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                }
                }
            }
        },
        {
            v: '',
            t: 's',
        },
        {
            v: '',
            t: 's',
        },
        {
            v: 'PILA OESTE',
            t: 's',
            s: {
                font: {
                sz: 14, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
                },
                alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
                },
                fill: {
                fgColor: {
                    rgb: '37474f'
                }
                },
                border: {
                top: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                }
                }
            }
        },
        {
            v: '',
            t: 's',
        },
        {
            v: '',
            t: 's',
        },
        {
            v: 'Vacío(s)',
            t: 's',
            s: {
                font: {
                    sz: 12, // tamaño
                    bold: true, // grosor
                    color: {
                        rgb: 'ffffff'  // color de letra
                    }
                },
                alignment: {
                    vertical: 'center',
                    horizontal: 'left',
                    wrapText: true
                },
                fill: {
                    fgColor: {
                        rgb: '607d8b'
                    }
                },
                border: {
                    top: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    },
                    bottom: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    },
                    right: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    },
                    left: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    }
                }
            }
        },
        {
            v: tabla_estados[0].children[1].innerHTML,
            t: 's',
            s: {
                font: {
                    sz: 12, // tamaño
                    bold: false, // grosor
                    color: {
                        rgb: '000000'  // color de letra
                    }
                },
                alignment: {
                    vertical: 'center',
                    horizontal: 'center',
                    wrapText: true
                },
                fill: {
                    fgColor: {
                        rgb: 'ffffff'
                    }
                },
                border: {
                top: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                }
                }
            }
        },
    ];

    let merge = [
        { s: {r: 0, c: 0 }, e: { r: 0, c: 1 }},
        { s: {r: 0, c: 3 }, e: { r: 0, c: 4 }}
    ];
    let alto_filas = [{ 'hpt': 22 }, { 'hpt': 20 }];

    let hoja = XLSX.utils.aoa_to_sheet([cabecera]);
    hoja['!merges'] = merge;
    cabecera = [{
        v: 'MÓDULO',
        t: 's',
        s: {
            font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'ffffff'  // color de letra
            }
            },
            alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: '738AA6'
                }
            },
            border: {
            top: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            right: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            left: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            }
            }
        }
        }, {
        v: 'ESTADO',
        t: 's',
        s: {
            font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'ffffff'  // color de letra
            }
            },
            alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
            },
            fill: {
            fgColor: {
                rgb: '738AA6'
            }
            },
            border: {
            top: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            right: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            left: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            }
            }
        }
        }, {
            v: '',
            t: 's'
        }, {
        v: 'MÓDULO',
        t: 's',
        s: {
            font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'ffffff'  // color de letra
            }
            },
            alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
            },
            fill: {
            fgColor: {
                rgb: '738AA6'
            }
            },
            border: {
            top: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            right: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            left: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            }
            }
        }
        }, {
        v: 'ESTADO',
        t: 's',
        s: {
            font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'ffffff'  // color de letra
            }
            },
            alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
            },
            fill: {
            fgColor: {
                rgb: '738AA6'
            }
            },
            border: {
            top: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            right: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            },
            left: {
                style: 'thin',
                color: {
                rgb: 'ABABAB'
                }
            }
            }
        }
        },
        {
            v: '',
            t: 's'
        },
        {
            v: 'Apilado(s)',
            t: 's',
            s: {
                font: {
                    sz: 12, // tamaño
                    bold: true, // grosor
                    color: {
                        rgb: 'ffffff'  // color de letra
                    }
                },
                alignment: {
                    vertical: 'center',
                    horizontal: 'left',
                    wrapText: true
                },
                fill: {
                    fgColor: {
                        rgb: '607d8b'
                    }
                },
                border: {
                    top: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    },
                    bottom: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    },
                    right: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    },
                    left: {
                        style: 'thin',
                        color: {
                        rgb: 'ABABAB'
                        }
                    }
                }
            }
        },
        {
            v: tabla_estados[0].children[3].innerHTML,
            t: 's',
            s: {
                font: {
                    sz: 12, // tamaño
                    bold: false, // grosor
                    color: {
                        rgb: '000000'  // color de letra
                    }
                },
                alignment: {
                    vertical: 'center',
                    horizontal: 'center',
                    wrapText: true
                },
                fill: {
                    fgColor: {
                        rgb: 'ffffff'
                    }
                },
                border: {
                top: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                    rgb: 'ABABAB'
                    }
                }
                }
            }
        }
    ];

    let contador_tabla_estados = 2; // máximo 10
    let origen_fila = 1;
    XLSX.utils.sheet_add_aoa(hoja, [cabecera], { origin: origen_fila });
    origen_fila += 1;

    
    let ancho_cols = [{ wch: 15 }, { wch: 25 }, { wch: 10 }, { wch: 15 }, { wch: 25 }, { wch: 10 }, { wch: 30 } ];
    

    // Cuerpo de tablas
    const tbody_este = tabla_este.tBodies[0].rows;
    const tbody_oeste = tabla_oeste.tBodies[0].rows;

    let pos_estado = 0;
    let pos_fila = 1;
    let contador_estados_fila = 0;
    for(let i = 0; i < tbody_este.length; i++) {
        let cuerpo = [];
        const fila_este = tbody_este[i].children;
        const fila_oeste = tbody_oeste[i].children;

        const modulo_este = fila_este[0].children[0].innerHTML;
        const modulo_oeste = fila_oeste[0].children[0].innerHTML;

        const estado_este = fila_este[1].children[0].innerHTML;
        const estado_oeste = fila_oeste[1].children[0].innerHTML;
        // console.log(estado_este);
        const color_este = obtenerColorEstado(estado_este.replace(/ /g, "_"));
        const color_oeste = obtenerColorEstado(estado_oeste.replace(/ /g, "_"));

        cuerpo = [...cuerpo, 
        { // Módulo este
            v: modulo_este,
            t: 's',
            s: {
                font: {
                    sz: 12, // tamaño
                    bold: true, // grosor
                    color: {
                        rgb: '01579b'  // color de letra
                    }
                },
                alignment: {
                    vertical: 'center',
                    horizontal: 'center',
                    wrapText: true
                },
                fill: {
                    fgColor: {
                        rgb: 'd8e5f0'
                    }
                },
                border: {
                    top: {
                        style: 'thin',
                        color: {
                            rgb: 'ABABAB'
                        }
                    },
                    bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'ABABAB'
                    }
                    },
                    right: {
                    style: 'thin',
                    color: {
                        rgb: 'ABABAB'
                    }
                    },
                    left: {
                    style: 'thin',
                    color: {
                        rgb: 'ABABAB'
                    }
                    }
                }
            }
        },
        { // Estado este
            v: estado_este,
            t: 's',
            s: {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: estado_este === 'Vacio' ? '343434' : 'ffffff' 
                }
            },
            fill: {
                fgColor: {
                rgb: color_este
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                bottom: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                right: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                left: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                }
            }
            }
        },
        { // Espacio entre tablas
            v: '',
            t: ''
        },
        { // Módulo oeste
            v: modulo_oeste,
            t: 's',
            s: {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '01579b'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'd8e5f0'
                }
            },
            border: {
                top: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                bottom: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                right: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                left: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                }
            }
            }
        },
        { // Estado oeste
            v: estado_oeste,
            t: 's',
            s: {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: estado_oeste === 'Vacio' ? '343434' : 'ffffff'  // color de letra
                }
            },
            fill: {
                fgColor: {
                rgb: color_oeste
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                bottom: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                right: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                },
                left: {
                style: 'thin',
                color: {
                    rgb: 'ABABAB'
                }
                }
            }
            }
        },
        ];

        if(contador_tabla_estados < 10) {
            // console.log('Pos estado: '+ pos_estado);
            // console.log('Estado: '+ tabla_estados[pos_fila].children[pos_estado].innerHTML);
            // console.log('Valor: '+ tabla_estados[pos_fila].children[pos_estado+1].innerHTML);
            // console.log('-------------');
            cuerpo = [...cuerpo,
                {
                    v: '',
                    t: 's'
                },
                {
                    v: tabla_estados[pos_fila].children[pos_estado].innerHTML,
                    t: 's',
                    s: {
                        font: {
                            sz: 12, // tamaño
                            bold: true, // grosor
                            color: {
                                rgb: 'ffffff'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'left',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: '607d8b'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            }
                        }
                    }
                },
                {
                    v: tabla_estados[pos_fila].children[pos_estado+1].innerHTML,
                    t: 's',
                    s: {
                        font: {
                            sz: 12, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '000000'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: 'ffffff'
                            }
                        },
                        border: {
                        top: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        }
                        }
                    }
                }
            ];

            pos_estado = pos_estado === 2 ? 0 : pos_estado + 2;
            contador_tabla_estados += 1;
            contador_estados_fila = contador_estados_fila === 2 ? 1 : contador_estados_fila+1;
            pos_fila = contador_estados_fila === 2 ? pos_fila+1 : pos_fila;
        }

        XLSX.utils.sheet_add_aoa(hoja, [cuerpo], { origin: origen_fila });
        origen_fila += 1;
        alto_filas = [...alto_filas, { hpt: 20 }]
    }

    // Ajustar ancho y alto de filas y  columnas
    hoja['!rows'] = alto_filas;
    hoja['!cols'] = ancho_cols;

    return hoja;
    }
    
    const obtenerColorEstado = (estado) => {
        switch( estado ) {
            case 'Vacio':
                return 'e6e7f3';

            case 'Inicio_Apilamiento':
            case 'Fin_Apilamiento':
                return '778d5c';

            case 'Ruteo':
                return 'b6703b';

            case 'Armado_Parrilla':
            case 'Armado Parrilla':
                return 'C5A463';
            
            case 'Humectación':
            case 'Humectacion':
                return '3e5e7c';

            case 'Riego':
                return '427975';

            // case 'En riego refino':
            // return '6E9ACA';

            case 'Drenaje':
                return '9f89a1';

            case 'Desarme_de_Parrilla':
            case 'Desarme de Parrilla':
                return '434E5F';

            case 'Inicio_Derripado':
            case 'Fin_Derripado':
                return '484D52';

            case 'Detenido_por_Fuerza_Mayor':
            case 'Detenido por Fuerza Mayor':
                return '925d5d';
        }
    }

    const descargarResumenOXE = () => {
        const date = new Date();
        const fecha_actual = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        
        //generar archivo excel
        const archivo = XLSX.utils.book_new();
        const hoja_resumen = generarHojaOXE();

        // agregar hoja a archivo
        XLSX.utils.book_append_sheet(archivo, hoja_resumen, 'Resumen');

        XLSX.writeFile(archivo, `resumen_oxe_${fecha_actual}.xlsx`);
    }

    const generarHojaOXE = () => {
        // obtener tabla
        const tabla = document.getElementById("tabla-resumen-oxe");

        // cabecera
        const thead = tabla.tHead.rows[0].children;
        let cabecera = [];
        let ancho_cols = [];

        for(let i = 0 ; i < thead.length; i++) {
            const valor = thead[i].innerHTML;
            ancho_cols = i === 0 || i === 1 ? [...ancho_cols, { wch:4 }] : [...ancho_cols, { wch: 25 }];
            cabecera = [...cabecera, {
                v: valor,
                t: 's',
                s: {
                    font: {
                        sz: 12,//tamaño
                        bold: true,//grosor
                        color: {
                            rgb: 'ffffff'//color de la letra
                        }
                    },
                    alignment:{
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill:{
                        fgColor: {
                            rgb: '607d8b'
                        }
                    },
                    border:{
                        top:{
                            style: 'thin',
                            color: {
                                rgb: 'ABABAB'
                            }
                        },
                        bottom:{
                            style: 'thin',
                            color: {
                                rgb: 'ABABAB'
                            }
                        },
                        right:{
                            style: 'thin',
                            color: {
                                rgb: 'ABABAB'
                            }
                        },
                        left:{
                            style: 'thin',
                            color: {
                                rgb: 'ABABAB'
                            }
                        },
                    }
                }
            }];
        }

        let hoja = XLSX.utils.aoa_to_sheet([cabecera]);
        let alto_filas = [{'hpt': 25}];

        const tbody = tabla.tBodies[0].rows;
        let merge = [
            { s: { r: 1, c: 0 }, e: { r: 4, c: 0 } },
            { s: { r: 5, c: 0 }, e: { r: 8, c: 0 } }
        ];

        for(let i = 0; i < tbody.length; i++) {
            const fila = tbody[i].children;
            let cuerpo = [];

            for(let h = 0; h < fila.length; h++) {
                const clases_celda = fila[h].classList;
                let valor = '';

                switch(true) {
                    case (h === 0 && clases_celda.contains('bloque')):
                        valor = fila[h].innerHTML;
                        cuerpo = [...cuerpo, 
                            {
                                v: valor,
                                t: 's',
                                s: {
                                    font: {
                                        sz: 12,//tamaño
                                        bold: true,//grosor
                                        color: {
                                            rgb: 'ffffff'
                                        }
                                    },
                                    alignment:{
                                        vertical: 'center',
                                        horizontal: 'center',
                                        wrapText: true
                                    },
                                    fill:{
                                        fgColor: {
                                            rgb: '607d8b'
                                        }
                                    },
                                    border:{
                                        top:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        bottom:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        right:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        left:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                    }
                                }

                            }, {
                                v: '4',
                                t: 's',
                                s: {
                                    font: {
                                        sz: 12,//tamaño
                                        bold: true,//grosor
                                        color: {
                                            rgb: 'ffffff'//color de la letra
                                        }
                                    },
                                    alignment:{
                                        vertical: 'center',
                                        horizontal: 'center',
                                        wrapText: true
                                    },
                                    fill:{
                                        fgColor: {
                                            rgb: '3677b0'
                                        }
                                    },
                                    border:{
                                        top:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        bottom:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        right:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        left:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                    }
                                }
                            }
                            
                        ]
                        break;

                    case (h === 0 && clases_celda.contains('modulo')):
                        valor = fila[h].innerHTML;
                        cuerpo = [...cuerpo, {
                                v: '',
                                t: 's',
                                s: {
                                    font: {
                                        sz: 12,//tamaño
                                        bold: true,//grosor
                                        color: {
                                            rgb: 'ffffff'//color de la letra
                                        }
                                    },
                                    alignment:{
                                        vertical: 'center',
                                        horizontal: 'center',
                                        wrapText: true
                                    },
                                    fill:{
                                        fgColor: {
                                            rgb: '607d8b'
                                        }
                                    },
                                    border:{
                                        top:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        bottom:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        right:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                        left:{
                                            style: 'thin',
                                            color: {
                                                rgb: 'ABABAB'
                                            }
                                        },
                                    }
                                }

                        }];

                        cuerpo = [...cuerpo, {
                            v: valor,
                            t: 's',
                            s: {
                                font: {
                                    sz: 12,//tamaño
                                    bold: true,//grosor
                                    color: {
                                        rgb: 'ffffff'//color de la letra
                                    }
                                },
                                alignment:{
                                    vertical: 'center',
                                    horizontal: 'center',
                                    wrapText: true
                                },
                                fill:{
                                    fgColor: {
                                        rgb: '3677b0'
                                    }
                                },
                                border:{
                                    top:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                    bottom:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                    right:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                    left:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                }
                            }
                        }];
                        break;

                    case (h !== 0 && !clases_celda.contains('modulo')):
                        valor = fila[h].children[0].innerHTML;
                        const color = obtenerColorEstado(valor.replace(/ /g, "_"));
                        
                        cuerpo = [...cuerpo, {
                            v: valor,
                            t: 's',
                            s: {
                                font: {
                                    sz: 12,//tamaño
                                    bold: true,//grosor
                                    color: {
                                        rgb: valor === 'Vacio' ? '343434' : 'ffffff'//color de la letra
                                    }
                                },
                                alignment:{
                                    vertical: 'center',
                                    horizontal: 'center',
                                    wrapText: true
                                },
                                fill:{
                                    fgColor: {
                                        rgb: color
                                    }
                                },
                                border:{
                                    top:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                    bottom:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                    right:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                    left:{
                                        style: 'thin',
                                        color: {
                                            rgb: 'ABABAB'
                                        }
                                    },
                                }
                            }
                        }];
                        break;
                }

            }

            alto_filas = [...alto_filas, { hpt: 20 }]
            XLSX.utils.sheet_add_aoa(hoja, [cuerpo], { origin: i+1 });
        }


        // Tabla con estados contabilizados
        const tabla_estados = document.getElementById("tabla-estados").tBodies[0].rows;
        let fila_estados = [{}];
        let origen_tabla = tbody.length;

        XLSX.utils.sheet_add_aoa(hoja, [fila_estados], { origin: origen_tabla });
        XLSX.utils.sheet_add_aoa(hoja, [fila_estados], { origin: origen_tabla+1 });
        origen_tabla += 2;

        for(let i = 0; i < tabla_estados.length; i++) {
            fila_estados = [
                {}, {}, // espacios vacíos
                {
                    v: tabla_estados[i].children[0].innerHTML,
                    t: 's',
                    s: {
                        font: {
                            sz: 12, // tamaño
                            bold: true, // grosor
                            color: {
                                rgb: 'ffffff'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'left',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: '607d8b'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            }
                        }
                    }
                },
                {
                    v: tabla_estados[i].children[1].innerHTML,
                    t: 's',
                    s: {
                        font: {
                            sz: 12, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '000000'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: 'ffffff'
                            }
                        },
                        border: {
                        top: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        }
                        }
                    }
                },
                {
                    v: tabla_estados[i].children[2].innerHTML,
                    t: 's',
                    s: {
                        font: {
                            sz: 12, // tamaño
                            bold: true, // grosor
                            color: {
                                rgb: 'ffffff'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'left',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: '607d8b'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                rgb: 'ABABAB'
                                }
                            }
                        }
                    }
                },
                {
                    v: tabla_estados[i].children[3].innerHTML,
                    t: 's',
                    s: {
                        font: {
                            sz: 12, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '000000'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: 'ffffff'
                            }
                        },
                        border: {
                        top: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                            rgb: 'ABABAB'
                            }
                        }
                        }
                    }
                }
            ];

            XLSX.utils.sheet_add_aoa(hoja, [fila_estados], { origin: origen_tabla });
            origen_tabla += 1;
        }

        hoja['!cols'] = ancho_cols;
        hoja['!rows'] = alto_filas;
        hoja['!merges'] = merge;


        return hoja;
    }

    const contarEstados = (planta, datos) => {
        let respuesta = {};
        switch(planta) {
            case 'met':
                respuesta = estadosMET(datos);
                break;

            case 'oxe':
                respuesta = estadosOXE(datos);
                break;
        }

        return respuesta;
    }

    const estadosMET = (datos) => {
        const { pila_este, pila_oeste } = datos;

        let cont_estados = {
            Vacio: 0, Inicio_Apilamiento: 0, Fin_Apilamiento: 0, Armado_Parrilla: 0, Humectacion: 0, Riego: 0,
            Detenido_por_Fuerza_Mayor: 0, Drenaje: 0, Desarme_de_Parrilla: 0, Inicio_Derripado: 0, Fin_Derripado: 0
        }

        pila_este.forEach(({ estado }) => {
            const estado_modulo = estado.replace(/ /g, '_');
            cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
        });

        pila_oeste.forEach(({ estado }) => {
            const estado_modulo = estado.replace(/ /g, '_');
            cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
        });

        let lista_contabilizada = [];
        let lista_estados = [];
        let colores = [];
        for(const estado in cont_estados) {
            if(cont_estados[estado] > 0) {
                lista_contabilizada = [...lista_contabilizada, { x: estado.replace(/_/g, " "), y: cont_estados[estado] }];
                lista_estados = [...lista_estados, estado];
            }
        }

        lista_estados.forEach(estado => {
            colores = [...colores, `#${obtenerColorEstado(estado)}`];
        });


        return {
            lista_contabilizada,
            lista_estados,
            colores
        }
    }

    const estadosOXE = (datos) => {
        let cont_estados = {
            Vacio: 0, Inicio_Apilamiento: 0, Fin_Apilamiento: 0, Ruteo: 0, Armado_Parrilla: 0, Humectacion: 0, Riego: 0,
            Detenido_por_Fuerza_Mayor: 0, Drenaje: 0, Desarme_de_Parrilla: 0, Inicio_Derripado: 0, Fin_Derripado: 0
        }

        datos.forEach(({ modulos }) => {
          modulos.forEach(modulo => {
            const estado_modulo = modulo.estado.replace(/ /g, "_");
            cont_estados[estado_modulo] = cont_estados[estado_modulo] + 1;
          })
        }); 

        let lista_contabilizada = [];
        let lista_estados = [];
        let colores = [];
        for(const estado in cont_estados) {
            if(cont_estados[estado] > 0) {
                lista_contabilizada = [...lista_contabilizada, { x: estado.replace(/_/g, " "), y: cont_estados[estado] }];
                lista_estados = [...lista_estados, estado];
            }
        }
        
        lista_estados.forEach(estado => {
            colores = [...colores, `#${obtenerColorEstado(estado)}`];
        });

        return {
            lista_contabilizada,
            lista_estados,
            colores
        }
    }

    const descargarHistorial = (planta, DatosDia, DatosA, DatosB, fecha) => {
        const archivo = XLSX.utils.book_new();

        // Hojas
        const hoja_dia = generarHoja(DatosDia);
        const hoja_a = generarHoja(DatosA);
        const hoja_b = generarHoja(DatosB);

        XLSX.utils.book_append_sheet(archivo, hoja_dia, 'Resumen diario');
        XLSX.utils.book_append_sheet(archivo, hoja_a, 'Turno A');
        XLSX.utils.book_append_sheet(archivo, hoja_b, 'Turno B');

        XLSX.writeFile(
            archivo, 
            `Historial_estados_planta_${planta.toUpperCase()}_${fecha}.xlsx`,
            { cellDates: true, editable: true }
        )
        
    }

    const generarHoja = (datos) => {
        // Cabecera
        let fila = [
            {
                v: 'FECHA',
                t: 's',
                s: {
                    font: {
                        sz: 12,
                        bold: true,
                        color: {
                            rgb: "ffffff"
                        }
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '3677b0'
                        }
                    },
                    border: {
                        bottom: {
                            style: "thick",
                            color: {
                                rgb: '607d8b'
                            }
                        }
                    }
                }
            },
            {
                v: 'CICLO',
                t: 's',
                s: {
                    font: {
                        sz: 12,
                        bold: true,
                        color: {
                            rgb: "ffffff"
                        }
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '3677b0'
                        }
                    },
                    border: {
                        bottom: {
                            style: "thick",
                            color: {
                                rgb: '607d8b'
                            }
                        }
                    }
                }
            },
            {
                v: 'MÓDULO',
                t: 's',
                s: {
                    font: {
                        sz: 12,
                        bold: true,
                        color: {
                            rgb: "ffffff"
                        }
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '3677b0'
                        }
                    },
                    border: {
                        bottom: {
                            style: "thick",
                            color: {
                                rgb: '607d8b'
                            }
                        }
                    }
                }
            },
            {
                v: 'ESTADO',
                t: 's',
                s: {
                    font: {
                        sz: 12,
                        bold: true,
                        color: {
                            rgb: "ffffff"
                        }
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '3677b0'
                        }
                    },
                    border: {
                        bottom: {
                            style: "thick",
                            color: {
                                rgb: '607d8b'
                            }
                        }
                    }
                }
            },
            {
                v: 'USUARIO',
                t: 's',
                s: {
                    font: {
                        sz: 12,
                        bold: true,
                        color: {
                            rgb: "ffffff"
                        }
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '3677b0'
                        }
                    },
                    border: {
                        bottom: {
                            style: "thick",
                            color: {
                                rgb: '607d8b'
                            }
                        }
                    }
                }
            }
        ];
        let hoja =  XLSX.utils.aoa_to_sheet([fila]);
        
        let cols = [{ wch: 30 }, { wch: 25 }, { wch: 15 }, { wch: 30 }, { wch: 20 }];
        let rows = [{ 'hpt': 23 }];

        fila = [];
        let contador = 0;
        datos.forEach(({ datetime_actualizado:fecha, ciclo, modulo, estado, usuario }) => {
            rows = [...rows, { 'hpt': 23 }];

            fila = [...fila, 
                {
                    v: fecha,
                    t: 's',
                    s: {
                        font: {
                            bold: false,
                            sz: 11,
                            color: {
                                rgb: '1e1e1e' 
                            }
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        },
                        fill: {
                            fgColor: {
                                rgb: contador % 2 === 0 ? 'ffffff' : 'f2f2f2'
                            }
                        },
                        border: {
                            bottom: {
                                style: "thin",
                                color: {
                                    rgb: "c9d0d8"
                                }
                            }
                        }
                    }
                },
                {
                    v: ciclo,
                    t: 's',
                    s: {
                        font: {
                            bold: false,
                            sz: 11,
                            color: {
                                rgb: '1e1e1e' 
                            }
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        },
                        fill: {
                            fgColor: {
                                rgb: contador % 2 === 0 ? 'ffffff' : 'f2f2f2'
                            }
                        },
                        border: {
                            bottom: {
                                style: "thin",
                                color: {
                                    rgb: "c9d0d8"
                                }
                            }
                        }
                    }
                },
                {
                    v: modulo,
                    t: 's',
                    s: {
                        font: {
                            bold: false,
                            sz: 11,
                            color: {
                                rgb: '1e1e1e' 
                            }
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        },
                        fill: {
                            fgColor: {
                                rgb: contador % 2 === 0 ? 'ffffff' : 'f2f2f2'
                            }
                        },
                        border: {
                            bottom: {
                                style: "thin",
                                color: {
                                    rgb: "c9d0d8"
                                }
                            }
                        }
                    }
                },
                {
                    v: estado.replace(/_/g, ' '),
                    t: 's',
                    s: {
                        font: {
                            bold: false,
                            sz: 11,
                            color: {
                                rgb: '1e1e1e' 
                            }
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        },
                        fill: {
                            fgColor: {
                                rgb: contador % 2 === 0 ? 'ffffff' : 'f2f2f2'
                            }
                        },
                        border: {
                            bottom: {
                                style: "thin",
                                color: {
                                    rgb: "c9d0d8"
                                }
                            }
                        }
                    }
                },
                {
                    v: usuario,
                    t: 's',
                    s: {
                        font: {
                            bold: false,
                            sz: 11,
                            color: {
                                rgb: '1e1e1e' 
                            }
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        },
                        fill: {
                            fgColor: {
                                rgb: contador % 2 === 0 ? 'ffffff' : 'f2f2f2'
                            }
                        },
                        border: {
                            bottom: {
                                style: "thin",
                                color: {
                                    rgb: "c9d0d8"
                                }
                            }
                        }
                    }
                }
            ];

            XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: contador+1 });
            contador += 1;
            fila = [];
        });

        hoja['!rows'] = rows;
        hoja['!cols'] = cols;

        return hoja;
    }


    return {
        obtenerResumenAPI,
        historialModuloAPI,
        historialEstadosPlantaAPI,
        descargarResumenMET,
        descargarResumenOXE,
        contarEstados,
        descargarHistorial
    }
}