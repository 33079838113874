import { useDispatch, useSelector } from "react-redux"
import { iniciarSesion, cerrarSesion } from "../../store/autentificacion/autentificacionSlice";
import digitalizacionAPI from "../../API/digitalizacionAPI";

export const useAuthStore = () => {
    // Obtener datos del store
    const { nombre, token, correo, estado, uid } = useSelector(state =>  state.autentificacion );
    const dispatch =  useDispatch();

    const autentificarAPI= async(data) => {
        const { correo, clave } =  data;

        try {
            const { data } = await digitalizacionAPI.post('/auth/', { correo, clave });
            // console.log(data);
            return data;
            
        } catch (error) {
            // console.log(error.response.data.msg);
            return error.response.data;
        }
    }

    const validarCorreo = async(correo) => {
        try {
            const res = await digitalizacionAPI.post('/auth/validar', { correo });
            return res.data.existe
        } catch(error) {
            return error.message;
        }
    }

    const autentificarStore = (data) => {
        dispatch( iniciarSesion(data) );
    }

    const cerrarSesionStore = () => {
        dispatch( cerrarSesion() );
    }

    const validarDatosSesion = () => {
        if( estado == true ) {
            if( nombre.length == 0 || correo.length == 0 || token.length == 0 || uid == 0 || uid == null ) {
                dispatch( cerrarSesion() );
            }
        }
    }
    
    return {
        nombre,
        correo,
        token,
        autentificarAPI,
        validarCorreo,
        autentificarStore,
        cerrarSesionStore,
        validarDatosSesion
    }
}