import React, { useEffect, useState } from 'react'
import { Grafico } from './Grafico';
import { useCosecha } from '../../../hooks/useCosecha';
import { Nomina } from './Nomina';
import M from 'materialize-css';
import '../styles/tabs.scss'

export const Produccion = ( props ) => {
  const { cambiarInforme, obtenerMesAnio } = useCosecha();
  const moment = require('moment');

  const [Ejecutado, setEjecutado] = useState(false);
  const [Mes, setMes] = useState('');
  const [Anio, setAnio] = useState('');

  useEffect(() => {
    if ( !Ejecutado ) {
        const { texto_mes, anio } = obtenerMesAnio( moment().format('YYYY-MM-DD') );
        setMes( texto_mes.toUpperCase() );
        setAnio( anio )
        setEjecutado( true );

        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems);
    }
  }, []);

  return (
    <div id="tab-produccion-cosecha" className="tab-cosecha activo">

      <div className="contenido-cosecha scroll">
        {/* DATOS MES PRESENTE */}
        <h6>INFORME MENSUAL { Mes } {Anio}</h6>
        <div className="btns-informe">
          <button 
            className="btn waves-effect waves-light activo transparent" 
            id="btn-informe-grafico-mensual"
            onClick={ () => { cambiarInforme('tab-informe-nomina-mensual','btn-informe-nomina-mensual','tab-informe-grafico-mensual','btn-informe-grafico-mensual') } }
          >
            <i className="material-icons left">show_chart</i>
            GRÁFICO
          </button>

          <button 
            className="btn waves-effect waves-light transparent" 
            id="btn-informe-nomina-mensual"
            onClick={ () => { cambiarInforme('tab-informe-grafico-mensual','btn-informe-grafico-mensual','tab-informe-nomina-mensual','btn-informe-nomina-mensual') } }
          >
            <i className="material-icons left">table_chart</i>
            NÓMINA
          </button>
        </div>
        <Grafico id_grafico="graf-prod-mensual" id_tab="tab-informe-grafico-mensual" datos={ props.datos.informe_mensual.grafico } interfaz={ props.interfaz }/>
        <Nomina id_tab="tab-informe-nomina-mensual" datos={ props.datos.informe_mensual.nomina } interfaz={ props.interfaz } />

        <br />

        {/* DATOS ANUALES */}
        <h6>INFORME ANUAL</h6>
        <div className="btns-informe">
          <button 
            className="btn waves-effect waves-light activo transparent" 
            id="btn-informe-grafico-anual"
            onClick={ () => { cambiarInforme('tab-informe-nomina-anual','btn-informe-nomina-anual','tab-informe-grafico-anual','btn-informe-grafico-anual') } }
          >
            <i className="material-icons left">show_chart</i>
            GRÁFICO
          </button>

          <button 
            className="btn waves-effect waves-light transparent" 
            id="btn-informe-nomina-anual"
            onClick={ () => { cambiarInforme('tab-informe-grafico-anual','btn-informe-grafico-anual','tab-informe-nomina-anual','btn-informe-nomina-anual') } }
          >
            <i className="material-icons left">table_chart</i>
            NÓMINA
          </button>
        </div>

        <Grafico id_grafico="graf-prod-anual" id_tab="tab-informe-grafico-anual" datos={ props.datos.informe_anual.grafico } interfaz={ props.interfaz }/>
        <Nomina id_tab="tab-informe-nomina-anual" datos={ props.datos.informe_anual.nomina } interfaz={ props.interfaz } />
        <br />
      </div>
    </div>
  )
}
