
import { Loader } from "./Loader"

export const ModalCarga = ( props ) => {
    return (
        <div id="modal-carga"  className="modal carga">
            <div className="modal-content">
                <div className="cont-loader lmodal"> 
                    <Loader />
                </div>
                {
                    props.msj
                    ? <p>{ props.msj }</p>
                    : <p>Guardando cambios...</p>
                }
                
            </div>
        </div>
    )
}
