import { useEffect } from "react";
import M from "materialize-css";
import { ModalDescargar } from "./ModalDescargar"

export const FilaSuperior = (props) => {
  useEffect(() => {
    var elems = document.querySelectorAll('.modal');
    var instances = M.Modal.init(elems, {dismissible: false});
  }, [])

  const cambiarMes = () => {
    const select =  document.getElementById(`select-mes-horasflujos-${props.turno}`);
    const id = parseInt(select.value);
    let grupo = props.datos.grupos[id];

    props.setFilasGeneral( grupo.filas_general );
    props.setFilasA( grupo.filas_turno_a );
    props.setFilasB( grupo.filas_turno_b );
  }

  return (
    <>
      <ModalDescargar planta={ props.planta } />

      <div className="fila-superior">
          <div className="titulo-mes">
            <h3 className="titulo-pagina">
              CONTROL DE RIEGO { props.planta.toUpperCase() }
            </h3>
            <select 
              className="browser-default selector-mes" 
              id={ "select-mes-horasflujos-" + props.turno } 
              onChange={ cambiarMes } 
              defaultValue={ props.meses[props.meses.length-1].valor } 
            >
              {
                  props.meses.map((mes, index) => (
                      <option key={ index } value={ mes.valor }>
                          { mes.label.replace("_"," ") }
                      </option>
                  ))
              }
            </select>
          </div>

            <div className="cont-btns">
              {/* Exportar datos de tablas */}
              <button className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar modal-trigger" data-target="modal-descargar">
                <i className="material-icons left">download</i>
                DESCARGAR 
              </button>
          </div> 
      </div>
    
    </>
  )
}