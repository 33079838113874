import React from 'react'

export const Tabla = (props) => {
    
    return (
        !props.Interfaz
        ? null
        :
            <table id="tabla-analisis" className='tabla-analisis'>
                <thead>
                    {
                        (props.datos.cabecera).map( (fila, index) => (
                            <tr key={ index }>
                                {
                                    fila.map( (celda, subindex) => (   
                                        <th  key={ subindex } colSpan={ celda.cols }>{ celda.nombre }</th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>

                <tbody>
                    {
                        (props.datos.filas).map( (fila, index) => (
                            <tr key={ index }>
                                {
                                    fila.map( (celda, subindex) => (
                                        <td key={ subindex } rowSpan={ celda.rows } className={ celda.clases }>
                                            { celda.valor }
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
    )
}
