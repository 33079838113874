import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


import useControlRiego from "../../../hooks/useControlRiego";

import M from 'materialize-css';

export const ModalDescargar = (props) => {
    const moment = require('moment');
    const datos = useSelector((state) => state.ControlRiego.contenido);

    const { descargarControlRiego } = useControlRiego();

    const [FechaMinima, setFechaMinima] = useState(null);
    const [FechaMaxima, setFechaMaxima] = useState(null);

    const [Ejecutado, setEjecutado] = useState(false)

    useEffect(() => {
        if(Object.keys(datos).length > 0 && Ejecutado == false) {
            setEjecutado(true);
            const { grupos } = datos.datos_tablas;
            
            const { filas_general:primero } = grupos[0];
            const { filas_general:ultimo } = grupos[grupos.length-1];

            const fecha_minima = primero[0][0].valor;
            const fecha_maxima = ultimo[ultimo.length-2][0].valor;

            setFechaMinima( fecha_minima);
            setFechaMaxima( fecha_maxima );
        } 
      }, [datos])
    
    const generarDescarga = () => {
        if( document.getElementById('input-desde').value == '' || document.getElementById('input-hasta').value == '' ) {
            M.toast({html: 'Debe ingresar dos fechas para obtener un rango de datos', classes: 'error'} )

        } else if( document.getElementById('input-desde').value > document.getElementById('input-hasta').value ) {
            M.toast({html: 'El rango de fechas es incorrecto', classes: 'error'} )
        } else {
            const { grupos } = datos.datos_tablas;
            const fecha_minima = moment(document.getElementById('input-desde').value).format('YYYY-MM-DD');
            const fecha_maxima = moment(document.getElementById('input-hasta').value).format('YYYY-MM-DD');

            let modulos = datos.datos_tablas.modulos;
            let filas_general = [];
            let filas_a = [];
            let filas_b = [];
        
            grupos.forEach(({ filas_general:general, filas_turno_a, filas_turno_b }) => {
                let pos = 1; // 1: Hora, 2: Flujo 3: Presion
                for( let i = 0; i < general.length; i++ ) {
                    const fila_general = general[i];
                    const fila_a = filas_turno_a[i];
                    const fila_b = filas_turno_b[i];

                    const fecha_fila = moment(fila_general[0].valor).format('YYYY-MM-DD');

                    if( i != filas_general.length-1  && fecha_fila >= fecha_minima && fecha_fila <= fecha_maxima ) {
                        filas_general = [...filas_general, fila_general];
                        filas_a = [...filas_a, fila_a];
                        filas_b = [...filas_b, fila_b];
                    }
                }
            });

            filas_general = sumarFilaTotal( filas_general, modulos ); 
            filas_a = sumarFilaTotal( filas_a, modulos ); 
            filas_b = sumarFilaTotal( filas_b, modulos );  
            descargarControlRiego( props.planta, { filas_general, filas_a, filas_b }, modulos );
        }
    }

    const sumarFilaTotal = (filas, modulos) => {
        let fila_total = [{ clases: '', valor: 'TOTAL'}];
        let pos = 1;
        let cant_celdas = modulos.length * 3 + 1;

        if( props.planta == 'met' ) {
            let cant_celdas = modulos.length * 3 + 1;
            for( let i = 1; i < cant_celdas; i++) {
                let suma_horas = 0;
                let suma_flujo = 0;
                let suma_presion = 0;
                let div_flujo = 0;
                let div_presion = 0;
    
                filas.forEach( fila => {
                    const celda = fila[i];
                    const valor = celda.valor != '' ? parseFloat(celda.valor) : '';
    
                    switch( pos ) {
                        case 1:
                            suma_horas += valor != '' ? valor : 0;
                            break;
    
                        case 2:
                            suma_presion += (valor > 0 && valor != '')  ? valor : 0;
                            div_presion += (valor > 0 && valor != '')  ? 1 : 0; 
                            break;
    
                        case 3:
                            suma_flujo += (valor > 0 && valor != '')  ? valor : 0;
                            div_flujo += (valor > 0 && valor != '')  ? 1 : 0; 
                            
                            break;
                    }
                });
    
                switch( pos ) {
                    case 1:
                        pos += 1;
                        fila_total = [...fila_total, {
                            clases: '',
                            valor: suma_horas
                        }];
    
                        break;
    
                    case 2:
                        pos = pos + 1;
                        div_presion = div_presion == 0 ? 1 : div_presion;
                        fila_total = [...fila_total, {
                            clases: '',
                            valor: (suma_presion / div_presion).toFixed(2)
                        }];
    
                        break;
    
                    case 3:
                        pos = 1;
                        div_flujo = div_flujo == 0 ? 1 : div_flujo;
                        fila_total = [...fila_total, {
                            clases: '',
                            valor: (suma_flujo / div_flujo).toFixed(2)
                        }];
    
                        break;
                }
            }
        } else {
            let cant_celdas = modulos.length * 2 + 1;
            for( let i = 1; i < cant_celdas; i++) {
                let suma_horas = 0;
                let suma_flujo = 0;
                let div_flujo = 0;
    
                filas.forEach( fila => {
                    const celda = fila[i];
                    const valor = celda.valor != '' ? parseFloat(celda.valor) : '';
    
                    switch( pos ) {
                        case 1:
                            suma_horas += valor;
                            break;
    
                        case 2:
                            suma_flujo += (valor > 0 && valor != '')  ? valor : 0;
                            div_flujo += (valor > 0 && valor != '')  ? 1 : 0; 
                            
                            break;
                    }
                });
    
                switch( pos ) {
                    case 1:
                        pos += 1;
                        fila_total = [...fila_total, {
                            clases: '',
                            valor: suma_horas
                        }];
    
                        break;

                    case 2:
                        pos = 1;
                        div_flujo = div_flujo == 0 ? 1 : div_flujo;
                        fila_total = [...fila_total, {
                            clases: '',
                            valor: (suma_flujo / div_flujo).toFixed(2)
                        }];
    
                        break;
                }
            }
        }


        return [...filas, fila_total];
    }

    return (
        <div id="modal-descargar" className="modal">
            <div className="modal-content">
                <div className="cabecera-modal">
                <i className="material-icons">download</i>
                    <button className="modal-close">
                        <i className="material-icons">close</i>
                    </button>
                </div>

                <div className="selector-modal">
                    <h5>DESCARGAR CONTROL DE RIEGO</h5>
                    <p>Seleccionar rango de fechas a obtener</p>

                    <div className="cont-inputs-cr">
                        <div>
                            <label htmlFor="input-desde">Desde</label>
                            <input type="date" id="input-desde" min={ '2023-01-30' } max={ FechaMaxima } />
                        </div>

                        <div>
                            <label htmlFor="input-hasta">Hasta</label>
                            <input type="date" id="input-hasta" min={ FechaMinima } max={ FechaMaxima } />
                        </div>
                    </div>
                    

                    <div className="cont-btns">
                        <button className="btn waves-effect waves-light light-blue darken-4 btn-aceptar" onClick={ generarDescarga }>DESCARGAR</button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
