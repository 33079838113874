
export const Tabla = (props) => {
    return (
        <div className="cont-tabla-rlix scroll">
            <table className="tabla-rlix striped" id="tabla-rlix">
                <thead>
                    <tr>
                        {
                            props.cabecera.map((nombre, index) => (
                                <th key={ index }>{ nombre }</th>
                            ))
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        props.planta === 'met'
                        ?
                            props.modulos.map((modulo, index) => (
                                modulo.Nomenclatura !== null
                                ?
                                <tr key={ index } className={ index % 2 !== 0 ? 'impar' : 'n' } >
                                    <td className="cuerpo modulo">{ modulo.Modulo }</td>
                                    <td>{ modulo.Ciclo }</td>
                                    <td>{ modulo.Fecha_Inicio_Ciclo.split(" ")[0] }</td>
                                    <td>{ modulo.Fecha_Cumplimiento_RLIX.split(" ")[0] }</td>
                                    <td>{ modulo.TMS }</td>
                                    <td>{ modulo.Total_Horas }</td>
                                    <td>{ modulo.Volumen_Total }</td>
                                    <td>{ modulo.DiasRiego_Efectivo }</td>
                                    <td>{ modulo.RazonRiego_Efectiva }</td>
                                    <td>{ modulo.Rlix_Estimado }</td>
                                    <td><span className={ "estado-rlix " + ( modulo.EstadoCiclo ).replace(/ /g, "_") }>{ modulo.EstadoCiclo }</span></td>
                                </tr>
                                : null
                            ))
                        :
                            props.modulos.map((modulo, index) => (
                                <tr key={ index }>
                                    <td className="cuerpo modulo">{ modulo.Nomenclatura }</td>
                                    <td>{ modulo.Ciclo }</td>
                                    <td>{ modulo.Fecha_Inicio_Ciclo.split(" ")[0] }</td>
                                    {/* <td>{ FechaActual }</td> */}
                                    <td>{ modulo.Fecha_Cumplimiento_RLIX.split(' ')[0] }</td>
                                    <td>{ modulo.DiasRiego_Efectivo }</td>
                                    <td>{ modulo.TMS }</td>
                                    <td>{ modulo.Volumen_Total.toFixed(2) }</td>
                                    <td>{ (modulo.Volumen_Total / modulo.TMS).toFixed(2)  }</td>
                                    {/* <td>{ modulo.RazonRiego_Efectiva }</td> */}
                                    <td>{ modulo.Rlix_Estimado }</td>
                                    <td><span className={ "estado-rlix " + ( modulo.EstadoCiclo ).replace(/ /g, "_") }>{ modulo.EstadoCiclo }</span></td>
                                </tr>
                            ))
                    }
                </tbody>

            </table>
            <br />
        </div>
    )
}
