import { createSlice } from '@reduxjs/toolkit';

const initialState = { estado: false, correo: '', nombre: '', error: '', dominio: '', token: '' };

const autentificacionSlice = createSlice({
    name: 'Autentificación',
    initialState,
    reducers: {
        iniciarSesion: (state, { payload }) => {
            state.estado = true;
            state.nombre = payload.nombre;
            state.correo = payload.correo;
            state.token = payload.token;
            state.dominio = payload.dominio;
            state.uid = payload.uid;
            state.rol = payload.rol;
            
        },
        cerrarSesion: (state) => {
            state.correo = '';
            state.nombre = '';
            state.estado = false;
            state.dominio = '';
            state.token = '';
            state.uid = '';
            state.rol = '';
        },
        estadoValidar: (state) => {
            state.estado = true;
        },
        revisarCredenciales: (state) => {

        }
    }
})

export const { iniciarSesion, cerrarSesion, estadoValidar, revisarCredenciales } = autentificacionSlice.actions;
export default autentificacionSlice.reducer;