// Redux
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";
import { setControlRiego, limpiarControlRiego } from "../../store/digitalizacion/ControlRiegoSlice";

// API
import digitalizacionAPI from "../../API/digitalizacionAPI";

const useControlRiego = () => {
    // Variables
    const XLSX = require("xlsx-js-style");
    const dispatch = useDispatch();

    // Token
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};

    const obtenerControlRiegoAPI = async(planta, inicio) => {
        let datos_tablas = {};

        try {
            // Determinar ID planta
            const id_planta = planta === 'met' ?  1 : 2;
    
            // Obtener datos desde API
            
            await digitalizacionAPI.get(`/comun/controlRiego/${id_planta}`, conf_api).then(res => {
                
                const { data } = res;
                // console.log(data);
    
                if(planta === 'met') {
                    datos_tablas = generarControlRiegoMET(data);;
                    if(inicio !== true) {
                        // console.log(data);
                        dispatch( setControlRiego({ datos_tablas, data }) )
                    }
                } else {
                    datos_tablas = generarControlRiegoOXE(data);
                
                    // console.log(datos_tablas);
                    if(inicio !== true) {
                        dispatch( setControlRiego({ datos_tablas, data }) )
                    }
                }
            });
            

        } catch( err ) {
            console.log(err);
            if( err.response.data === 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
                
            } else {
                console.log( err.response.data );
                return false;
            }
        }

        return datos_tablas;
    }

    const generarControlRiegoMET = (datos) => {
        // Obtener fechas
        const fechas = obtenerFechas(datos);

        // Obtener meses en lista de fechas
        const meses = obtenerMeses(fechas);

        // Obtener módulos
        const modulos = obtenerModulos(datos);

        // Generar filas
        const filas_agrupadas = generarFilasMET(datos, fechas, modulos, meses);
        const filas_con_total = sumarTotalMET(filas_agrupadas);
        
        return {
            modulos,
            meses,
            grupos: filas_con_total
        };
    }

    const obtenerFechas = (datos) => {
        let fechas = [];
        datos.forEach(modulo => {
            const { Turno_A } = modulo;

            Turno_A.forEach(({ Fecha }) => {
                if(!fechas.includes(Fecha)) {
                    fechas = [...fechas, Fecha];
                }
            });
        })

        fechas.sort();

        return fechas;
    }

    const obtenerMeses = (fechas) => {
        let meses = [];
        fechas.forEach(fecha => {
            const mes = obtenerMesFecha(fecha);

            if(!meses.includes(mes)) meses = [...meses, mes];
        });

        return meses;
    }

    const obtenerMesFecha = (fecha) => {
        const date = new Date(fecha);
        const anio = fecha.split("-")[0];
        const mes = fecha.split("-")[1];
        let mes_registro = '';

        switch(mes) {
            case '01':
                mes_registro = `Enero_${anio}`;
                break;

            case '02':
                mes_registro = `Febrero_${anio}`;
                break;

            case '03':
                mes_registro = `Marzo_${anio}`;
                break;

            case '04':
                mes_registro = `Abril_${anio}`;
                break;

            case '05':
                mes_registro = `Mayo_${anio}`;
                break;

            case '06':
                mes_registro = `Junio_${anio}`;
                break;

            case '07':
                mes_registro = `Julio_${anio}`;
                break;

            case '08':
                mes_registro = `Agosto_${anio}`;
                break;

            case '09':
                mes_registro = `Septiembre_${anio}`;
                break;

            case '10':
                mes_registro = `Octubre_${anio}`;
                break;

            case '11':
                mes_registro = `Noviembre_${anio}`;
                break;

            case '12':
                mes_registro = `Diciembre_${anio}`;
                break;
        }

        return mes_registro;
    }

    const obtenerModulos = (datos) => {
        let modulos = [];
        datos.forEach(({ nomenclatura }) => {
            if(nomenclatura !== null) {
                modulos = [...modulos, nomenclatura];
            }
        });

        modulos.sort();
        return modulos;
    }

    const generarFilasMET = (datos, fechas, modulos, meses) => {
        // Contenedor de todas las filas
        let filas_agrupadas = [];
        let filas_general = [];
        let filas_turno_a = []; 
        let filas_turno_b = [];

        // Generar filas por orden de fechas
        let mes_actual = meses[0];
        for(let i = 0; i < fechas.length; i++) {
            const fecha = fechas[i];

            // Contenedor de fila (celdas)
            let fila_general = [{
                modulo: false,
                valor: fecha,
                clases: 'cuerpo fecha'
            }];
            let fila_turno_a = [{
                modulo: false,
                valor: fecha,
                clases: 'cuerpo fecha'
            }];
            let fila_turno_b = [{
                modulo: false,
                valor: fecha,
                clases: 'cuerpo fecha'
            }];

            // Generar columnas por orden de módulos
            let color = false;
            let contador_modulos = 1;
            modulos.forEach(modulo => {
                color = contador_modulos % 2 === 0 ? true : false;
                contador_modulos += 1;
                let cont = 0;
                let encontro_a = false;
                let encontro_b = false;

                // Variables para calcular valor de ambos turnos
                let hora = 0;
                let presion = 0;
                let flujo = 0; // Flujo se conoce como caudal también
                let divisor_presion = 0;
                let divisor_flujo = 0;

                datos.forEach(({ nomenclatura, Turno_A, Turno_B }) => {
                    // De corresponder al módulo de la posición deberá registrar
                    if(nomenclatura === modulo) {
                        // Celda turno A
                        Turno_A.forEach(({ Fecha, Horas, Caudal, Presion }) => {

                            // Si corresponde a la fecha debe registrar
                            if(Fecha === fecha) {
                                hora += Horas;
                                presion += Presion;
                                flujo += Caudal;
                                encontro_a = true;

                                divisor_presion += Presion > 0 ? 1 : 0;
                                divisor_flujo += Caudal > 0 ? 1 : 0;

                                fila_turno_a = [...fila_turno_a, 
                                    { // Hora
                                        modulo,
                                        valor: Horas.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                    { // Presión
                                        modulo,
                                        valor: Presion.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                    { // Flujo
                                        modulo,
                                        valor: Caudal.toString(),
                                        clases: color ? 'color' : ''
                                    }
                                ];
                            } 
                        });

                        // Celda turno B
                        Turno_B.forEach(({ Fecha, Horas, Caudal, Presion }) => {
                            // Si corresponde a la fecha debe registrar
                            
                            if(Fecha === fecha) {
                                hora += Horas;
                                presion += Presion;
                                flujo += Caudal;
                                encontro_b = true;

                                divisor_presion += Presion > 0 ? 1 : 0;
                                divisor_flujo += Caudal > 0 ? 1 : 0;

                                fila_turno_b = [...fila_turno_b, 
                                    { // Hora
                                        modulo,
                                        valor: Horas.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                    { // Presión
                                        modulo,
                                        valor: Presion.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                    { // Flujo
                                        modulo,
                                        valor: Caudal.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                ]
                            }
                        });
                    }
                    cont += 1;
                });

                if(!encontro_a) {
                    fila_turno_a = [...fila_turno_a, 
                        { // Hora
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        },
                        { // Presión
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        },
                        { // Flujo
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        }
                    ]
                }

                if(!encontro_b) {
                    fila_turno_b = [...fila_turno_b, 
                        { // Hora
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        },
                        { // Presión
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        },
                        { // Flujo
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        }
                    ]
                }

                // Celda general
                divisor_presion = divisor_presion === 0 ? 1 : divisor_presion;
                divisor_flujo = divisor_flujo === 0 ? 1 : divisor_flujo;
                // console.log(`
                //     - Fecha: ${fecha},
                //     - Módulo: ${modulo},
                //     - Presion: ${presion}
                //     - Divisor: ${divisor_presion}
                // `);
                fila_general = [...fila_general, 
                    { // Hora
                        modulo,
                        valor: hora.toFixed(2).toString(),
                        clases: color ? 'color' : ''
                    },
                    { // Presión
                        modulo,
                        valor: presion <= 0 ? 0 : (presion / divisor_presion ).toFixed(2).toString(),
                        clases: color ? 'color' : ''
                    },
                    { // Flujo
                        modulo,
                        valor: flujo <= 0 ? 0 : (flujo / divisor_flujo ).toFixed(2).toString(),
                        clases: color ? 'color' : ''
                    },
                ]
            });

            // Agregar a lista de filas
            filas_general = [...filas_general, fila_general];
            filas_turno_a = [...filas_turno_a, fila_turno_a];
            filas_turno_b = [...filas_turno_b, fila_turno_b];
            
            // Si la siguiente fecha cambia de mes, generar grupo
            if(i < fechas.length-1) {
                const mes_futuro = obtenerMesFecha(fechas[i+1]);
                if(mes_futuro !== mes_actual) {
                    filas_agrupadas = [...filas_agrupadas, {
                        grupo: mes_actual,
                        filas_general,
                        filas_turno_a,
                        filas_turno_b
                    }]
    
                    filas_general = [];
                    filas_turno_a = []; 
                    filas_turno_b = [];
                } 

                mes_actual = mes_futuro;
            } else if(i === fechas.length-1) {
                filas_agrupadas = [...filas_agrupadas, {
                    grupo: mes_actual,
                    filas_general,
                    filas_turno_a,
                    filas_turno_b
                }]
            }   
        }

        return filas_agrupadas;
    }

    const sumarTotalMET = (filas_agrupadas) => {
        let grupos = [];
        filas_agrupadas.forEach(({ grupo, filas_general, filas_turno_a, filas_turno_b }) => {
            const total_turno_a = generarFilaTotal(filas_turno_a);
            const total_turno_b = generarFilaTotal(filas_turno_b);
            const total_general = generarFilaTotal(filas_general);
           
            grupos = [...grupos, {
                grupo,
                filas_general: [...filas_general, total_general],
                filas_turno_a: [...filas_turno_a, total_turno_a],
                filas_turno_b: [...filas_turno_b, total_turno_b]
            }]
        });

        return grupos;
    }

    const generarFilaTotal = (filas) => {
        let fila_total = [{
            clases: '',
            valor: 'TOTAL'
        }];

        // Obtener cantidad de columnas y filas
        const cant_celdas = filas[0].length;
        const cant_filas = filas.length;

        // Obtener valores fila + columna
        let id_pos = 1;
        for(let i = 1; i < cant_celdas; i++) {
            let total_col = 0;
            let divisor = 0;

            for(let h = 0; h < cant_filas; h++) {
                if(filas[h][i].valor !== '' && filas[h][i].valor > 0) {
                    divisor += 1;
                    total_col += parseFloat(filas[h][i].valor);
                }
            }

            divisor = divisor === 0 ? 1 : divisor;
            switch(id_pos) {
                case 2:
                    total_col = total_col / divisor;
                    break;

                case 3:
                    total_col = total_col / divisor;
                    id_pos = 0;
                    break;
            }


            fila_total = [...fila_total, {
                clases: '',
                valor: total_col.toFixed(2)
            }];
            id_pos += 1;
        }

        return fila_total;
    }

    const generarControlRiegoOXE = (datos) => {
        // Obtener fechas
        const fechas = obtenerFechas(datos);

        // Obtener meses en lista de fechas
        const meses = obtenerMeses(fechas);

        // Obtener módulos
        const modulos = obtenerModulos(datos);

        // Generar filas
        const filas_agrupadas = generarFilasOXE(datos, fechas, modulos, meses);

        const filas_con_total = sumarTotalOXE(filas_agrupadas);
        
        
        return {
            modulos,
            meses,
            grupos: filas_con_total
        };
        
    }

    const generarFilasOXE = ( datos, fechas, modulos, meses ) => {
        // Contenedor de todas las filas
        let filas_agrupadas = [];
        let filas_general = [];
        let filas_turno_a = []; 
        let filas_turno_b = [];

        // Generar filas por orden de fechas
        let mes_actual = meses[0];
        for(let i = 0; i < fechas.length; i++) {
            const fecha = fechas[i];

            // Contenedor de fila (celdas)
            let fila_general = [{
                modulo: false,
                valor: fecha,
                clases: 'cuerpo fecha'
            }];
            let fila_turno_a = [{
                modulo: false,
                valor: fecha,
                clases: 'cuerpo fecha'
            }];
            let fila_turno_b = [{
                modulo: false,
                valor: fecha,
                clases: 'cuerpo fecha'
            }];

            // Generar columnas por orden de módulos
            let contador_modulos = 1;
            let color = false;
            modulos.forEach(modulo => {
                color = contador_modulos % 2 === 0 ? true : false;
                contador_modulos += 1;
                let cont = 0;
                let encontro_a = false;
                let encontro_b = false;
                let divisor_flujo = 0;

                // Variables para calcular valor de ambos turnos
                let hora = 0;
                let flujo = 0; // Flujo se conoce como caudal también

                datos.forEach(({ nomenclatura, Turno_A, Turno_B }) => {
                    // De corresponder al módulo de la posición deberá registrar
                    if(nomenclatura === modulo) {
                        // Celda turno A
                        Turno_A.forEach(({ Fecha, Horas, Caudal }) => {
                            // Si corresponde a la fecha debe registrar
                            
                            if(Fecha === fecha) {
                                hora += Horas;
                                flujo += Caudal;
                                encontro_a = true;
                                divisor_flujo += Caudal > 0 ? 1 : 0;

                                fila_turno_a = [...fila_turno_a, 
                                    { // Hora
                                        modulo,
                                        valor: Horas.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                    { // Flujo
                                        modulo,
                                        valor: Caudal.toString(),
                                        clases: color ? 'color' : ''
                                    }
                                ]
                            } 

                        });

                        // Celda turno B
                        Turno_B.forEach(({ Fecha, Horas, Caudal }) => {
                            // Si corresponde a la fecha debe registrar
                            
                            if(Fecha === fecha) {
                                hora += Horas;
                                flujo += Caudal;
                                encontro_b = true;
                                divisor_flujo += Caudal > 0 ? 1 : 0;

                                fila_turno_b = [...fila_turno_b, 
                                    { // Hora
                                        modulo,
                                        valor: Horas.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                    { // Flujo
                                        modulo,
                                        valor: Caudal.toString(),
                                        clases: color ? 'color' : ''
                                    },
                                ]
                            }
                        });
                    }
                    cont += 1;
                });

                if(!encontro_a) {
                    fila_turno_a = [...fila_turno_a, 
                        { // Hora
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        },
                        { // Flujo
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        }
                    ]
                }

                if(!encontro_b) {
                    fila_turno_b = [...fila_turno_b, 
                        { // Hora
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        },
                        { // Flujo
                            modulo,
                            valor: '',
                            clases: color ? 'color' : ''
                        }
                    ]
                }

                // Celda general
                divisor_flujo = divisor_flujo === 0 ? 1 : divisor_flujo;
                fila_general = [...fila_general, 
                    { // Hora
                        modulo,
                        valor: hora.toFixed(2).toString(),
                        clases: color ? 'color' : ''
                    },
                    { // Flujo
                        modulo,
                        valor: (flujo / divisor_flujo).toFixed(2).toString(),
                        clases: color ? 'color' : ''
                    },
                ]
            });

            // Agregar a lista de filas
            filas_general = [...filas_general, fila_general];
            filas_turno_a = [...filas_turno_a, fila_turno_a];
            filas_turno_b = [...filas_turno_b, fila_turno_b];
            
            // Si la siguiente fecha cambia de mes, generar grupo
            if(i < fechas.length-1) {
                const mes_futuro = obtenerMesFecha(fechas[i+1]);
                if(mes_futuro !== mes_actual) {
                    filas_agrupadas = [...filas_agrupadas, {
                        grupo: mes_actual,
                        filas_general,
                        filas_turno_a,
                        filas_turno_b
                    }]
    
                    filas_general = [];
                    filas_turno_a = []; 
                    filas_turno_b = [];
                } 

                mes_actual = mes_futuro;
            } else if(i === fechas.length-1) {
                filas_agrupadas = [...filas_agrupadas, {
                    grupo: mes_actual,
                    filas_general,
                    filas_turno_a,
                    filas_turno_b
                }]
            }   
        }

        return filas_agrupadas;
    }

    const sumarTotalOXE = (filas_agrupadas) => {
        let grupos = [];
        filas_agrupadas.forEach(({ grupo, filas_general, filas_turno_a, filas_turno_b }) => {
            const total_turno_a = generarFilaTotalOXE(filas_turno_a);
            const total_turno_b = generarFilaTotalOXE(filas_turno_b);
            const total_general = generarFilaTotalOXE(filas_general);
           
            grupos = [...grupos, {
                grupo,
                filas_general: [...filas_general, total_general],
                filas_turno_a: [...filas_turno_a, total_turno_a],
                filas_turno_b: [...filas_turno_b, total_turno_b]
            }]
        });

        return grupos;
    }

    const generarFilaTotalOXE = (filas) => {
        let fila_total = [{
            clases: '',
            valor: 'TOTAL'
        }];

        // Obtener cantidad de columnas y filas
        const cant_celdas = filas[0].length;
        const cant_filas = filas.length;

        // Obtener valores filña + columna
        let id_pos = 1;
        for(let i = 1; i < cant_celdas; i++) {
            let total_col = 0;
            let divisor = 0;

            for(let h = 0; h < cant_filas; h++) {
                if(filas[h][i].valor !== '' && filas[h][i].valor !== '0' && filas[h][i].valor !== '0.00') {
                    divisor += 1;
                    total_col += parseFloat(filas[h][i].valor);
                }

            }

            if(id_pos === 2) {
                id_pos = 0;
                total_col = total_col / divisor;
            }


            fila_total = [...fila_total, {
                clases: `cuerpo `,
                valor: total_col.toFixed(2)
            }];
            id_pos += 1;
        }

        return fila_total;
    }

    const descargarControlRiego = (planta, grupo, modulos) => {
        const archivo = XLSX.utils.book_new();

        const hoja_general = hojaControlRiego(planta, 'general', grupo.filas_general, modulos); // envía turno y mes 
        const hoja_turno_A = hojaControlRiego(planta, 'A', grupo.filas_a, modulos); // envía turno y mes 
        const hoja_turno_B = hojaControlRiego(planta, 'B', grupo.filas_b, modulos); // envía turno y mes 

        XLSX.utils.book_append_sheet(archivo, hoja_general, `General`);
        XLSX.utils.book_append_sheet(archivo, hoja_turno_A, `Turno A`);
        XLSX.utils.book_append_sheet(archivo, hoja_turno_B, `Turno B`);

        XLSX.writeFile(archivo, `Control_de_riego_${ planta.toUpperCase() }.xlsx`, { cellDates: true, editable: true });
    }

    const hojaControlRiego = (planta, turno, filas, modulos) => {
        const cant_cols = planta === 'met' ? 2 : 1;
        const subcabeceras = planta === 'met' ? ['HORA', 'PRESIÓN', 'FLUJO'] : ['HORA', 'FLUJO'];
        let merges = [];
        
        const border = { 
            bottom: {
                style: "thin",
                color: {
                    rgb: 'FFFFFF'
                }
            },
            top: {
                style: "thin",
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: "thin",
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: "thin",
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }

        let es_cabecera = {
            font: {
                sz: 12,
                bold: true,
                color: {
                    rgb: 'ffffff'
                }
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            },
            fill: {
                fgColor: {
                    rgb: turno === 'general' ? '6f8e9e' : turno === 'A' ? '00838f' : 'c25838'
                }
            },
            border
        }
        let es_subcabecera = {
            font: {
                sz: 12,
                bold: true,
                color: {
                    rgb: 'ffffff'
                }
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            },
            fill: {
                fgColor: {
                    rgb: turno === 'general' ? '2566b4' : turno === 'A' ? '63b1b8' : 'e9a793'
                }
            },
            border
        };

        // CABECERA DE TABLA
        let cabecera = [{ v: '', t: 's', s: es_cabecera }];
        let subcabecera = [{ v: 'FECHA', t: 's', s: es_subcabecera }];
        let rows = [{ 'hpt': 23 }, { 'hpt': 23 }];

        let contador = 1;
        modulos.forEach(modulo => {
            merges = [...merges, {
                s: {
                    r: 0,
                    c: contador
                },
                e: {
                    r: 0,
                    c: contador + cant_cols
                }
            }]

            contador += cant_cols+1;

            // celda con valor
            cabecera = [...cabecera, { v: modulo, t: 's', s: es_cabecera }];

            // celdas vacias
            for(let i = 0; i < cant_cols; i++) {
                cabecera = [...cabecera, { v: '', t: 's', s: es_cabecera }];
            }

            // subcabecera
            subcabeceras.forEach(nombre => {
                subcabecera = [...subcabecera, { v: nombre, t: 's', s: es_subcabecera }];
            })
        });

        let hoja =  XLSX.utils.aoa_to_sheet([cabecera]);
        let origen = 1;
        XLSX.utils.sheet_add_aoa(hoja, [subcabecera], { origin: origen });
        origen += 1;


        // CUERPO DE TABLA
        const color_fecha = turno === 'general' ? 'b1c7e0' : turno === 'A' ? 'b3d9df' : 'ffccbc';
        const color_texto = turno === 'general' ? '01579b' : turno === 'A' ? '00838f' : 'c25838';

        for(let h = 0; h < filas.length; h++) {
            const fila = filas[h];
            let fila_xlsx = [];

            for(let i = 0; i < fila.length; i++) {
                const valor = fila[i].valor === 'NaN' ? '' : fila[i].valor;

                switch(true) {
                    case valor === 'TOTAL':
                        fila_xlsx = [...fila_xlsx,  {
                            v: valor,
                            t: 's',
                            s: {
                                font: {
                                    sz: 12,
                                    bold: i === 0 || h === filas.length-1 ? true : false,
                                    color: {
                                        rgb: i === 0 ? color_texto : '37474f'
                                    }
                                },
                                alignment: {
                                    vertical: "center",
                                    horizontal: "center"
                                },
                                fill: {
                                    fgColor: {
                                        rgb: i === 0 ? color_fecha : (fila[i].clases).includes('color') ? 'f2f2f2' : h % 2 === 0 ? 'f8f8f8' : 'ffffff'
                                    }
                                },
                                border
                            }
                        }];
                        break;

                    case valor.length === 10:
                        fila_xlsx = [...fila_xlsx,  {
                            v: valor,
                            t: 's',
                            s: {
                                font: {
                                    sz: 12,
                                    bold: i === 0 || h === filas.length-1 ? true : false,
                                    color: {
                                        rgb: i === 0 ? color_texto : '37474f'
                                    }
                                },
                                alignment: {
                                    vertical: "center",
                                    horizontal: "center"
                                },
                                fill: {
                                    fgColor: {
                                        rgb: i === 0 ? color_fecha : (fila[i].clases).includes('color') ? 'f2f2f2' : h % 2 === 0 ? 'f8f8f8' : 'ffffff'
                                    }
                                },
                                border
                            }
                        }];
                        break;

                    default: 
                        case valor.length === 10:
                        fila_xlsx = [...fila_xlsx,  {
                            v: valor,
                            t: 'n',
                            w: '0.00',
                            s: {
                                font: {
                                    sz: 12,
                                    bold: i === 0 || h === filas.length-1 ? true : false,
                                    color: {
                                        rgb: i === 0 ? color_texto : '37474f'
                                    }
                                },
                                alignment: {
                                    vertical: "center",
                                    horizontal: "center"
                                },
                                fill: {
                                    fgColor: {
                                        rgb: i === 0 ? color_fecha : (fila[i].clases).includes('color') ? 'f2f2f2' : h % 2 === 0 ? 'f8f8f8' : 'ffffff'
                                    }
                                },
                                border
                            }
                        }];
                        break;
                }
                
            }

            rows = [...rows, { 'hpt': 21 }];
            XLSX.utils.sheet_add_aoa(hoja, [fila_xlsx], { origin: origen });
            origen += 1;
        }

        let cols = [{ wch: 15 }];

        hoja['!merges'] = merges;
        hoja['!rows'] = rows;
        hoja['!cols'] = cols;


        return hoja;

    }

    return {
        obtenerControlRiegoAPI,
        descargarControlRiego
    }
}

export default useControlRiego;