import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import M from "materialize-css";

import { useResumenROM } from "../../hooks/useResumenROM";

import { Pestania } from "./components/Pestania";
import { Loader } from "../../components/Loader";
import { FilaSuperior } from "./components/FilaSuperior"
import { Graficos } from "./components/Graficos";
import { MsjError } from "../../components/MsjError";
import { ModalCarga } from "../../components/ModalCarga";

import './styles/ResumenROM.scss';
import './styles/tabs.scss';


export const ResumenProduccionROMPage = () => {
  const { obtenerROMAPI } = useResumenROM();
  const {planta} = useParams();

  const [GrafProduccion, setGrafProduccion] = useState({});
  const [GrafRefino, setGrafRefino] = useState({});
  const [ROM, setROM] = useState({})
  const [Ejecutado, setEjecutado] = useState(false);
  const [Cambio, setCambio] = useState(0);

  useEffect(() => {
    if( !Ejecutado ) {
      cargarDatosRom();
    }
  }, [])

  const cargarDatosRom = async() => {
    try {
      const { filas_turno_a, filas_turno_b, filas_resumen, 
      fechas, refino, produccion } = await obtenerROMAPI();

      // Tablas
      setROM({
        filas_turno_a,
        filas_turno_b,
        filas_resumen
      });

      // Gráficos
      setGrafProduccion({
        labels: fechas,
        data: produccion
      });

      setGrafRefino({
        labels: fechas,
        data: refino
      })

      setEjecutado( true );
    } catch ( err ) {
      setEjecutado( null )
    }
    
  }
  
  const cambiarVistaROM = ( event, contenedor ) => {
    // Botones
    document.getElementById("btn-rom-detalle").classList.remove("activo");
    document.getElementById("btn-rom-graficos").classList.remove("activo");
    event.target.classList.add("activo");

    // Contenedores
    document.getElementById("tab-rom-detalle").classList.remove("activo");
    document.getElementById("tab-rom-graficos").classList.remove("activo");
    document.getElementById(contenedor).classList.add("activo");   
  }

  const cambiarTablaROM = (contenedor, btn) => {
    
    const contenedores = ['tab-general-rom','tab-A-rom','tab-B-rom'];

    const btns = ['btn-rom-resumen','btn-rom-a','btn-rom-b'];

    contenedores.forEach(id => {
      if( id == contenedor){
        document.getElementById(id).classList.add('activo');
      } else {
        document.getElementById(id).classList.remove('activo');
      }
    });

    btns.forEach(id => {
      if(id == btn) {
        document.getElementById(id).classList.add('activo');
      } else {
        document.getElementById(id).classList.remove('activo');
      }
    });
    
  }

  return (
    <div className="contenido">
    {
      Ejecutado === false
      ?
        <div className="contenido">
          <div className="cont-loader">
              <Loader mensaje=""/>
          </div>
        </div>
      : Ejecutado == null
      ? <MsjError />
      :
        <>
          <div className="contenedor">
            <FilaSuperior cambio={ Cambio } planta={ planta } rom={ ROM } />
          </div>

          <ModalCarga />

          <div className="contenedor rom">
            <div className="cont-btns-tabs-rom">
              <button 
                className="btn btn-tab-rom waves-effect waves-light activo" 
                onClick={ ( event ) => { cambiarVistaROM( event, 'tab-rom-detalle' ) }}
                id="btn-rom-detalle"
              >
                <i className="material-icons left">table_chart</i>
                DETALLE
              </button>

              <button 
                className="btn btn-tab-rom waves-effect waves-light" 
                onClick={ ( event ) => { cambiarVistaROM( event, 'tab-rom-graficos' ) }}
                id="btn-rom-graficos"
              >
                <i className="material-icons left">show_chart</i>
                GRÁFICOS
              </button>
            </div>

            <div className="cont-tab-rom cont-detalle-rom activo" id="tab-rom-detalle">
              {/* sub tabs */}
              <div className="cont-btn-subtabs-rom">
                  <button id="btn-rom-resumen" className="btn waves-effect waves-light activo" onClick={() => { cambiarTablaROM('tab-general-rom','btn-rom-resumen')}}>RESUMEN</button>
                  <button id="btn-rom-a" className="btn waves-effect waves-light" onClick={() => { cambiarTablaROM('tab-A-rom','btn-rom-a')}}>TURNO A</button>
                  <button id="btn-rom-b" className="btn waves-effect waves-light" onClick={() => { cambiarTablaROM('tab-B-rom','btn-rom-b')}}>TURNO B</button>
              </div>
              
              <Pestania tipo="general" cambio={ Cambio } setCambio={ setCambio } rom={ ROM } tabla="filas_resumen" interfaz={ Ejecutado } id_tab="tab-general-rom" turno="general" datos={ ROM.filas_resumen } setROM={ setROM } clases="tab-rom activo" />
              <Pestania tipo="turno" cambio={ Cambio } setCambio={ setCambio } rom={ ROM } tabla="filas_turno_a" interfaz={ Ejecutado } id_tab="tab-A-rom" turno="A" datos={ ROM.filas_turno_a } setROM={ setROM } clases="tab-rom" />
              <Pestania tipo="turno" cambio={ Cambio } setCambio={ setCambio } rom={ ROM } tabla="filas_turno_b" interfaz={ Ejecutado } id_tab="tab-B-rom" turno="B" datos={ ROM.filas_turno_b } setROM={ setROM } clases="tab-rom" />
              
            </div>

            <div className="cont-tab-rom cont-graficos-rom" id="tab-rom-graficos">
              <Graficos datos_prod={ GrafProduccion } datos_ref={ GrafRefino } interfaz={ Ejecutado } />
            </div>
          </div>
        </>
    }
    </div>
  )
}
