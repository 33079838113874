import '../styles/modal_var.scss'
import { useEffect } from 'react';
import { useState } from 'react';

import { Nomina } from './descarga/Nomina';
import { TablaSimple } from './descarga/TablaSimple';
import { Loader } from '../../../components/Loader';

import '../styles/modal_dl.scss';

export const ModalDescarga = ( { cambios, setCambios, produccion, sx, ewsx, lixiviacion, interfaz } ) => {
    return (
        <div id="modal-captura"  className="modal captura" >
            <div className="modal-content">
                <div className="carga-captura">
                        <Loader mensaje={"Generando descarga..."}/>
                </div>

                <div id="captura-cosecha" className="captura">
                    <div className="capt-produccion">
                        <Nomina 
                            id_tab="tab-informe-nomina-mensual"
                            datos={ produccion.informe_mensual.nomina } interfaz={ interfaz }
                        />


                        <Nomina 
                            id_tab="tab-informe-nomina-anual"
                            datos={ produccion.informe_anual.nomina } interfaz={ interfaz }
                        />
                    </div>

                    <div className="capt-sx">
                        <div>
                            <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_elec_pobre.cabeceras } filas={ sx.tabla_elec_pobre.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_elec_pobre' }/>

                            <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_laboratorio.cabeceras } filas={ sx.tabla_laboratorio.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_laboratorio' }/>

                            <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_turnos.cabeceras } filas={ sx.tabla_turnos.filas } claseTabla={ 'tabla-flujos turnos colores-turnos' } id_tabla={ 'tabla_turnos' }/>
                        
                            <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_hora.cabeceras } filas={ sx.tabla_hora.filas } claseTabla={ 'tabla-flujos colores-turnos' } id_tabla={ 'tabla_hora' }/>
                        </div>

                        <div>
                            <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_arrastre.cabeceras } filas={ sx.tabla_arrastre.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_arrastre' }/>

                            <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_analisis.cabeceras } filas={ sx.tabla_analisis.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_analisis' }/>
                        </div>

                        <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_turno_a.cabeceras } filas={ sx.tabla_turno_a.filas } claseTabla={ 'tabla-flujos turnoa ' } id_tabla={ 'tabla_turno_a' }/>
            
                        <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_turno_b.cabeceras } filas={ sx.tabla_turno_b.filas } claseTabla={ 'tabla-flujos turnob' } id_tabla={ 'tabla_turno_b' }/>
                    </div>

                    <div className="sx-flujos">
                        <TablaSimple vista="sx" datos={ sx } cabecera={ sx.tabla_flujos.cabeceras } filas={ sx.tabla_flujos.filas } claseTabla={ 'tabla-flujos flujos' } id_tabla={ 'tabla_flujos' } />
                    </div>

                    <div className="capt-ewsx">
                        <TablaSimple cabecera={ ewsx.tabla_eficiencia_extraccion.cabeceras } filas={ ewsx.tabla_eficiencia_extraccion.filas } claseTabla='tabla-ewsx eficiencia simple tabla-ewsx-ef-ext' id_tabla='tabla-ewsx-ef-ext' />
                        
                        <div className="ewsx1">
                            <div>
                                <TablaSimple cabecera={ ewsx.tabla_cte_teorica.cabeceras } filas={ ewsx.tabla_cte_teorica.filas } claseTabla='tabla-ewsx simple teorica ' id_tabla='tabla-ewsx-teorica' />
                                <TablaSimple cabecera = { ewsx.tabla_lvl_tks.cabeceras} filas = { ewsx.tabla_lvl_tks.filas } claseTabla='tabla-fila' id_tabla='tabla-ewsx-tks'/>
                                <TablaSimple cabecera = { ewsx.tabla_prod_planta.cabeceras} filas = { ewsx.tabla_prod_planta.filas } claseTabla='tabla-fila' id_tabla='tabla-ewsx-prod-planta'/>
                                <TablaSimple cabecera={ ewsx.tabla_extractores.cabeceras } filas={ ewsx.tabla_extractores.filas } claseTabla='tabla-ewsx simple extractores ' id_tabla='tabla-ewsx-ext' />
                            </div>

                            <TablaSimple cabecera={ ewsx.tabla_turnos.cabeceras } filas={ ewsx.tabla_turnos.filas } claseTabla='tabla-ewsx simple colores-turnos turnos-ewsx' id_tabla='tabla-ewsx-turnos'/>
                        </div>  
                    </div>

                    <div className="capt-lix">
                        <TablaSimple cabecera={ lixiviacion.tabla_lix.cabeceras } filas={ lixiviacion.tabla_lix.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_lix'/>

                        <div>
                            <TablaSimple cabecera={ lixiviacion.tabla_acido.cabeceras } filas={ lixiviacion.tabla_acido.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_acido'/>
                            <TablaSimple cabecera={ lixiviacion.tabla_lvl_agua.cabeceras } filas={ lixiviacion.tabla_lvl_agua.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_lvl_agua'/>
                            <TablaSimple cabecera={ lixiviacion.tabla_agua_fresca.cabeceras } filas={ lixiviacion.tabla_agua_fresca.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_agua_fresca'/>
                        </div>

                        <TablaSimple cabecera={ lixiviacion.tabla_flujos.cabeceras } filas={ lixiviacion.tabla_flujos.filas } claseTabla='tabla-ewsx colores-turnos simple' id_tabla='tabla_flujos'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
