import './styles/Autentificacion.scss';
import './styles/Animaciones.scss';
import './styles/Autentificacion_responsive.scss';

import { CuadroLogin } from "./components/CuadroLogin"


export const AutentificarPage = () => {
  return (
    <>
      <div className="contenedor-autentificacion">
        <div className="cont-login">
          

          <div className="img-autentificacion">
          </div>

          <CuadroLogin />
        </div>
      </div>
    </>
  )
}
