import { useEffect, useState } from "react";
import { useAnalisisQuimico } from "../../../hooks/useAnalisisQuimico"
import { ModalCarga } from "../../../components/ModalCarga";
import M from "materialize-css";


export const FilaSuperior = ( { datos, setDatos } ) => {
  const moment = require('moment');
  const { descargarAnalisisQuimico, obtenerAnalisisQuimicoFechaAPI, obtenerAnalisisQuimicoAPI } = useAnalisisQuimico();
  const [Interfaz, setInterfaz] = useState(false);
  const [Fechas, setFechas] = useState({ hoy: '', max: '', min: '' })
  const [FechaInput, setFechaInput] = useState('');
  const [Dis, setDis] = useState(false);

  useEffect(() => {
    if( !Interfaz ) {
      setInterfaz( false );
      setFechas({
        max: moment().subtract(1, "days").format('YYYY-MM-DD'),
        min: moment('2023-01-01').format( 'YYYY-DD-MM' ),
      });

      setFechaInput( moment().subtract(1, "days").format('YYYY-MM-DD') );
    }
  }, []);

  const obtenerAQFecha = async( input ) => {
    const valor = input.value;
    setDis( true );


    const modal = document.getElementById('modal-carga');
    const ins = M.Modal.init(modal);
    ins.open();

    setFechaInput( valor )
    const valores = await obtenerAnalisisQuimicoFechaAPI( valor );
    
    setDatos( valores );
    ins.close();
    

    setDis( false );
  }
  

  return (
    <div className="fila-superior">
      <div className="cont-titulo-aq">
        <h3 className="titulo-pagina">
            ANÁLISIS QUÍMICO
        </h3>
        <input 
          type="date" 
          id="input-fecha-aq" 
          disabled={ Dis }
          min={ Fechas.min }
          max={ Fechas.max }
          value={ FechaInput } 
          onChange={ ( e ) => { obtenerAQFecha( e.target ) } }
        />  

        <ModalCarga msj="Obteniendo datos..."/>

      </div>

        <div className="cont-btns">
            {/* Exportar datos de tablas */}
            <button className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar modal-trigger" onClick={ () => { descargarAnalisisQuimico( datos ) } }>
              <i className="material-icons left">download</i>
                DESCARGAR
            </button>
        </div> 
    </div>
  )
}
