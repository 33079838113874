import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import M from "materialize-css";

export const descargaCosecha = () => {
    const cabecera_gris = {
        font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'ffffff'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: '607d8b'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    };

    const cabecera_azul = {
        font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'ffffff'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: '01579b'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const cabecera_turno_a = {
        font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: '006064'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'b3d9df'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    };

    const cabecera_turno_b = {
        font: {
            sz: 12, // tamaño
            bold: true, // grosor
            color: {
                rgb: 'bf360c'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'ffccbc'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    };

    const nom_celda_azul_par = {
        font: {
            sz: 11, // tamaño
            bold: true, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'd2e2ed'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    };

    const nom_celda_azul_impar = {
        font: {
            sz: 11, // tamaño
            bold: true, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'bed4e6'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    };

    const celda_gris_par = {
        font: {
            sz: 11, // tamaño
            bold: true, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'f7f7f7'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_gris_impar = {
        font: {
            sz: 11, // tamaño
            bold: true, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'f2f2f2'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_gris = {
        font: {
            sz: 11, // tamaño
            bold: false, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'f7f7f7'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_turno_a = {
        font: {
            sz: 11, // tamaño
            bold: false, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'd9ecef'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_a = {
        font: {
            sz: 11, // tamaño
            bold: true, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'e8f4f5'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_b = {
        font: {
            sz: 11, // tamaño
            bold: true, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'ffefea'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_turno_b = {
        font: {
            sz: 11, // tamaño
            bold: false, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'ffe5de'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const celda_bloqueada = {
        font: {
            sz: 11, // tamaño
            bold: false, // grosor
            color: {
                rgb: '37474f'  // color de letra
            }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true
        },
        fill: {
            fgColor: {
                rgb: 'dce2e6'
            }
        },
        border: {
            top: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            bottom: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            right: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            },
            left: {
                style: 'thin',
                color: {
                    rgb: 'FFFFFF'
                }
            }
        }
    }

    const XLSX = require("xlsx-js-style");
    const moment = require('moment');

    const descargarPDF = async(produccion, sx, ewsx, lixiviacion) => {
        // Mostrar gráficos
        document.getElementById("btn-cosecha-produccion").click();
        document.getElementById("btn-informe-grafico-anual").click();
        document.getElementById("btn-informe-grafico-mensual").click();
        

        setTimeout(async() => {
            // Documento PDF
            const pdf = new jsPDF();
    
            // Título de documento
            pdf.setFontSize(16).setFont(undefined, 'bold').setTextColor(1, 87, 155);
            pdf.text('INFORME COSECHA', 77, 15);
    
            // ================================================================= PRODUCCIÓN
            // MENSUAL
            pdf.setFontSize(14).setFont(undefined, 'bold').setTextColor(1, 87, 155);
            pdf.text('1. PRODUCCIÓN', 12, 25);
    
            pdf.setFontSize(12).setFont(undefined, 'bold').setTextColor(55, 71, 79)
            pdf.text('1.1. Informe mensual Julio 2023', 17, 32);
    
            // Gráfico mensual
            let elemento = document.getElementById("graf-prod-mensual");
            let canvas  = await html2canvas(elemento);
            let img = canvas.toDataURL('image/png');
            pdf.addImage(img, 'JPEG', 19, 34, 179, 35);
    
            // Nómina mensual
            const { cabecera_nom_mensual, cuerpo_nom_mensual } = generarNominaMensual( produccion );
            pdf.autoTable({
                margin: { 
                    top: 72,
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.3,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.3,
                    lineColor: [255,255,255]
                },
                head: cabecera_nom_mensual,
                body: cuerpo_nom_mensual
            });
    
            // ANUAL
            pdf.addPage();
            pdf.setFontSize(12).setFont(undefined, 'bold').setTextColor(55, 71, 79)
            pdf.text('1.2. Informe anual', 17, 15);
    
            elemento = document.getElementById("graf-prod-anual");
            canvas  = await html2canvas(elemento);
            img = canvas.toDataURL('image/png');
            pdf.addImage(img, 'JPEG', 19, 20, 179, 35);
            
            const { cabecera_nom_anual, cuerpo_nom_anual } = generarNominaAnual( produccion );
            pdf.autoTable({
                startY: 60,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.3,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.3,
                    lineColor: [255,255,255]
                },
                head: cabecera_nom_anual,
                body: cuerpo_nom_anual
            });
    
            // ================================================================= SX
            pdf.addPage();
            pdf.setFontSize(14).setFont(undefined, 'bold').setTextColor(1, 87, 155);
            pdf.text('2. SX', 12, 15);

            const  { tabla_analisis, tabla_arrastre, tabla_elec_pobre, tabla_flujos, tabla_hora,
            tabla_laboratorio, tabla_turno_a, tabla_turno_b, tabla_turnos } = sx;
            
            const estilo_cabecera = { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: '607d8b', fontStyle: 'bold', fontSize: 7 };
            const estilo_sub_cabecera = { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: '01579b', fontStyle: 'bold', fontSize: 7 };
            const estilo_cabecera_a = { valign: 'middle', halign: 'center', textColor: '006064', fillColor: 'b3d9df', fontStyle: 'bold', fontSize: 7 };
            const estilo_cabecera_b = { valign: 'middle', halign: 'center', textColor: 'bf360c', fillColor: 'ffccbc', fontStyle: 'bold', fontSize: 7 };
            
            const estilo_nom_fila = { cellWidth: 30, valign: 'middle', halign: 'left', textColor: '37474f', fillColor: 'f2f2f2', fontStyle: 'bold', fontSize: 7 };
            const estilo_nom_azul = { cellWidth: 30, valign: 'middle', halign: 'left', textColor: '37474f', fillColor: 'd2e2ed', fontStyle: 'bold', fontSize: 7 };
            const estilo_nom_fila_a = { cellWidth: 30, valign: 'middle', halign: 'left', textColor: '37474f', fillColor: 'e8f4f5', fontStyle: 'bold', fontSize: 7 };
            const estilo_nom_fila_b = { cellWidth: 30, valign: 'middle', halign: 'left', textColor: '37474f', fillColor: 'ffefea', fontStyle: 'bold', fontSize: 7 };

            const estilo_celda = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'f7f7f7', fontSize: 7 };
            const estilo_no_celda = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'dce2e6', fontSize: 7 };
            const estilo_celda_a = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'd9ecef', fontSize: 7 };
            const estilo_celda_b = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'ffe5de', fontSize: 7 };
            
            // Elec. pobre
            pdf.autoTable({
                tableWidth: 82,
                startY: 18,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'ELEC. POBRE', styles: estilo_cabecera },
                        { content: '18:00', styles: estilo_cabecera },
                        { content: '06:00', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'Cu++', styles: estilo_nom_fila },
                        { content: tabla_elec_pobre.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_elec_pobre.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'H++', styles: estilo_nom_fila },
                        { content: tabla_elec_pobre.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_elec_pobre.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Balance', styles: estilo_nom_fila },
                        { content: tabla_elec_pobre.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_elec_pobre.filas[2][2].valor, styles: estilo_celda_b },
                    ]
                ]
            });

            // Laboratorio
            pdf.autoTable({
                tableWidth: 82,
                startY: 45,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'LABORATORIO', styles: estilo_cabecera },
                        { content: '18:00', styles: estilo_cabecera },
                        { content: '06:00', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'PLS', styles: estilo_nom_fila },
                        { content: tabla_laboratorio.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_laboratorio.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Nivel PLS', styles: estilo_nom_fila },
                        { content: tabla_laboratorio.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_laboratorio.filas[1][2].valor, styles: estilo_celda_b },
                    ]
                ]
            });

            // EP a TK - 102
            pdf.autoTable({
                tableWidth: 82,
                startY: 65.5,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: '', styles: estilo_cabecera },
                        { content: 'TURNO A', styles: estilo_cabecera },
                        { content: 'TURNO B', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'EP a TK-102', styles: estilo_nom_fila },
                        { content: tabla_turnos.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'OTROS DESC EP', styles: estilo_nom_fila },
                        { content: tabla_turnos.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'TOTAL DESC EP', styles: estilo_nom_fila },
                        { content: tabla_turnos.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos.filas[2][2].valor, styles: estilo_celda_b },
                    ]
                ]
            });

            // Tabla hora (transferencias)
            pdf.autoTable({
                tableWidth: 95,
                startY: 18,
                margin: { 
                    left: 105,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'HORA', styles: estilo_cabecera },
                        { content: '09:00', styles: estilo_cabecera },
                        { content: '21:00', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'Transf. tren A', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Transf. tren B', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Transf. RAL a SX', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[2][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Transf. RAL a Ref', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[3][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[3][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Ef. tren A (E1-A)', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[4][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[4][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Ef. tren B (E1-B)', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[5][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[5][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Ef. tren A (E2-A)', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[6][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[6][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Ef. tren B (E2-B)', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[7][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[7][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'T.N."A" = gpl/%v/v', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[8][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[8][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'T.N."B" = gpl/%v/v', styles: estilo_nom_fila },
                        { content: tabla_hora.filas[8][1].valor, styles: estilo_celda_a },
                        { content: tabla_hora.filas[8][2].valor, styles: estilo_celda_b },
                    ]
                ]
            });

            // Tabla turno A
            pdf.autoTable({
                tableWidth: 'auto',
                startY: 95,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'HORA', styles: estilo_cabecera },
                        { content: 'TREN A', colSpan: 3, styles: estilo_cabecera },
                        { content: 'TREN B', colSpan: 3, styles: estilo_cabecera }
                    ],
                    [
                        { content: '09:00 HRS', styles: estilo_cabecera_a },
                        { content: 'Cu++', styles: estilo_cabecera_a },
                        { content: 'H++', styles: estilo_cabecera_a },
                        { content: 'pH', styles: estilo_cabecera_a },
                        { content: 'Cu++', styles: estilo_cabecera_a },
                        { content: 'H++', styles: estilo_cabecera_a },
                        { content: 'pH', styles: estilo_cabecera_a }
                    ]
                ],
                body: [
                    [
                        { content: 'PLS', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[0][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[0][2].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[0][3].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[0][4].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[0][5].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[0][6].valor, styles: estilo_celda }
                    ],
                    [
                        { content: 'REF E-2', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[1][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[1][2].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[1][3].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[1][4].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[1][5].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[1][6].valor, styles: estilo_celda }
                    ],
                    [
                        { content: 'REF E-3', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[2][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[2][2].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[2][3].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[2][4].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[2][5].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[2][6].valor, styles: estilo_celda }
                    ],
                    [
                        { content: 'REF LIX', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[3][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[3][2].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[3][3].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda }
                    ],
                    [
                        { content: 'EP', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[4][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[4][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda }
                    ],
                    [
                        { content: 'ECC', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[5][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[5][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda }
                    ],
                    [
                        { content: 'ER', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[6][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[6][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: tabla_turno_a.filas[6][4].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[6][5].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                    ],
                    [
                        { content: 'RAL W-1', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[7][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[7][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: tabla_turno_a.filas[7][4].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[7][5].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                    ],
                    [
                        { content: 'RAL W-2', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[8][1].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[8][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: tabla_turno_a.filas[8][4].valor, styles: estilo_celda },
                        { content: tabla_turno_a.filas[8][5].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                    ],
                    [
                        { content: 'Balance ácido EP', styles: estilo_nom_fila_a },
                        { content: tabla_turno_a.filas[9][1].valor, styles: estilo_celda }
                    ]
                ]
            });

            // Tabla turno B
            pdf.autoTable({
                tableWidth: 'auto',
                startY: 175,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'HORA', styles: estilo_cabecera },
                        { content: 'TREN A', colSpan: 3, styles: estilo_cabecera },
                        { content: 'TREN B', colSpan: 3, styles: estilo_cabecera }
                    ],
                    [
                        { content: '21:00 HRS', styles: estilo_cabecera_b },
                        { content: 'Cu++', styles: estilo_cabecera_b },
                        { content: 'H++', styles: estilo_cabecera_b },
                        { content: 'pH', styles: estilo_cabecera_b },
                        { content: 'Cu++', styles: estilo_cabecera_b },
                        { content: 'H++', styles: estilo_cabecera_b },
                        { content: 'pH', styles: estilo_cabecera_b }
                    ]
                ],
                body: [
                    [
                        { content: 'PLS', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[0][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[0][2].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[0][3].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[0][4].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[0][5].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[0][6].valor, styles: estilo_celda }
                    ],
                    [
                        { content: 'REF E-2', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[1][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[1][2].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[1][3].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[1][4].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[1][5].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[1][6].valor, styles: estilo_celda }
                    ],
                    [
                        { content: 'REF E-3', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[2][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[2][2].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[2][3].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[2][4].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[2][5].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[2][6].valor, styles: estilo_celda }
                    ],
                    [
                        { content: 'REF LIX', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[3][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[3][2].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[3][3].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda }
                    ],
                    [
                        { content: 'EP', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[4][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[4][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda }
                    ],
                    [
                        { content: 'ECC', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[5][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[5][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: '', styles: estilo_no_celda }
                    ],
                    [
                        { content: 'ER', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[6][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[6][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: tabla_turno_b.filas[6][4].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[6][5].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                    ],
                    [
                        { content: 'RAL W-1', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[7][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[7][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: tabla_turno_b.filas[7][4].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[7][5].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                    ],
                    [
                        { content: 'RAL W-2', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[8][1].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[8][2].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                        { content: tabla_turno_b.filas[8][4].valor, styles: estilo_celda },
                        { content: tabla_turno_b.filas[8][5].valor, styles: estilo_celda },
                        { content: '', styles: estilo_no_celda },
                    ],
                    [
                        { content: 'Balance ácido EP', styles: estilo_nom_fila_b },
                        { content: tabla_turno_b.filas[9][1].valor, styles: estilo_celda }
                    ]
                ]
            });

            // Tabla arrastre
            pdf.addPage();
            pdf.autoTable({
                tableWidth: 90,
                startY: 18,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'ARRAST. ORG', styles: estilo_cabecera },
                        { content: '10:00', styles: estilo_cabecera },
                        { content: '22:00', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'EP', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'ECC', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'ECL', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[2][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'S1A', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[3][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[3][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'S1B', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[4][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[4][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'E2A', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[5][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[5][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'E2B', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[6][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[6][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'E3A', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[7][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[7][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'E3B', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[8][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[8][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'REF. P', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[9][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[9][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'OUT. FL. FRAL.', styles: estilo_nom_fila },
                        { content: tabla_arrastre.filas[10][1].valor, styles: estilo_celda_a },
                        { content: tabla_arrastre.filas[10][2].valor, styles: estilo_celda_b },
                    ],
                ]
            });

            // Tabla análisis
            pdf.autoTable({
                tableWidth: 90,
                startY: 18,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'ARRAST. ORG', styles: estilo_cabecera },
                        { content: '09:00', styles: estilo_cabecera },
                        { content: '21:00', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'ER-A', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'ER-B', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'RAL W-1 A', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[2][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'RAL W-1 B', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[3][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[3][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'RAL W-2 A', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[4][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[4][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'RAL W-2 B', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[5][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[5][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'ECC', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[6][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[6][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'EP', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[7][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[7][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'PLS', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[8][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[8][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'TOTAL', styles: estilo_nom_fila },
                        { content: tabla_analisis.filas[9][1].valor, styles: estilo_celda_a },
                        { content: tabla_analisis.filas[9][2].valor, styles: estilo_celda_b },
                    ]
                ]
            });

            // Tabla flujos
            pdf.autoTable({
                tableWidth: 'auto',
                startY: 100,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: '', styles: estilo_cabecera },
                        { content: 'TURNO A', colSpan: 3, styles: estilo_cabecera },
                        { content: 'TURNO B', colSpan: 3, styles: estilo_cabecera }
                    ],
                    [
                        { content: 'FLUJOS', styles: estilo_sub_cabecera },
                        { content: 'TREN-A', styles: estilo_sub_cabecera },
                        { content: 'PLS SX', styles: estilo_sub_cabecera },
                        { content: 'TREN-B', styles: estilo_sub_cabecera },
                        { content: 'TREN-A', styles: estilo_sub_cabecera },
                        { content: 'PLS SX', styles: estilo_sub_cabecera },
                        { content: 'TREN-B', styles: estilo_sub_cabecera },
                    ]
                ],
                body: [
                    [
                        { content: 'PLS E-1', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[0][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: tabla_flujos.filas[0][2].valor, rowSpan: 2, styles: estilo_celda_a }, // PLS SX
                        { content: tabla_flujos.filas[0][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[0][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: tabla_flujos.filas[0][5].valor, rowSpan: 2, styles: estilo_celda_b }, // PLS SX
                        { content: tabla_flujos.filas[0][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'PLS E-2', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[1][1].valor, styles: estilo_celda_a }, // TREN A
                        // { content: tabla_flujos.filas[1][2].valor, styles: estilo_celda_a }, // PLS SX
                        { content: tabla_flujos.filas[1][2].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[1][3].valor, styles: estilo_celda_b }, // TREN A
                        // { content: tabla_flujos.filas[1][5].valor, styles: estilo_celda_b }, // PLS SX
                        { content: tabla_flujos.filas[1][4].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'ORG. Cargado', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[2][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[2][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[2][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[2][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'EP', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[3][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[3][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[3][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[3][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'ECC', styles: estilo_nom_fila },
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[4][2].valor, styles: estilo_celda_a }, // PLS SX
                        { content: '', styles: estilo_no_celda }, // TREN B
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[4][5].valor, styles: estilo_celda_b }, // PLS SX
                        { content: '', styles: estilo_no_celda } // TREN B
                    ],
                    [
                        { content: 'ECS', styles: estilo_nom_fila },
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[5][2].valor, styles: estilo_celda_a }, // PLS SX
                        { content: '', styles: estilo_no_celda }, // TREN B
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[5][5].valor, styles: estilo_celda_b }, // PLS SX
                        { content: '', styles: estilo_no_celda } // TREN B
                    ],
                    [
                        { content: 'Ref. a Lix MCO', styles: estilo_nom_fila },
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[6][2].valor, styles: estilo_celda_a }, // PLS SX
                        { content: '', styles: estilo_no_celda }, // TREN B
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[6][5].valor, styles: estilo_celda_b }, // PLS SX
                        { content: '', styles: estilo_no_celda } // TREN B
                    ],
                    [
                        { content: 'Ref. a Lix OXE', styles: estilo_nom_fila },
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[7][2].valor, styles: estilo_celda_a }, // PLS SX
                        { content: '', styles: estilo_no_celda }, // TREN B
                        { content: '', styles: estilo_no_celda }, // TREN A
                        { content: tabla_flujos.filas[7][5].valor, styles: estilo_celda_b }, // PLS SX
                        { content: '', styles: estilo_no_celda } // TREN B
                    ],
                    [
                        { content: 'Desc. EP', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[8][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[8][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[8][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[8][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'Desc. RAL a refino', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[9][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[9][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[9][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[9][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'Desc. RAL a E-1', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[10][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[10][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[10][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[10][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                    [
                        { content: 'Desc. RAL a TK-102', styles: estilo_nom_fila },
                        { content: tabla_flujos.filas[11][1].valor, styles: estilo_celda_a }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[11][3].valor, styles: estilo_celda_a }, // TREN B
                        { content: tabla_flujos.filas[11][4].valor, styles: estilo_celda_b }, // TREN A
                        { content: '', styles: estilo_no_celda }, // PLS SX
                        { content: tabla_flujos.filas[11][6].valor, styles: estilo_celda_b } // TREN B
                    ],
                ]
            });
            
            // ================================================================= EW SX
            pdf.addPage();
            pdf.setFontSize(14).setFont(undefined, 'bold').setTextColor(1, 87, 155);
            pdf.text('3. EW - SX', 12, 15);

            const { tabla_turnos:tabla_turnos_ewsx, tabla_eficiencia_extraccion, tabla_eficiencia_reextracción,
            tabla_extractores, tabla_cte_teorica, tabla_lvl_tks, tabla_prod_planta } = ewsx;
            
            // Tabla turnos EWSX
            pdf.autoTable({
                tableWidth: 90,
                startY: 18,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: '', styles: estilo_cabecera },
                        { content: 'TURNO A', styles: estilo_cabecera },
                        { content: 'TURNO B', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'Transferencia total', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Cte. teórica', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Cte. real promedio', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[2][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Depósito real', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[3][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[3][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Descarte RAL', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[4][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[4][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Corte SX global', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[5][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[5][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: 'Cte. teórica SX', styles: estilo_nom_fila },
                        { content: tabla_turnos_ewsx.filas[6][1].valor, styles: estilo_celda_a },
                        { content: tabla_turnos_ewsx.filas[6][2].valor, styles: estilo_celda_b },
                    ],
                ]
            });

            // Tabla extractores
            pdf.autoTable({
                tableWidth: 88,
                startY: 18,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'EXTRACTORES FUERA DE SERVICIO', colSpan: 2, styles: estilo_cabecera },
                    ]
                ],
                body: [
                    [
                        { content: 'Sector norte', styles: estilo_nom_azul },
                        { content: tabla_extractores.filas[0][1].valor, styles: estilo_celda },
                    ],
                    [
                        { content: 'Sector sur', styles: estilo_nom_azul },
                        { content: tabla_extractores.filas[1][1].valor, styles: estilo_celda },
                    ],
                ]
            });

            // Tabla cte teórico
            pdf.autoTable({
                tableWidth: 88,
                startY: 38,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'CTE. TEÓRICA', colSpan: 2, styles: estilo_cabecera },
                    ]
                ],
                body: [
                    [
                        { content: 'Puntual 18:00', styles: estilo_nom_azul },
                        { content: tabla_cte_teorica.filas[0][1].valor, styles: estilo_celda },
                    ],
                    [
                        { content: 'Puntual 06:00', styles: estilo_nom_azul },
                        { content: tabla_cte_teorica.filas[1][1].valor, styles: estilo_celda },
                    ],
                ]
            });

            // Tabla Eficiencia extracción
            pdf.autoTable({
                tableWidth: 'auto',
                startY: 75,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'EFICIENCIA EXTRACCIÓN', styles: { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: '607d8b', fontStyle: 'bold', fontSize: 7 } },
                        { content: '09:00', styles: estilo_cabecera },
                        { content: '21:00', styles: estilo_cabecera },
                        { content: 'EFICIENCIA REEXTRACCIÓN', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: 'Tren A', styles: { cellWidth: 60, valign: 'middle', halign: 'left', textColor: '37474f', fillColor: 'd2e2ed', fontStyle: 'bold', fontSize: 7 } },
                        { content: tabla_eficiencia_extraccion.filas[0][1].valor, styles: estilo_celda },
                        { content: tabla_eficiencia_extraccion.filas[0][2].valor, styles: estilo_celda },
                        { content: tabla_eficiencia_extraccion.filas[0][3].valor, styles:  { cellWidth: 50, valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'f7f7f7', fontSize: 7 } }
                    ],
                    [
                        { content: 'Tren A', styles: { cellWidth: 60, valign: 'middle', halign: 'left', textColor: '37474f', fillColor: 'd2e2ed', fontStyle: 'bold', fontSize: 7 } },
                        { content: tabla_eficiencia_extraccion.filas[1][1].valor, styles: estilo_celda },
                        { content: tabla_eficiencia_extraccion.filas[1][2].valor, styles: estilo_celda },
                        { content: tabla_eficiencia_extraccion.filas[1][3].valor, styles:  { cellWidth: 50, valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'f7f7f7', fontSize: 7 } }
                    ],
                ]
            });

            // Tabla Nivel TKS
            pdf.autoTable({
                tableWidth: 90,
                startY: 100,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                body: [
                    [
                        { content: 'Nivel TKS acumuladores P.T.S.', styles: estilo_sub_cabecera },
                        { content: tabla_lvl_tks.filas[0][1].valor, styles: estilo_celda },
                    ],
                ]
            });

            // Tabla producción
            pdf.autoTable({
                tableWidth: 90,
                startY: 100,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                body: [
                    [
                        { content: 'Nivel TKS acumuladores P.T.S.', styles: estilo_sub_cabecera },
                        { content: tabla_prod_planta.filas[0][1].valor, styles: estilo_celda },
                    ],
                ]
            });

            // ================================================================= Lixiviación
            const { tabla_lix, tabla_acido, tabla_lvl_agua, tabla_agua_fresca, tabla_flujos:tabla_flujos_lix } = lixiviacion;
            pdf.addPage();
            pdf.setFontSize(14).setFont(undefined, 'bold').setTextColor(1, 87, 155);
            pdf.text('4. Lixiviación', 12, 15);

            // Tabla datos lixiviación
            pdf.autoTable({
                tableWidth: 90,
                startY: 18,
                margin: { 
                    left: 19,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'DATOS DE LIXIVIACIÓN', styles: estilo_cabecera },
                        { content: 'TURNO A', styles: estilo_cabecera },
                        { content: 'TURNO B', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: tabla_lix.filas[0][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[0][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[1][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[1][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[2][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[2][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[3][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[3][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[3][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[4][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[4][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[4][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[5][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[5][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[5][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[6][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[6][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[6][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[7][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[7][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[7][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[8][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[8][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[8][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[9][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[9][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[9][2].valor, styles: estilo_celda_b },
                    ],
                    [
                        { content: tabla_lix.filas[10][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lix.filas[10][1].valor, styles: estilo_celda_a },
                        { content: tabla_lix.filas[10][2].valor, styles: estilo_celda_b },
                    ]
                ]
            });

            // Tabla niveles ácidos
            pdf.autoTable({
                tableWidth: 88,
                startY: 18,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'NIVELES ÁCIDOS', colSpan: 3, styles: estilo_cabecera },
                    ]
                ],
                body: [
                    [
                        { content: tabla_acido.filas[0][0].valor, styles: estilo_nom_fila },
                        { content: tabla_acido.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_acido.filas[0][1].valor, styles: estilo_celda_b }
                    ],
                    [
                        { content: tabla_acido.filas[1][0].valor, styles: estilo_nom_fila },
                        { content: tabla_acido.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_acido.filas[1][1].valor, styles: estilo_celda_b }
                    ],
                    [
                        { content: tabla_acido.filas[2][0].valor, styles: estilo_nom_fila },
                        { content: tabla_acido.filas[2][1].valor, styles: estilo_celda_a },
                        { content: tabla_acido.filas[2][1].valor, styles: estilo_celda_b }
                    ],
                    [
                        { content: tabla_acido.filas[3][0].valor, styles: estilo_nom_fila },
                        { content: tabla_acido.filas[3][1].valor, styles: estilo_celda_a },
                        { content: tabla_acido.filas[3][1].valor, styles: estilo_celda_b }
                    ],
                ]
            });

            // Tabla niveles agua
            pdf.autoTable({
                tableWidth: 88,
                startY: 51,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'NIVELES AGUA', styles: estilo_cabecera },
                        { content: 'TURNO A', styles: estilo_cabecera },
                        { content: 'TURNO B', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: tabla_lvl_agua.filas[0][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lvl_agua.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_lvl_agua.filas[0][1].valor, styles: estilo_celda_b }
                    ],
                    [
                        { content: tabla_lvl_agua.filas[1][0].valor, styles: estilo_nom_fila },
                        { content: tabla_lvl_agua.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_lvl_agua.filas[1][1].valor, styles: estilo_celda_b }
                    ],
                ]
            });

            // Tabla nivel agua fresca
            pdf.autoTable({
                tableWidth: 88,
                startY: 71,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'NIVEL AGUA FRESCA', styles: estilo_cabecera },
                        { content: 'TURNO A', styles: estilo_cabecera },
                        { content: 'TURNO B', styles: estilo_cabecera }
                    ]
                ],
                body: [
                    [
                        { content: tabla_agua_fresca.filas[0][0].valor, styles: estilo_nom_fila },
                        { content: tabla_agua_fresca.filas[0][1].valor, styles: estilo_celda_a },
                        { content: tabla_agua_fresca.filas[0][1].valor, styles: estilo_celda_b }
                    ],
                    [
                        { content: tabla_agua_fresca.filas[1][0].valor, styles: estilo_nom_fila },
                        { content: tabla_agua_fresca.filas[1][1].valor, styles: estilo_celda_a },
                        { content: tabla_agua_fresca.filas[1][1].valor, styles: estilo_celda_b }
                    ],
                ]
            });

            // Tabla FLUJO
            pdf.autoTable({
                tableWidth: 88,
                startY: 91,
                margin: { 
                    left: 110,
                    right: 10
                },
                headStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255,255,255]
                },
                head: [
                    [
                        { content: 'FLUJO (m3/h)', styles: estilo_cabecera },
                        { content: 'TURNO A', styles: estilo_cabecera },
                    ]
                ],
                body: [
                    [
                        { content: tabla_flujos_lix.filas[0][0].valor, styles: estilo_nom_fila },
                        { content: tabla_flujos_lix.filas[0][1].valor, styles: estilo_celda_a },
                    ],
                    [
                        { content: tabla_flujos_lix.filas[1][0].valor, styles: estilo_nom_fila },
                        { content: tabla_flujos_lix.filas[1][1].valor, styles: estilo_celda_a },
                    ],
                ]
            });
            
            // Descargar
            pdf.save(`Cosecha_${ moment().format('DD-MM-YYYY') }.pdf`);

        }, 200)

    }

    const generarNominaMensual = (datos) => {
        const estilo_cabecera = { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: '01579b', fontStyle: 'bold', fontSize: 7 };
        const cabecera_nom_mensual = [
            [
                { content: 'DÍA', styles: estilo_cabecera }, //
                { content: 'COSECHA TURNO A', styles: estilo_cabecera },
                { content: 'COSECHA TURNO B', styles: estilo_cabecera },
                { content: 'COSECHA DÍA', styles: estilo_cabecera },
                { content: 'ACUM. COSECHA REAL', styles: estilo_cabecera },
                { content: 'ACUM. PRESUPUESTO', styles: estilo_cabecera },
                { content: 'VAR. PRESUPUESTO', styles: estilo_cabecera },
                { content: 'ACUMULADO PLAN MAESTRO', styles: estilo_cabecera },
                { content: 'VAR. PLAN MAESTRO', styles: estilo_cabecera }
            ]
        ]

        const { nomina } = datos.informe_mensual;
        const { filas, fila_total } = nomina;
        const cuerpo_nom_mensual = [];

        let cont = 0;
        filas.forEach(({ cosecha_a, cosecha_b, cosecha_dia, cosecha_real, fecha, plan_maestro, var_plan_maestro, presupuesto, var_presupuesto }) => {
            const fondo = cont % 2 == 0 ? 'f7f7f7' : 'f2f2f2';
            const fondo_fecha = cont % 2 == 0 ? 'd2e2ed' : 'bed4e6';
            cont += 1;

            const estilo_fecha = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: fondo_fecha, fontStyle: 'bold', fontSize: 7 };
            const estilo_celda = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: fondo, fontSize: 7 };

            cuerpo_nom_mensual.push([
                { content: fecha, styles: estilo_fecha },
                { content: cosecha_a.toLocaleString("es-CL"), styles: estilo_celda },
                { content: cosecha_b.toLocaleString("es-CL"), styles: estilo_celda },
                { content: cosecha_dia.toLocaleString("es-CL"), styles: estilo_celda },
                { content: cosecha_real.toLocaleString("es-CL"), styles: estilo_celda },
                { content: presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
                { content: var_presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
                { content: plan_maestro.toLocaleString("es-CL"), styles: estilo_celda },
                { content: var_plan_maestro.toLocaleString("es-CL"), styles: estilo_celda }
            ])
        });

        const estilo_fecha = { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: 'e07b40', fontStyle: 'bold', fontSize: 7 };
        const estilo_celda = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'f8e2d5', fontSize: 7 };

        const { total_cosecha_a, total_cosecha_b, total_cosecha_dia, total_cosecha_real, texto, 
        total_plan_maestro, total_var_plan_maestro, total_presupuesto, total_var_presupuesto } = fila_total;

        cuerpo_nom_mensual.push([
            { content: 'MTD', styles: estilo_fecha },
            { content: total_cosecha_a.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_cosecha_b.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_cosecha_dia.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_cosecha_real.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_var_presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_plan_maestro.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_var_plan_maestro.toLocaleString("es-CL"), styles: estilo_celda }
        ]);

        return {
            cabecera_nom_mensual,
            cuerpo_nom_mensual
        }
    }

    const generarNominaAnual = (datos) => {
        const estilo_cabecera = { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: '01579b', fontStyle: 'bold', fontSize: 7 };
        const cabecera_nom_anual = [
            [
                { content: 'MES', styles: estilo_cabecera }, //
                { content: 'ACUM. COSECHA REAL', styles: estilo_cabecera },
                { content: 'ACUM. PRESUPUESTO', styles: estilo_cabecera },
                { content: 'VAR. PRESUPUESTO', styles: estilo_cabecera },
                { content: 'ACUMULADO PLAN MAESTRO', styles: estilo_cabecera },
                { content: 'VAR. PLAN MAESTRO', styles: estilo_cabecera }
            ]
        ]

        const { nomina } = datos.informe_anual;
        const { filas, fila_total } = nomina;
        const cuerpo_nom_anual = [];

        let cont = 0;
        filas.forEach(({ cosecha_real, mes, plan_maestro, var_plan_maestro, presupuesto, var_presupuesto }) => {
            const fondo = cont % 2 == 0 ? 'f7f7f7' : 'f2f2f2';
            const fondo_fecha = cont % 2 == 0 ? 'd2e2ed' : 'bed4e6';
            cont += 1;

            const estilo_fecha = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: fondo_fecha, fontStyle: 'bold', fontSize: 7 };
            const estilo_celda = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: fondo, fontSize: 7 };

            cuerpo_nom_anual.push([
                { content: mes, styles: estilo_fecha },
                { content: cosecha_real.toLocaleString("es-CL"), styles: estilo_celda },
                { content: presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
                { content: var_presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
                { content: plan_maestro.toLocaleString("es-CL"), styles: estilo_celda },
                { content: var_plan_maestro.toLocaleString("es-CL"), styles: estilo_celda }
            ])
        });

        const estilo_fecha = { valign: 'middle', halign: 'center', textColor: 'FFFFFF', fillColor: 'e07b40', fontStyle: 'bold', fontSize: 7 };
        const estilo_celda = { valign: 'middle', halign: 'center', textColor: '37474f', fillColor: 'f8e2d5', fontSize: 7 };

        const { total_cosecha_real, texto, total_plan_maestro, total_var_plan_maestro, total_presupuesto, total_var_presupuesto } = fila_total;

        cuerpo_nom_anual.push([
            { content: 'YTD', styles: estilo_fecha },
            { content: total_cosecha_real.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_var_presupuesto.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_plan_maestro.toLocaleString("es-CL"), styles: estilo_celda },
            { content: total_var_plan_maestro.toLocaleString("es-CL"), styles: estilo_celda }
        ]);

        return {
            cabecera_nom_anual,
            cuerpo_nom_anual
        }
    }

    const descargarXLSX = (produccion, sx, ewsx, lixiviacion) => {
        // Generar hojas
        const hoja_produccion = generarHojaProduccion( produccion );
        const hoja_sx = generarHojaSX( sx );
        const hoja_ewsx = generarHojaEWSX( ewsx );
        const hoja_lixiviacion = generarHojaLixiviacion( lixiviacion );

        const archivo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(archivo, hoja_produccion, `Producción`);
        XLSX.utils.book_append_sheet(archivo, hoja_sx, `SX`);
        XLSX.utils.book_append_sheet(archivo, hoja_ewsx, `EW SX`);
        XLSX.utils.book_append_sheet(archivo, hoja_lixiviacion, `Lixiviación`);


        XLSX.writeFile(archivo, `Cosecha_${ moment().format('DD-MM-YYYY') }.xlsx`, {  editable: true });
    }

    const generarHojaProduccion = (datos) => {
        const { informe_mensual, informe_anual } = datos;

        // Variables de tamaños celdas
        let alto_filas = [{ 'hpt': 15 }, { 'hpt': 20 }];
        let ancho_cols = [{ wch: 3 }, { wch: 20 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];

        // Generar hoja con primera fila vacía
        let hoja = XLSX.utils.aoa_to_sheet([]);
        hoja['!cols'] = ancho_cols;
        let origen = 1;

        // Nomina mensual
        let cabecera = [
            {
                v: '',
                t: 's',
            },
            {
                v: 'DÍA',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'COSECHA TURNO A',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'COSECHA TURNO B',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'COSECHA DÍA',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'ACUM. COSECHA REAL',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'ACUM. PRESUPUESTO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'VARIACIÓN PRESUPUESTO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'ACUM. PLAN MAESTRO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'VARIACIÓN PLAN MAESTRO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
        ];

        // Agregar cabecera nomina mensual
        XLSX.utils.sheet_add_aoa(hoja, [cabecera], { origin: origen  });
        origen += 1;

        // Contenido nomina mensual
        const { filas:filas_mensual, fila_total:fila_total_mensual } = informe_mensual.nomina;
        filas_mensual.forEach( contenido => {
            const { fecha, cosecha_a, cosecha_b, cosecha_dia, cosecha_real, plan_maestro,
            var_plan_maestro, presupuesto, var_presupuesto } = contenido;
            
            alto_filas = [...alto_filas, { hpt: 19 }];
            const fila = [
                {
                    v: '',
                    t: 's',
                },
                {
                    v: fecha,
                    t: 's',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: true, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'bed4e6' : 'd2e2ed'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: cosecha_a,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: cosecha_b,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: cosecha_dia,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: cosecha_real,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: presupuesto,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: var_presupuesto,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: plan_maestro,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: var_plan_maestro,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
            ]

            XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen  });
            origen += 1;
        });

        // Fila Month to day
        alto_filas = [...alto_filas, { hpt: 19 }, { hpt: 20 }];
        let fila = [
            {
                v: '',
                t: 's',
            },
            {
                v: fila_total_mensual.texto,
                t: 's',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'FFFFFF'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'e07b40'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_cosecha_a,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_cosecha_b,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_cosecha_dia,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_cosecha_real,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_presupuesto,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_var_presupuesto,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_plan_maestro,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_mensual.total_var_plan_maestro,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
        ]

        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 2;

        // Nómina anual
        cabecera = [
            {
                v: '',
                t: 's',
            },
            {
                v: 'MES',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'ACUM. COSECHA REAL',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'ACUM. PRESUPUESTO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'VARIACIÓN PRESUPUESTO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'ACUM. PLAN MAESTRO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: 'VARIACIÓN PLAN MAESTRO',
                t: 's',
                s: {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '01579b'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
        ];

        XLSX.utils.sheet_add_aoa(hoja, [cabecera], { origin: origen  });
        origen += 1;

        // Contenido nomina anual
        const { filas:filas_anual, fila_total:fila_total_anual } = informe_anual.nomina;
        filas_anual.forEach( contenido => {
            const { mes, cosecha_real, plan_maestro, var_plan_maestro, presupuesto, var_presupuesto } = contenido;
            
            alto_filas = [...alto_filas, { hpt: 19 }];
            const fila = [
                {
                    v: '',
                    t: 's',
                },
                {
                    v: mes,
                    t: 's',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: true, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'bed4e6' : 'd2e2ed'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: cosecha_real,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: presupuesto,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: var_presupuesto,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: plan_maestro,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
                {
                    v: var_plan_maestro,
                    t: 'n',
                    w: '0.00',
                    s: {
                        font: {
                            sz: 11, // tamaño
                            bold: false, // grosor
                            color: {
                                rgb: '37474f'  // color de letra
                            }
                        },
                        alignment: {
                            vertical: 'center',
                            horizontal: 'center',
                            wrapText: true
                        },
                        fill: {
                            fgColor: {
                                rgb: origen % 2 != 0 ? 'f2f2f2' : 'f7f7f7'
                            }
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'FFFFFF'
                                }
                            }
                        }
                    }
                },
            ]

            XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen  });
            origen += 1;
        });

        // Fila Month to day
        alto_filas = [...alto_filas, { hpt: 19 }, { hpt: 20 }];
        fila = [
            {
                v: '',
                t: 's',
            },
            {
                v: fila_total_anual.texto,
                t: 's',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'FFFFFF'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'e07b40'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_anual.total_cosecha_real,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_anual.total_presupuesto,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_anual.total_var_presupuesto,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_anual.total_plan_maestro,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
            {
                v: fila_total_anual.total_var_plan_maestro,
                t: 'n',
                w: '0.00',
                s: {
                    font: {
                        sz: 11, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: '37474f'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: 'f8e2d5'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            },
        ]

        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });

        hoja['!rows'] = alto_filas;

        return hoja;
    }

    const generarHojaSX =  (datos) => {
        const { tabla_flujos, tabla_turno_a, tabla_turno_b, tabla_arrastre, tabla_analisis, 
        tabla_laboratorio,  tabla_elec_pobre, tabla_hora,  tabla_turnos } = datos;
        
        // Generar hoja con primera fila vacía
        let hoja = XLSX.utils.aoa_to_sheet([]);
        let origen = 1;

        // Tabla elec.pobre, hora, arrastre y análisis
        const { filas:filas_elec } = tabla_elec_pobre;
        const { filas:filas_hora } = tabla_hora;
        const { filas:filas_arrastre } = tabla_arrastre;
        const { filas:filas_analisis } = tabla_analisis;

        let fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Elec pobre
            { v: 'ELEC. POBRE', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: '18:00', t: 's', s: cabecera_gris },
            { v: '06:00', t: 's', s: cabecera_gris },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: 'HORA', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: '09:00', t: 's', s: cabecera_gris },
            { v: '18:00', t: 's', s: cabecera_gris },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: 'ARRAST. ORG', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: '10:00', t: 's', s: cabecera_gris },
            { v: '22:00', t: 's', s: cabecera_gris },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: 'ANÁLISIS [CI-]', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: '09:00', t: 's', s: cabecera_gris },
            { v: '21:00', t: 's', s: cabecera_gris },
        ];

        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Elec pobre
            { v: filas_elec[0][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_elec[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_elec[0][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[0][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[0][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[0][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[0][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[0][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[0][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Elec pobre
            { v: filas_elec[1][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_elec[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_elec[1][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[1][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[1][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[1][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[1][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[1][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[1][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Elec pobre
            { v: filas_elec[2][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_elec[2][1].valor, t: 's', s: celda_turno_a },
            { v: filas_elec[2][2].valor, t: 's', s: celda_turno_b },
            

            { v: '', t: 's' }, // Espacio entre tablas

            // Horas
            { v: filas_hora[2][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[2][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[2][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[2][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[2][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[2][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[2][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[2][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[2][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Se agrega tabla laboratorio
        const { filas:filas_lab } = tabla_laboratorio;
        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Salto de línea
            { v: '', t: 's' }, { v: '', t: 's' },
            { v: '', t: 's' },
            { v: '', t: 's' },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[3][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[3][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[3][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[3][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[3][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[3][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[3][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[3][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[3][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Laboratorio
            { v: 'LABORATORIO', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: '18:00', t: 's', s: cabecera_gris },
            { v: '06:00', t: 's', s: cabecera_gris },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[4][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[4][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[4][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[4][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[4][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[4][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[4][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[4][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[4][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Laboratorio
            { v: filas_lab[0][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_lab[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_lab[0][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[5][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[5][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[5][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[5][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[5][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[5][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[5][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[5][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[5][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Se agrega tabla EP a TK
        const { filas:filas_turnos } = tabla_turnos;
        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: filas_lab[1][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_lab[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_lab[1][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[6][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[6][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[6][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[6][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[6][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[6][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[6][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[6][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[6][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: '', t: 's' }, { v: '', t: 's' },
            { v: '', t: 's' },
            { v: '', t: 's' },


            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[7][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[7][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[7][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[7][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[7][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[7][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[7][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[7][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[7][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: '', t: 's'}, { v: '', t: 's'},
            { v: 'TURNO A', t: 's', s: cabecera_gris },
            { v: 'TURNO B', t: 's', s: cabecera_gris },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[8][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[8][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[8][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[8][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[8][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[8][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[8][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[8][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[8][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: filas_turnos[0][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_turnos[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[0][2].valor, t: 's', s: celda_turno_b },
            

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: filas_hora[9][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_hora[9][1].valor, t: 's', s: celda_turno_a },
            { v: filas_hora[9][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[9][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[9][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[9][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: filas_analisis[9][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_analisis[9][1].valor, t: 's', s: celda_turno_a },
            { v: filas_analisis[9][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: filas_turnos[1][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_turnos[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[1][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Horas
            { v: '', t: 's' }, { v: '', t: 's' },
            { v: '', t: 's' },
            { v: '', t: 's' },

            { v: '', t: 's',}, // Espacio entre tablas

            // Arrastre
            { v: filas_arrastre[10][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_arrastre[10][1].valor, t: 's', s: celda_turno_a },
            { v: filas_arrastre[10][2].valor, t: 's', s: celda_turno_b },

            { v: '', t: 's',}, // Espacio entre tablas

            // Análisis
            { v: '', t: 's' }, { v: '', t: 's' },
            { v: '', t: 's' },
            { v: '', t: 's' },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: filas_turnos[2][0].valor, t: 's', s: celda_gris_par }, { v: '', t: 's', s: celda_gris_par },
            { v: filas_turnos[2][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[2][2].valor, t: 's', s: celda_turno_b },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Salto de línea entre tablas
        fila = [];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Tabla turno A, turno B y flujos
        const { filas:filas_a } = tabla_turno_a;
        const { filas:filas_b } = tabla_turno_b;
        const { filas:filas_flujos } = tabla_flujos;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: 'TURNO A', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: 'TREN A', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, 
            { v: 'TREN B', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },

            // Espacio entre tablas
            { v: '', t: 's',},

            // Turnos
            { v: 'TURNO B', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: 'TREN A', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, 
            { v: 'TREN B', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris } ,

            // Espacio entre tablas
            { v: '', t: 's',},

            // Flujos
            { v: '', t: 's' }, { v: '', t: 's' },
            { v: 'TURNO A', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, 
            { v: 'TURNO B', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris } 
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Primera celda vacía (márgen)
            { v: '', t: 's',},

            // Turnos
            { v: '09:00', t: 's', s: cabecera_turno_a }, { v: '', t: 's', s: cabecera_turno_a },
            { v: 'Cu++', t: 's', s: cabecera_turno_a }, 
            { v: 'H+', t: 's', s: cabecera_turno_a }, 
            { v: 'pH', t: 's', s: cabecera_turno_a },
            { v: 'Cu++', t: 's', s: cabecera_turno_a }, 
            { v: 'H+', t: 's', s: cabecera_turno_a }, 
            { v: 'pH', t: 's', s: cabecera_turno_a },

            // Espacio entre tablas
            { v: '', t: 's',},

            // Turnos
            { v: '21:00', t: 's', s: cabecera_turno_b }, { v: '', t: 's', s: cabecera_turno_b },
            { v: 'Cu++', t: 's', s: cabecera_turno_b }, 
            { v: 'H+', t: 's', s: cabecera_turno_b }, 
            { v: 'pH', t: 's', s: cabecera_turno_b },
            { v: 'Cu++', t: 's', s: cabecera_turno_b }, 
            { v: 'H+', t: 's', s: cabecera_turno_b }, 
            { v: 'pH', t: 's', s: cabecera_turno_b },

            // Espacio entre tablas
            { v: '', t: 's',},

            // Flujos
            { v: 'FLUJOS', t: 's', s: cabecera_azul }, { v: '', t: 's', s: cabecera_azul },
            { v: 'TREN-A', t: 's', s: cabecera_azul }, 
            { v: 'PLS SX', t: 's', s: cabecera_azul }, 
            { v: 'TREN-B', t: 's', s: cabecera_azul },
            { v: 'TREN-A', t: 's', s: cabecera_azul }, 
            { v: 'PLS SX', t: 's', s: cabecera_azul }, 
            { v: 'TREN-B', t: 's', s: cabecera_azul },
        ];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        for( let i = 0; i < filas_a.length; i++ ) {
            const celdas_a = filas_a[i];
            const celdas_b = filas_b[i];
            const celdas_flujos = filas_flujos[i];

            if( i == 1 ) {
                fila = [
                    // Primera celda vacía (márgen)
                    { v: '', t: 's',},
        
                    // Turno A
                    { v: celdas_a[0].valor, t: 's', s: celda_a }, { v: '', t: 's', s: celda_a  }, // Nombre fila 
                    { v: celdas_a[1].valor == 'no valido' ? '' : celdas_a[1].valor, t: 's', s: celdas_a[1].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[2].valor == 'no valido' ? '' : celdas_a[2].valor, t: 's', s: celdas_a[2].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[3].valor == 'no valido' ? '' : celdas_a[3].valor, t: 's', s: celdas_a[3].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[4].valor == 'no valido' ? '' : celdas_a[4].valor, t: 's', s: celdas_a[4].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[5].valor == 'no valido' ? '' : celdas_a[5].valor, t: 's', s: celdas_a[5].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[6].valor == 'no valido' ? '' : celdas_a[6].valor, t: 's', s: celdas_a[6].valor == 'no valido' ? celda_bloqueada : celda_gris },
    
                    // Espacio entre tablas
                    { v: '', t: 's',},
    
                    // Turno B
                    { v: celdas_b[0].valor, t: 's', s: celda_b }, { v: '', t: 's', s: celda_b  }, // Nombre fila 
                    { v: celdas_b[1].valor == 'no valido' ? '' : celdas_b[1].valor, t: 's', s: celdas_b[1].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[2].valor == 'no valido' ? '' : celdas_b[2].valor, t: 's', s: celdas_b[2].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[3].valor == 'no valido' ? '' : celdas_b[3].valor, t: 's', s: celdas_b[3].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[4].valor == 'no valido' ? '' : celdas_b[4].valor, t: 's', s: celdas_b[4].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[5].valor == 'no valido' ? '' : celdas_b[5].valor, t: 's', s: celdas_b[5].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[6].valor == 'no valido' ? '' : celdas_b[6].valor, t: 's', s: celdas_b[6].valor == 'no valido' ? celda_bloqueada : celda_gris },

                    // Espacio entre tablas
                    { v: '', t: 's',},
    
                    // Flujos
                    { v: celdas_flujos[0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar  }, // Nombre fila 
                    { v: celdas_flujos[1].valor == 'no valido' ? '' : celdas_flujos[1].valor, t: 's', s: celdas_flujos[1].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: '', t: 's', s: celda_turno_a },
                    { v: celdas_flujos[2].valor == 'no valido' ? '' : celdas_flujos[2].valor, t: 's', s: celdas_flujos[2].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[3].valor == 'no valido' ? '' : celdas_flujos[3].valor, t: 's', s: celdas_flujos[3].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                    { v: '', t: 's', s: celda_turno_b },
                    { v: celdas_flujos[4].valor == 'no valido' ? '' : celdas_flujos[4].valor, t: 's', s: celdas_flujos[4].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                ];
            } else if( i != filas_a.length - 1) {
                fila = [
                    // Primera celda vacía (márgen)
                    { v: '', t: 's',},
        
                    // Turno A
                    { v: celdas_a[0].valor, t: 's', s: celda_a }, { v: '', t: 's', s: celda_a  }, // Nombre fila 
                    { v: celdas_a[1].valor == 'no valido' ? '' : celdas_a[1].valor, t: 's', s: celdas_a[1].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[2].valor == 'no valido' ? '' : celdas_a[2].valor, t: 's', s: celdas_a[2].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[3].valor == 'no valido' ? '' : celdas_a[3].valor, t: 's', s: celdas_a[3].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[4].valor == 'no valido' ? '' : celdas_a[4].valor, t: 's', s: celdas_a[4].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[5].valor == 'no valido' ? '' : celdas_a[5].valor, t: 's', s: celdas_a[5].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_a[6].valor == 'no valido' ? '' : celdas_a[6].valor, t: 's', s: celdas_a[6].valor == 'no valido' ? celda_bloqueada : celda_gris },
    
                    // Espacio entre tablas
                    { v: '', t: 's',},
    
                    // Turno B
                    { v: celdas_b[0].valor, t: 's', s: celda_b }, { v: '', t: 's', s: celda_b  }, // Nombre fila 
                    { v: celdas_b[1].valor == 'no valido' ? '' : celdas_b[1].valor, t: 's', s: celdas_b[1].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[2].valor == 'no valido' ? '' : celdas_b[2].valor, t: 's', s: celdas_b[2].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[3].valor == 'no valido' ? '' : celdas_b[3].valor, t: 's', s: celdas_b[3].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[4].valor == 'no valido' ? '' : celdas_b[4].valor, t: 's', s: celdas_b[4].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[5].valor == 'no valido' ? '' : celdas_b[5].valor, t: 's', s: celdas_b[5].valor == 'no valido' ? celda_bloqueada : celda_gris },
                    { v: celdas_b[6].valor == 'no valido' ? '' : celdas_b[6].valor, t: 's', s: celdas_b[6].valor == 'no valido' ? celda_bloqueada : celda_gris },

                    // Espacio entre tablas
                    { v: '', t: 's',},
    
                    // Flujos
                    { v: celdas_flujos[0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar  }, // Nombre fila 
                    { v: celdas_flujos[1].valor == 'no valido' ? '' : celdas_flujos[1].valor, t: 's', s: celdas_flujos[1].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[2].valor == 'no valido' ? '' : celdas_flujos[2].valor, t: 's', s: celdas_flujos[2].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[3].valor == 'no valido' ? '' : celdas_flujos[3].valor, t: 's', s: celdas_flujos[3].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[4].valor == 'no valido' ? '' : celdas_flujos[4].valor, t: 's', s: celdas_flujos[4].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                    { v: celdas_flujos[5].valor == 'no valido' ? '' : celdas_flujos[5].valor, t: 's', s: celdas_flujos[5].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                    { v: celdas_flujos[6].valor == 'no valido' ? '' : celdas_flujos[6].valor, t: 's', s: celdas_flujos[6].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                ];
            } else {
                fila = [
                    // Primera celda vacía (márgen)
                    { v: '', t: 's',},
        
                    // Turno A
                    { v: celdas_a[0].valor, t: 's', s: celda_a }, { v: '', t: 's', s: celda_a  }, // Nombre fila 
                    { v: celdas_a[1].valor, t: 's', s: celda_gris },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
    
                    // Espacio entre tablas
                    { v: '', t: 's',},
    
                    // Turno B
                    { v: celdas_b[0].valor, t: 's', s: celda_b }, { v: '', t: 's', s: celda_b  }, // Nombre fila 
                    { v: celdas_b[1].valor, t: 's', s: celda_gris },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
                    { v: '', t: 's' },
                    { v: '', t: 's' },

                    // Espacio entre tablas
                    { v: '', t: 's',},
    
                    // Turno B
                    { v: celdas_flujos[0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar  }, // Nombre fila 
                    { v: celdas_flujos[1].valor == 'no valido' ? '' : celdas_flujos[1].valor, t: 's', s: celdas_flujos[1].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[2].valor == 'no valido' ? '' : celdas_flujos[2].valor, t: 's', s: celdas_flujos[2].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[3].valor == 'no valido' ? '' : celdas_flujos[3].valor, t: 's', s: celdas_flujos[3].valor == 'no valido' ? celda_bloqueada : celda_turno_a },
                    { v: celdas_flujos[4].valor == 'no valido' ? '' : celdas_flujos[4].valor, t: 's', s: celdas_flujos[4].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                    { v: celdas_flujos[5].valor == 'no valido' ? '' : celdas_flujos[5].valor, t: 's', s: celdas_flujos[5].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                    { v: celdas_flujos[6].valor == 'no valido' ? '' : celdas_flujos[6].valor, t: 's', s: celdas_flujos[6].valor == 'no valido' ? celda_bloqueada : celda_turno_b },
                ];
            }


            XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
            origen += 1;
        }

        fila = [];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;
        

        // Variables de tamaños celdas
        let alto_filas = [{ 'hpt': 15 }, { 'hpt': 19 }];
        let ancho_cols = [{ wch: 3 }];

        // Mezclar celdas
        let merge = [
            {
                s: { r: 17, c: 22 },
                e: { r: 18, c: 22 }
            },
            {
                s: { r: 17, c: 25 },
                e: { r: 18, c: 25 }
            }
        ];
        for( let i = 1; i < 27; i++ ) {
            alto_filas = [...alto_filas, { 'hpt': 19 } ];
            if( i < 14 ) {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    },
                    {
                        s: { r: i, c: 6 },
                        e: { r: i, c: 7 }
                    },
                    {
                        s: { r: i, c: 11 },
                        e: { r: i, c: 12 }
                    },
                    {
                        s: { r: i, c: 16 },
                        e: { r: i, c: 17 }
                    },
                ];
            } else if(i == 15) {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    },
                    {
                        s: { r: i, c: 3 },
                        e: { r: i, c: 5 }
                    },
                    {
                        s: { r: i, c: 6 },
                        e: { r: i, c: 8 }
                    },
                    {
                        s: { r: i, c: 10 },
                        e: { r: i, c: 11 }
                    },
                    {
                        s: { r: i, c: 12 },
                        e: { r: i, c: 14 }
                    },
                    {
                        s: { r: i, c: 15 },
                        e: { r: i, c: 17 }
                    },
                    {
                        s: { r: i, c: 19 },
                        e: { r: i, c: 20 }
                    },
                    {
                        s: { r: i, c: 21 },
                        e: { r: i, c: 23 }
                    },
                    {
                        s: { r: i, c: 24 },
                        e: { r: i, c: 26 }
                    },
                ];
            } else {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    },
                    {
                        s: { r: i, c: 10 },
                        e: { r: i, c: 11 }
                    },
                    {
                        s: { r: i, c: 19 },
                        e: { r: i, c: 20 }
                    },
                ];
            }
        }

        hoja['!cols'] = ancho_cols;
        hoja["!rows"] = alto_filas;
        hoja["!merges"] = merge;
        return hoja;
    }

    const generarHojaEWSX = (datos) => {
        const { tabla_turnos, tabla_cte_teorica, tabla_eficiencia_extraccion, tabla_extractores,
        tabla_lvl_tks, tabla_prod_planta  } = datos;

        // Generar hoja con primera fila vacía
        let hoja = XLSX.utils.aoa_to_sheet([]);
        let origen = 1;

        const { filas:filas_extraccion } = tabla_eficiencia_extraccion;
        const { filas:filas_extractores } = tabla_extractores;
        const { filas:filas_cte_teorica } = tabla_cte_teorica;
        const { filas:filas_lvl_tks } = tabla_lvl_tks;
        const { filas:filas_prod_planta } = tabla_prod_planta;
        const { filas:filas_turnos } = tabla_turnos;
        
        let fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Extractores
            { v: 'EXTRACTORES FUERA DE SERVICIO', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Cte. teórica
            { v: 'CTE. TEÓRICA', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Nivel TKS
            { v: 'NIVEL TKS ACUMULADORES PTS.', t: 's', s: cabecera_azul }, { v: '', t: 's', s: cabecera_azul }, { v: '', t: 's', s: cabecera_azul }, { v: '', t: 's', s: cabecera_azul },
            { v: filas_lvl_tks[0][1].valor, t: 's', s: celda_gris }
        ]
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Extractores
            { v: filas_extractores[0][0].valor, t: 's', s: nom_celda_azul_impar }, { v: '', t: 's',  s: nom_celda_azul_impar },
            { v: filas_extractores[0][1].valor, t: 's', s: celda_gris },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Cte. teórica
            { v: filas_cte_teorica[0][0].valor, t: 's', s: nom_celda_azul_impar }, { v: '', t: 's',  s: nom_celda_azul_impar },
            { v: filas_cte_teorica[0][1].valor, t: 's', s: celda_gris },

            // Espacio entre tablas 
            { v: '', t: 's' },

            // Salto de línea
            { v: '', t: 's' },
        ]
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Extractores
            { v: filas_extractores[1][0].valor, t: 's', s: nom_celda_azul_impar }, { v: '', t: 's', s: nom_celda_azul_impar },
            { v: filas_extractores[1][1].valor, t: 's', s: celda_gris },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Cte. teórica
            { v: filas_cte_teorica[1][0].valor, t: 's', s: nom_celda_azul_impar }, { v: '', t: 's', s: nom_celda_azul_impar },
            { v: filas_cte_teorica[1][1].valor, t: 's', s: celda_gris },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Nivel TKS
            { v: 'PROD. PLANTA OSMOSIS (M3/DÍA)', t: 's', s: cabecera_azul }, { v: '', t: 's', s: cabecera_azul}, { v: '', t: 's', s: cabecera_azul}, { v: '', t: 's', s: cabecera_azul },
            { v: filas_prod_planta[0][1].valor, t: 's', s: celda_gris }
        ]
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Agregar fila
        fila = [];
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: '', t: 's' }, { v: '', t: 's' },
            { v: 'TURNO A', t: 's', s: cabecera_gris },
            { v: 'TURNO B', t: 's', s: cabecera_gris },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Eficiencia extracción y reextracción
            { v: 'EFICIENCIA EXTRACCIÓN', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: '09:00', t: 's', s: cabecera_gris },
            { v: '21:00', t: 's', s: cabecera_gris },
            { v: 'EFICIENCIA REEXTRACCIÓN', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[0][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[0][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[0][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Eficiencia extracción y reextracción
            { v: filas_extraccion[0][0].valor, t: 's', s: nom_celda_azul_impar }, { v: '', t: 's', s: nom_celda_azul_impar }, { v: '', t: 's',s: nom_celda_azul_impar },
            { v: filas_extraccion[0][1].valor, t: 's', s: celda_gris },
            { v: filas_extraccion[0][2].valor, t: 's', s: celda_gris },
            { v: filas_extraccion[0][3].valor, t: 's', s: celda_gris }, { v: '', t: 's', s: celda_gris }, { v: '', t: 's', s: celda_gris }, 
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[1][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[1][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[1][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas 
            { v: '', t: 's', },

            // Eficiencia extracción y reextracción
            { v: filas_extraccion[1][0].valor, t: 's', s: nom_celda_azul_impar }, { v: '', t: 's', s: nom_celda_azul_impar }, { v: '', t: 's',s: nom_celda_azul_impar },
            { v: filas_extraccion[1][1].valor, t: 's', s: celda_gris },
            { v: filas_extraccion[1][2].valor, t: 's', s: celda_gris },
            { v: filas_extraccion[1][3].valor, t: 's', s: celda_gris },{ v: '', t: 's', s: celda_gris }, { v: '', t: 's', s: celda_gris }, 
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[2][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[2][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[2][2].valor, t: 's', s: celda_turno_b },
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[3][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[3][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[3][2].valor, t: 's', s: celda_turno_b },
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[4][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[4][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[4][2].valor, t: 's', s: celda_turno_b },
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;
        
        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[5][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[5][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[5][2].valor, t: 's', s: celda_turno_b },
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Márgen lateral
            { v: '', t: 's', },

            // Turnos
            { v: filas_turnos[6][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's',s: celda_gris_impar },
            { v: filas_turnos[6][1].valor, t: 's', s: celda_turno_a },
            { v: filas_turnos[6][2].valor, t: 's', s: celda_turno_b },
        ];
        // Agregar fila
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Variables de tamaños celdas
        let alto_filas = [{ 'hpt': 15 }, { 'hpt': 19 }];
        let ancho_cols = [{ wch: 3 }];

        // Mezclar celdas
        let merge = [];
        for( let i = 1; i < 14; i++ ) {
            alto_filas = [...alto_filas, { 'hpt': 19 } ];
            if( i == 1 ) {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 3 }
                    },
                    {
                        s: { r: i, c: 5 },
                        e: { r: i, c: 7 }
                    },
                    {
                        s: { r: i, c: 9 },
                        e: { r: i, c: 12 }
                    },
                ];

            } else if( i < 4 ) {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    },
                    {
                        s: { r: i, c: 5 },
                        e: { r: i, c: 6 }
                    },
                    {
                        s: { r: i, c: 9 },
                        e: { r: i, c: 12 }
                    },
                ];
            } else if( i >= 4 &&  i < 9 ) {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    },
                    {
                        s: { r: i, c: 6 },
                        e: { r: i, c: 8 }
                    },
                    {
                        s: { r: i, c: 11 },
                        e: { r: i, c: 13 }
                    },
                ];
            } else {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    }
                ];
            }
        }

        hoja['!cols'] = ancho_cols;
        hoja["!rows"] = alto_filas;
        hoja["!merges"] = merge;
        return hoja;
    }

    const generarHojaLixiviacion = (datos) => {
        const { tabla_acido, tabla_agua_fresca, tabla_flujos, 
        tabla_lix, tabla_lvl_agua } = datos;

        // Generar hoja con primera fila vacía
        let hoja = XLSX.utils.aoa_to_sheet([]);
        let origen = 1;

        let fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: 'DATOS DE LIXIVIACIÓN', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: 'TURNO A', t: 's', s: cabecera_gris },
            { v: 'TURNO B', t: 's', s: cabecera_gris },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles ácido
            { v: 'NIVELES ÁCIDO', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[0][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[0][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[0][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles ácido
            { v: tabla_acido.filas[0][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_acido.filas[0][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_acido.filas[0][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[1][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[1][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[1][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles ácido
            { v: tabla_acido.filas[1][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_acido.filas[1][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_acido.filas[1][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[2][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[2][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[2][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles ácido
            { v: tabla_acido.filas[2][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_acido.filas[2][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_acido.filas[2][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[3][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[3][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[3][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles ácido
            { v: tabla_acido.filas[3][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_acido.filas[3][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_acido.filas[3][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[4][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[4][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[4][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Salto de línea
            { v: '', t: 's' }
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[5][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[5][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[5][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles agua
            { v: 'NIVELES AGUA', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: 'TURNO A', t: 's', s: cabecera_gris },
            { v: 'TURNO B', t: 's', s: cabecera_gris },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[6][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[6][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[6][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles agua
            { v: tabla_lvl_agua.filas[0][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lvl_agua.filas[0][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lvl_agua.filas[0][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[7][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[7][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[7][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Niveles agua
            { v: tabla_lvl_agua.filas[1][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lvl_agua.filas[1][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lvl_agua.filas[1][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[8][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[8][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[8][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Salto de línea
            { v: '', t: 's' }
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[9][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[9][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[9][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // 
            { v: 'NIVEL AGUA FRESCA', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: 'TURNO A', t: 's', s: cabecera_gris },
            { v: 'TURNO B', t: 's', s: cabecera_gris },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: tabla_lix.filas[10][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },{ v: '', t: 's', s: celda_gris_impar },
            { v: tabla_lix.filas[10][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_lix.filas[10][2].valor, t: 's', s: celda_turno_b },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Agua fresca
            { v: tabla_agua_fresca.filas[0][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_agua_fresca.filas[0][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_agua_fresca.filas[0][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Datos lixiviación
            { v: '', t: 's' }, { v: '', t: 's' },{ v: '', t: 's' },
            { v: '', t: 's' },
            { v: '', t: 's' },

            // Espacio entre tablas
            { v: '', t: 's' },

            // Agua fresca
            { v: tabla_agua_fresca.filas[1][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_agua_fresca.filas[1][1].valor, t: 's', s: celda_turno_a },
            { v: tabla_agua_fresca.filas[1][2].valor, t: 's', s: celda_turno_b },
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = []
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Flujo
            { v: 'FLUJO (m3/h)', t: 's', s: cabecera_gris }, { v: '', t: 's', s: cabecera_gris },
            { v: 'TURNO A', t: 's', s: cabecera_gris }
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Flujo
            { v: tabla_flujos.filas[0][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_flujos.filas[0][1].valor, t: 's', s: celda_turno_a }
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        fila = [
            // Margen lateral
            { v: '', t: 's' },

            // Flujo
            { v: tabla_flujos.filas[1][0].valor, t: 's', s: celda_gris_impar }, { v: '', t: 's', s: celda_gris_impar },
            { v: tabla_flujos.filas[1][1].valor, t: 's', s: celda_turno_a }
        ]
        XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
        origen += 1;

        // Variables de tamaños celdas
        let alto_filas = [{ 'hpt': 15 }, { 'hpt': 19 }];
        let ancho_cols = [{ wch: 3 }];

        // Mezclar celdas
        let merge = [];
        for( let i = 1; i < 18; i++ ) {
            alto_filas = [...alto_filas, { 'hpt': 19 } ];

            if ( i == 1 ) {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 3 }
                    },
                    {
                        s: { r: i, c: 7 },
                        e: { r: i, c: 10 }
                    },
                ];
            } else if( i <= 14 ){
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 3 }
                    },
                    {
                        s: { r: i, c: 7 },
                        e: { r: i, c: 8 }
                    },
                ];
            } else {
                merge = [...merge, 
                    {
                        s: { r: i, c: 1 },
                        e: { r: i, c: 2 }
                    },
                ];
            }


            
        }

        hoja['!cols'] = ancho_cols;
        hoja["!rows"] = alto_filas;
        hoja["!merges"] = merge;
        return hoja;
    }

    const descargarImagen = async( produccion, sx, ewsx, lixiviacion ) => {
        const modal = document.getElementById("modal-captura");
        const instance = M.Modal.getInstance(modal);
        instance.open();
        
        // Gráfico mensual
        let elemento = document.getElementById("captura-cosecha");
        let canvas  = await html2canvas(elemento, { 
            scale: 3,
            letterRendering: true
        });
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg");
        a.download = "image.jpeg";
        a.click();

        instance.close();
    }


    return { descargarPDF, descargarXLSX, descargarImagen };
}
