import { createSlice } from "@reduxjs/toolkit";

const initialState = { datos: {} };

const SimSXSlice = createSlice({
    name: 'SimSX',
    initialState,
    reducers: {
        setSimSX: (state, { payload }) => {
            state.datos = payload
        },
        limpiarSimSX: (state) => {
            state.datos = {}
        }
    }
})

export const { setSimSX, limpiarSimSX } = SimSXSlice.actions;
export default SimSXSlice.reducer;