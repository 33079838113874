// Materialize
import M from 'materialize-css';

// Estilos scss
import './styles/Navbar.scss';

// Logos
const logo_smartpls = require('../../assets/img/logos/logo_smartpls_blanco_gif.gif');

const logo_centinela = require('../../assets/img/logos/centinela1_blanco.png');

export const Navbar = () => {

  return (
    <>
      <nav className=" light-blue darken-4">
        <div className="nav-wrapper">
            {/* Logo SmartPLS */}
            <a href="#" className="">
              <img src={ logo_smartpls } alt="" />
            </a>

            {/* <a href="#" className='titulo-desarrollo'>Ambiente de desarrollo</a> */}

            <a href="/" className="brand-logo right">
              <img src={ logo_centinela } alt="logo_cliente" className="logo-nv" /> 
            </a> 
        </div>
      </nav>
    </>
  )
}
{/* <img src={ logo_centinela } alt="logo_cliente" className="logo-nv" /> */}


document.addEventListener('DOMContentLoaded', function() {
  var elems = document.querySelectorAll('.sidenav');
  var instances = M.Sidenav.init(elems);
});