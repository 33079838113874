import React, { useState } from 'react'
import { useResumenModulos } from '../../../hooks/useResumenModulos';
import { ModalHistorialEstados } from './ModalHistorialEstados';

export const FilaSuperior = (props) => {

  const { descargarResumenMET, descargarResumenOXE, historialEstadosPlantaAPI } = useResumenModulos();

  const [DatosHistorial, setDatosHistorial] = useState({ error: null })

  const descargarResumenModulos = () => {
    if(props.planta == 'met') {
      descargarResumenMET();
    } else if(props.planta == 'oxe') {
      descargarResumenOXE();
    }
  }

  const verHistorialEstados = async() => {
    const datos = await historialEstadosPlantaAPI(props.planta);

    if( datos == false ) {
      setDatosHistorial({
        error: true,
        datos: []
      });
    } else {
      setDatosHistorial({
        error: false,
        datos
      });
    }
  }

  return (
    <div className="fila-superior">
        <h3 className="titulo-pagina">
            RESUMEN MÓDULOS { props.planta.toUpperCase() }
        </h3>

        <div className="cont-btns">
          {
            props.planta === 'met'
            ?
              <>
                <button className="btn waves-effect waves-light light-blue darken-4 btn-historial-estados modal-trigger" data-target={ `modal-historial-estados-${props.planta}` } onClick={ verHistorialEstados }>
                    <i className="material-icons left">update</i>
                    HISTORIAL ESTADOS
                </button>
                <ModalHistorialEstados planta={ props.planta } datos={ DatosHistorial } />
              </>
            : null
          }

          {/* Exportar datos de tablas */}
          <button className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar" onClick={ descargarResumenModulos }>
              <i className="material-icons left">download</i>
              DESCARGAR
          </button>
        </div>
    </div>
  )
}
