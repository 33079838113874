import { Routes, Route, Navigate } from "react-router-dom"
import { DigitalizacionRoutes } from "../digitalizacion/router/DigitalizacionRoutes"
import { AutentificacionRoutes } from "../autentificacion/router/AutentificacionRoutes"
import { PuentePage } from "../digitalizacion/modules/puente/PuentePage";
import { useSelector } from "react-redux"

export const AppRouter = () => {
  const token = useSelector((state) => state.autentificacion.token);
  const estado = useSelector((state) => state.autentificacion.estado)

  return (
    <Routes>
      {
        (estado == false || token.length == 0 )
        ? <Route path="/*" element={ <AutentificacionRoutes /> } />
        : <Route path="/*" element={ <DigitalizacionRoutes /> } />
      }

      <Route path="/integracion-smartpls" element={ <Navigate to="/" /> } />
      <Route path="/integracion-smartpls/:token" element={ <PuentePage /> } />
    </Routes>
  )
}

