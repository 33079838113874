import { useEffect, useState } from "react";

import { GraficoLinea } from "./GraficoLinea";

export const ControlRiego = (props) => {
    const [CicloMET, setCicloMET] = useState('');
    const [CicloOXE, setCicloOXE] = useState('');
    
    const cambiarGraficoControlRiego = (btn, id_btn, id_ocultar, id_mostrar) => {
        btn.classList.add('activo');
        document.getElementById(id_btn).classList.remove('activo');

        document.getElementById(id_ocultar).classList.remove('activo');
        document.getElementById(id_mostrar).classList.add('activo');
    }

    const cambiarGraficoCR = (planta, id_graf, graf1, graf2, id_btn, btn1, btn2) => {
        if(planta == 'met') {
            document.getElementById(id_graf).classList.add('activo');
            document.getElementById(graf1).classList.remove('activo');
            document.getElementById(graf2).classList.remove('activo');
    
            document.getElementById(id_btn).classList.add('activo');
            document.getElementById(btn1).classList.remove('activo');
            document.getElementById(btn2).classList.remove('activo');
        } else {
            document.getElementById(id_graf).classList.add('activo');
            document.getElementById(graf1).classList.remove('activo');
    
            document.getElementById(id_btn).classList.add('activo');
            document.getElementById(btn1).classList.remove('activo');
        }
    }
    
    return (
        <>
            <div className="btns-resumen-reportes">
                <button 
                    className='waves-effect waves-light btn activo'
                    id="btn-cr-MET"
                    onClick={e => { cambiarGraficoControlRiego(e.target, 'btn-cr-OXE', 'cont-cr-oxe', 'cont-cr-met') }}
                >MET</button>

                <button 
                    className='waves-effect waves-light btn'
                    id="btn-cr-OXE"
                    onClick={e => { cambiarGraficoControlRiego(e.target, 'btn-cr-MET', 'cont-cr-met', 'cont-cr-oxe') }}
                >OXE</button>
            </div>

            

            <div className="cont-cr activo" id='cont-cr-met'>
                <div className="cont-btns-variables">
                    <button id="btn-var-met-horas" className="waves-effect waves-light btn activo btn-variable" onClick={ () => { cambiarGraficoCR('met', 'graf-horas-met', 'graf-presion-met', 'graf-flujo-met', 'btn-var-met-horas', 'btn-var-met-presion', 'btn-var-met-flujos') } }>HORAS</button>
                    <button id="btn-var-met-presion" className="waves-effect waves-light btn btn-variable" onClick={ () => { cambiarGraficoCR('met', 'graf-presion-met', 'graf-horas-met', 'graf-flujo-met', 'btn-var-met-presion', 'btn-var-met-flujos', 'btn-var-met-horas') } }>PRESIÓN (kPa)</button>
                    <button id="btn-var-met-flujos" className="waves-effect waves-light btn btn-variable" onClick={ () => { cambiarGraficoCR('met', 'graf-flujo-met', 'graf-presion-met', 'graf-horas-met', 'btn-var-met-flujos', 'btn-var-met-horas', 'btn-var-met-presion') } }>FLUJOS (m3/hr)</button>
                </div>

                <div className="chartWrapper scroll">
                    <div className="chartAreaWrapper icr-MET">
                        <GraficoLinea datos={ props.datos } interfaz={ props.datos.datos } filtro='MET' variable=""/>
                    </div>
                </div>

            </div>

            <div className="cont-cr chartWrapper scroll" id='cont-cr-oxe'>
                <div className="cont-btns-variables">
                    <button id="btn-var-oxe-horas" className="waves-effect waves-light btn activo btn-variable" onClick={ () => { cambiarGraficoCR('oxe', 'graf-horas-oxe', 'graf-flujo-oxe', null, 'btn-var-oxe-horas', 'btn-var-oxe-flujos') } }>HORAS</button>
                    <button id="btn-var-oxe-flujos" className="waves-effect waves-light btn btn-variable" onClick={ () => { cambiarGraficoCR('oxe', 'graf-flujo-oxe', 'graf-horas-oxe', null, 'btn-var-oxe-flujos', 'btn-var-oxe-horas') } }>FLUJOS (m3/hr)</button>
                </div>
                
                <div className="chartWrapper scroll">
                    <div className="chartAreaWrapper icr-OXE">
                        <GraficoLinea datos={ props.datos } interfaz={ props.datos.datos } filtro='OXE' variable=""/>
                    </div>
                </div>
            </div>
        </>
    )
}


