import React, { useState, useEffect } from 'react'
import { TablaSimple } from './TablaSimple'
import { useCosecha } from '../../../hooks/useCosecha'

export const SX = ( props ) => {
  const { cambiarInforme } = useCosecha();
  return (
    props.Interfaz === false
    ? null
    :
      <div id="tab-sx-cosecha" className="tab-cosecha">
        <div className="contenido-cosecha sx scroll">
          {/* Elec.pobre, laboratorio, hora */}
          <div className="cont-dos-tablas linea-cont-tabla">
            <div>
              {/* Elec. pobre */}
              <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_elec_pobre.cabeceras } filas={ props.TablasSX.tabla_elec_pobre.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_elec_pobre' }/>

              {/* Laboratorio */}
              <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_laboratorio.cabeceras } filas={ props.TablasSX.tabla_laboratorio.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_laboratorio' }/>

              <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_turnos.cabeceras } filas={ props.TablasSX.tabla_turnos.filas } claseTabla={ 'tabla-flujos turnos colores-turnos' } id_tabla={ 'tabla_turnos' }/>
            </div>

            {/* Hora */}
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_hora.cabeceras } filas={ props.TablasSX.tabla_hora.filas } claseTabla={ 'tabla-flujos colores-turnos' } id_tabla={ 'tabla_hora' }/>
          </div>

          <br />

          {/* Trenes por turno */}
          <div className="btns-informe">
            <button 
              className="btn waves-effect waves-light activo transparent" 
              id="btn-trenes-turno-a"
              onClick={ () => { cambiarInforme('tab-trenes-turno-b','btn-trenes-turno-b', 'tab-trenes-turno-a','btn-trenes-turno-a') } }
            >
              TURNO A
            </button>

            <button 
              className="btn waves-effect waves-light transparent" 
              id="btn-trenes-turno-b"
              onClick={ () => { cambiarInforme('tab-trenes-turno-a','btn-trenes-turno-a','tab-trenes-turno-b','btn-trenes-turno-b') } }
            >
              TURNO B
            </button>
          </div>

          <div className="tab-informe activo" id="tab-trenes-turno-a">
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_turno_a.cabeceras } filas={ props.TablasSX.tabla_turno_a.filas } claseTabla={ 'tabla-flujos turnoa ' } id_tabla={ 'tabla_turno_a' }/>
          </div>

          <div className="tab-informe" id="tab-trenes-turno-b">
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_turno_b.cabeceras } filas={ props.TablasSX.tabla_turno_b.filas } claseTabla={ 'tabla-flujos turnob' } id_tabla={ 'tabla_turno_b' }/>
          </div>

          <br />

          {/* Arrastre, análisis */}
          <div className="cont-dos-tablas linea-cont-tabla">
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_arrastre.cabeceras } filas={ props.TablasSX.tabla_arrastre.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_arrastre' }/>

            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_analisis.cabeceras } filas={ props.TablasSX.tabla_analisis.filas } claseTabla={ 'tabla-flujos colores-turnos ' } id_tabla={ 'tabla_analisis' }/>
          </div>

          <br />

          <div className="linea-cont-tabla">
            <TablaSimple cambios={ props.cambios } setCambios={ props.setCambios } vista="sx" datos={ props.TablasSX } setDatos={ props.setDatos } cabecera={ props.TablasSX.tabla_flujos.cabeceras } filas={ props.TablasSX.tabla_flujos.filas } claseTabla={ 'tabla-flujos flujos' } id_tabla={ 'tabla_flujos' }/>
          </div>
        </div>
      </div>
  )
}
