import { createSlice } from '@reduxjs/toolkit';


const initialState = { datos: [] }

const RazonLixiviacionSlice = createSlice({
    name: 'RazonLixiviacion',
    initialState,
    reducers: {
        setRazonLixiviacion: (state, { payload }) => {
            state.datos = payload
        },

        limpiarRazonLixiviacion: (state) => {
            state.datos = [];
        }
    }
});

export const { setRazonLixiviacion, limpiarRazonLixiviacion } = RazonLixiviacionSlice.actions;
export default RazonLixiviacionSlice.reducer;