import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

import { useResumenModulos } from '../../../hooks/useResumenModulos';

// Componentes
import { Grafico } from './Grafico';
import { TablaEstados } from './TablaEstados';
import { TablaResumenMET } from './TablaResumenMET';
import { ModalHistorial } from './ModalHistorial';

export const ResumenMET = (props) => {
  const [ModuloModal, setModuloModal] = useState('');

  return (
    <div className="cont-resumen-met">
      <ModalHistorial modulo={ ModuloModal } />

      <div className="contenedor cont-estadistica met">
          {/*  Gráfico estados */}
          <Grafico alto={ 200 } datos={ props.datos } />

          {/* Tabla contabilizadora estados */}
          <TablaEstados datos={ props.datos } />
      </div>
      

      <div className="contenedor cont-tablas-resumen met">
        <div className="cont-tabla met scroll">
            <TablaResumenMET pila={ 'este' } datos={ props.datos } setModuloModal={ setModuloModal }/>
            <TablaResumenMET pila={ 'oeste' } datos={ props.datos } setModuloModal={ setModuloModal }/>
        </div>
      </div>

      <br />
    </div>
  )
}


