import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

export const Grafico = (props) => {
    return (   
        props.tipo === 'doble' 
        ?
          <ReactApexChart 
            height={350}
            type='line'
            options={ 
              { chart: {
                height: 350,
                type: 'line',
              },
              title: {
                text: props.nombre
              },
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              colors: [
                  '#16549e', '#a3d39c'
              ],
              labels: props.labels }
            }

            series={[
              {
                name: props.series[0],
                type: 'column',
                data: props.valores_barra
              },
              {
                  name: props.series[1],
                  type: 'line',
                  data: props.linea_objetivo
              }
            ]}
          />
        :
          props.tipo === 'nuevo'
          ?
          <ReactApexChart 
            height={ 350 }
            type="bar" 
            options={ 
              {
                chart: {
                  height: 350,
                  type: 'bar'
                },
                title: {
                  text: props.nombre
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                  }
                },
                colors: ['#16549e'],
                dataLabels: {
                  enabled: false,
                  enabledOnSeries: [0]
                },
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  customLegendItems: ['Rlix real', 'Rlix esperado',  'Rlix objetivo'],
                  markers: {
                    fillColors: ['#16549e', '#ffc33c', '#a3d39c']
                  }
                }
              }
            }

            series={ props.series }
          />
          : props.tipo === 'nuevo-este'
          ? 
            <ReactApexChart 
              height={ 350 }
              type="bar" 
              options={ 
                {
                  chart: {
                    height: 350,
                    type: 'bar',
                    zoom: {
                      enabled: true
                    }
                  },
                  title: {
                    text: props.nombre
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    }
                  },
                  colors: ['#16549e'],
                  dataLabels: {
                    enabled: false,
                    enabledOnSeries: [0]
                  },
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Rlix real', 'Rlix estimado a la fecha',  'Rlix objetivo'],
                    markers: {
                      fillColors: ['#16549e', '#ffc33c', '#a3d39c']
                    }
                  }
                }
              }

              series={ props.series }
            />
          : 
          <ReactApexChart 
            height={350}
            type='bar'
            options={ 
              {chart: {
                height: 350,
                type: 'line',
              },
              title: {
                text: props.nombre
              },
              stroke: {
                  width: [0,4]
              },
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              colors: [
                  '#16549e', '#01579b'
              ],
              labels: props.labels}
            }

            series={[
              {
                name: props.serie,
                type: 'column',
                data: props.valores_barra
              }
            ]}
          />
    )
}
