import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';

export const NodoOXE = () => {
   const { oxe } = useSelector((state) => state.SimSX.datos );

    return(
        <>
            {/* Contenido custom */}
            <div className="nodo nodo-oxe">
                <div className="forma-pila">
                    <h6>Planta OXE</h6>
                </div>
                
                <table className="tabla-nodo oxe">
                    <thead>
                        <tr>
                            <th width="135">Drenaje canaleta</th>
                            <th>Cu real última muestra</th>
                            <th width="135">Cu pred</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{ oxe.drenaje_canaleta }</td>
                            <td>{ oxe.cu_ultima_muestra }</td>
                            <td>{ oxe.cu_pred }</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Handle
                type="source"
                position={ Position.Left }
                id="a"
                style={{ background: '#16549e' }}
                isConnectable={ false }
            />
        </>
    )
}