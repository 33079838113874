// React
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Hooks
import { useRazonLixiviacion } from "../../hooks/useRazonLixiviacion";
import { useState } from "react";
import { limpiarRazonLixiviacion } from "../../../store/digitalizacion/RazonLixiviacionSlice";
import { useDispatch } from 'react-redux';
import { useSesion } from "../../hooks/useSesion";

// Componentes
import { FilaSuperior } from "./components/FilaSuperior";
import { Detalle } from "./components/Detalle";
import { Estadistica } from "./components/Estadistica";
import { Loader } from "../../components/Loader";
import { MsjError } from "../../components/MsjError";

// Materialize
import M from "materialize-css";

import './styles/rlix.scss';

export const RazonLixiviacionPage = () => {
    // Hooks
    const { obtenerRlixAPI, cambiarTabRlix }  = useRazonLixiviacion();
    const { limpiarStore } = useSesion();
    const dispatch = useDispatch();

    // Párammetros
    const { planta } = useParams();
    const datos = useSelector((state) => state.Rlix.datos)

    // Variables de estado
    const [Ejecutado, setEjecutado] = useState(false);
    const [Ciclo, setCiclo] = useState('')
    const [Ciclos, setCiclos] = useState([])
    const [Cabecera, setCabecera] = useState([]);
    const [Modulos, setModulos] = useState([]);

    
    useEffect(() => {
        limpiarStore();
        if( Object.keys(datos).length > 0) {
            dispatch( limpiarRazonLixiviacion() )
            obtenerDatos();
        } else {
            obtenerDatos();
        }
    }, []);

    const obtenerDatos = async() => {
        const id = planta == 'met' ? 1 : 2;
        const rlix = await obtenerRlixAPI( id );

        if( rlix == false ) {
            setEjecutado( null );

        } else {
            if(planta == 'met' ) {
                const { datos_agrupados, ciclos, datos:registros } = rlix;
    
                // Ordenar registros
                setCiclo(ciclos[ciclos.length-1]);
                setCiclos(ciclos);
                setModulos(registros)
                setCabecera([
                  'MÓDULO', 'CICLO', 'FECHA INICIO RIEGO', 'FECHA ESTIMADA DRENAJE', 'TONELADAS (tms)', 'TOTAL HORAS',
                  'M3 TOTALES', 'DIAS RIEGO EFECTIVO', 'RLIX EFECTIVA (m3/tms)', 'RLIX ESPERADO (m3/tms)', 
                  'ESTADO MÓDULO'
                ])
        
            } else {
                const { Ciclo:ciclos, Ciclos:grupos, datos:registros } = rlix;
                setCiclo(ciclos[ciclos.length-1]);
                setCiclos(ciclos);
                setModulos(registros)
                // console.log(grupos[grupos.length-1].registros);
                setCabecera([
                  'MÓDULO', 'CICLO', 'FECHA INICIO RIEGO', 'FECHA ESTIMADA DRENAJE', 'DIAS RIEGO EFECTIVO',
                  'TONELADAS (tms)', 'M3 TOTALES', 'RLIX EFECTIVA (m3/tms)', 'RLIX ESPERADO (m3/tms)', 
                  'ESTADO MÓDULO'
                ]);
            }

            setEjecutado(true);
        }
    }
    
    return (
        <div className="contenido">
            {
                {
                    false: <Loader />,
                    null: <MsjError />,
                    true:
                        <>
                            <FilaSuperior planta={ planta } ciclo={ Ciclo } />
                            <br />
                            <div className="contenedor">
                
                                <div className="cont-btns-tabs-rlix">
                                    <button 
                                        className="btn waves-effect waves-light activo"
                                        id="btn-tab-rlix-detalle"
                                        onClick={ () => { cambiarTabRlix('tab-detalle-rlix', 'btn-tab-rlix-detalle', 'tab-graficos-rlix', 'btn-tab-rlix-graficos') } }
                                    >
                                        <i className="material-icons left">table_chart</i>
                                        DETALLE
                                    </button>
                
                                    <button 
                                        className="btn waves-effect waves-light"
                                        id="btn-tab-rlix-graficos"
                                        onClick={ () => { cambiarTabRlix('tab-graficos-rlix', 'btn-tab-rlix-graficos', 'tab-detalle-rlix', 'btn-tab-rlix-detalle') } }
                                    >
                                        <i className="material-icons left">show_chart</i>
                                        GRÁFICOS
                                    </button>
                                </div>
                                
                                <Detalle 
                                    planta={ planta } 
                                    cabecera={ Cabecera } 
                                    modulos={ Modulos }
                                    ciclos={ Ciclos }
                                    ciclo={ Ciclo }
                                />
                
                                <Estadistica planta={ planta } modulos={ Modulos } />
                            </div>
                        </>
                }[ Ejecutado ]
            }
        </div>
    )
}
