

import './styles/loader.scss';

export const Loader = ( props ) => {
  return (

    <div className="cont-loader">
      <div className="pl4"> 
        <div className="pl4__a"></div>
        <div className="pl4__b"></div>
        <div className="pl4__c"></div>
        <div className="pl4__d"></div>
      </div>
      
      {
        props.mensaje ==! "" || props.mensaje
        ? <p>{ props.mensaje }</p>
        : null
      }
      
    </div>

  )
}
